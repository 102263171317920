.community-tag{
  color: #fff;
  background-color: #2EC4B6;
  font-family: AlbertLight;
  font-weight: 100;
  padding: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  margin-inline-start: 10px;
  font-size: 14px;
  position: relative;
  display: inline-flex;
}

.community-tag img{
  margin-inline-end: 5px;
}