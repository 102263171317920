.indication-chooser-input{
  position: relative;
}

.indication-chooser-input .indication-chooser-items{
  display: none;
  position: absolute;
  background-color: #fff;
  width: 100%;
  z-index: 10;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}

.indication-chooser-input.indication-chooser-input-show .indication-chooser-items{
  display: block;
}

.indication-chooser-input input{
  font-family: 'AlbertLight';
  font-size: 18px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border:1px solid #aaa;
  border-radius: 3px;
}
.indication-chooser-item{
  padding: 10px;
}
.indication-chooser-item:hover{
  cursor: pointer;
  background-color: #efefef;
}
.indication-chooser-input img.indication-chooser-list-icon{
  position: absolute;
  width: 25px;
  right: 6px;
  top: 9px;
  cursor: pointer;
}

.indication-chooser-input img.indication-chooser-close-icon{
  position: absolute;
  width: 15px;
  right: 9px;
  top: 15px;
  cursor: pointer;
}