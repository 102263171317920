.search-select-box{
  width: 100%;
  border: 1px solid #ffa78c;
}
.search-select-box.search-select-box-collapsed .search-select-box-title{
  border-bottom: none;
}
.search-select-box .search-select-box-title{
  display: flex;
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #ffa78c;
  justify-content: space-between;
}
.search-select-box .search-select-box-title-header{
  color: #ccc;
}
.search-select-box.search-select-box-collapsed .search-select-box-title-collapser{
  transform: rotate(180deg);
}
.search-select-box .search-select-box-title-collapser{
  border: 2px solid #333;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-select-box .search-select-box-title-close{
  cursor: pointer;
}
.search-select-box.search-select-box-collapsed .search-select-box-items{ 
  display: none;
}
.search-select-box .search-select-box-items{
  background-color: #fff;
}
.search-select-box .search-select-box-items-search{
  display: flex;
  background-color: #efefef;
  justify-content: space-between;
  align-items: center;
  padding-inline-end: 10px;
}
.search-select-box .search-select-box-items-search-input{
  padding: 10px;
}
.search-select-box .search-select-box-items-search-input input{
  border: none;
  background-color: transparent;
}
.search-select-box .search-select-box-items-search-icon{}
.search-select-box .search-select-box-items-search-icon img{
  width: 15px;
}
.search-select-box .search-select-box-items-search-input input:focus,
.search-select-box .search-select-box-items-search-input input:focus-visible,
.search-select-box .search-select-box-items-search-input input:focus-within,
.search-select-box .search-select-box-items-search-input input:active{
  outline: none;
}
.search-select-box .search-select-box-items-search-icon{

}
.search-select-box .search-select-box-items-search-list{
  max-height: 200px;
  overflow: auto;
}
.search-select-box .search-select-box-items-search-list-item-selected{

}
.search-select-box .search-select-box-items-search-list-item{
  padding: 10px;
  cursor: pointer;
  display: flex;
  font-family: AlbertLight;
  align-items: center;
}
.search-select-box .search-select-box-items-search-list-item:hover{
  background-color: #f8f8f8;
}
.search-select-box .search-select-box-items-search-list-item-title{}
.search-select-box .search-select-box-items-search-list-item-bullet{
  width: 10px;
  height: 10px;
  border-radius: 50px;
  border: 1px solid #333;
  margin-inline-end: 5px;
}
.search-select-box .search-select-box-items-search-list-item-selected .search-select-box-items-search-list-item-bullet{
  background-color: #000;
}
.search-select-box .search-select-box-items-search-list-item-title{}
