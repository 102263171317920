.intercom-lightweight-app{
  z-index: 88 !important;
}

.intercom-lightweight-app.visible{
  display: block;
}

#intercom-container{
  z-index: 1000 !important;
  position: relative;
}

.intercom-app > div{
  z-index: 1000 !important;
}

.intercom-app > div > div{
  z-index: 89 !important;
}

.intercom-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000008a;
  z-index: 1;
  display: none;
}

/* .intercom-launcher-frame, .intercom-launcher{
  display: none !important;
} */

[data-testid="active-notifications"] {
  display: none !important;
}

.intercom-button{
  /* display: none !important; */
  z-index: 9;
  position: fixed;
  bottom: 20px;
  right: 30px;
  background: #0c9bed;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  min-width: 130px;
}

body[mode="questionnaire"] .intercom-button{
  display: none !important;
}

body.chat-mode .intercom-button{
  display: none !important;
}

.intercom-launcher{
  display: none !important;
}

.intercom-launcher-frame{
  display: none !important;
}

.intercom-public-button{
  background: #fff;
  border: 2px solid #ff8c69;
  border-radius: 30px;
}

/* 
  deactivate intercom on questionnaire 
  notice: if this will be removed then borderless and chat iframes will automatically popup on editQuestion
*/

body[mode="questionnaire"] .intercom-button, 
body[mode="questionnaire"] .intercom-overlay, 
body[mode="questionnaire"]{
  /* display: none !important; */
}

/* --------- */

.intercom-button:hover{
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}

.intercom-button img{
  margin-inline-end: 10px;
}

.intercom-button span{
  margin-inline-end: 10px;
}

.intercom-button:hover{

}

.intercom-top-button{
 display: none;   
}

.intercom-button .button-loading {
  display: inline-block;
  height: 24px;
  cursor: default;
  position: absolute;
  cursor: default;
  top: -30px;
  zoom: 2;
}
.intercom-button .button-loading:after {
  content: " ";
  display: block;
  width: 14px;
  height: 14px;
  margin: 8px;
  border-radius: 50%;
  border: 1px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: button-loading 1.2s linear infinite;
}
@keyframes button-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 800px) {
  .intercom-overlay{
    display: block;
  }
  .intercom-top-button{
    display: block;
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 65px;
    cursor: pointer;
    z-index: 9999999999;
  }
}