.missing-critical-information-popup{}

.missing-critical-information-popup .intake-accordion {
  margin-left: 6px;
}
.missing-critical-information-popup .popup-title.popup-padding {
  border-bottom: initial;
}
.missing-critical-information-popup .files-uploader-select-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.missing-critical-information-popup .question{
  display: flex;
  cursor: pointer;
  font-family: 'AlbertLight';
  border-bottom: 1px solid #ececec;
}

.missing-critical-information-popup .question:hover{
  background-color: #f8f8f8;
}

.missing-critical-information-popup .question-circle{
  margin: 10px;
  padding: 10px;
  background: #EB4B62;
  color: #fff;
  width: 5px;
  height: 5px;
  border-radius: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.missing-critical-information-popup .question-title{
  display: flex;
  align-items: center;
}

.missing-critical-information-popup .cannot-update{
  text-align: center;
  margin-top: 10px;
  color: #0c9bed;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'AlbertLight';
}

.missing-critical-information-popup .cannot-update:hover{
  color: #8ac9e8;
}

@media only screen and (max-width: 800px) {
  .missing-critical-information-popup .intake-accordion {
    margin: 0px 6px;
  }
}