.treatment-survey {
  padding: 10px;
  /* height: calc(100% - 110px); */
}

.treatment-survey .survey-footer {
  /* display: none; */
}

.treatment-survey .popup-padding {
  word-break: break-word;
}

.treatment-survey .share-experiences {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: 'AlbertLight';
  font-weight: 100;
}

.treatment-survey .experiences {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: AlbertLight;
  font-weight: normal;
}

.treatment-survey .experiences .experiences-icon {
  background: #213332;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-end: 5px;
}

.treatment-survey .experiences img {}

.treatment-survey .footer-buttons {
  gap: 10px;
}

.treatment-survey .footer-buttons img {
  cursor: pointer;
}

.treatment-survey .title {
  margin-bottom: 5px;
  font-family: 'AlbertLight';
  font-weight: bold;
}

.treatment-survey .description {
  margin-bottom: 18px;
  font-family: 'AlbertLight';
  margin-bottom: 20px;
}

.treatment-survey .aggression {
  display: flex;
  justify-content: space-between;
}

.treatment-survey .aggression .option {
  width: 33%;
  text-align: center;
  cursor: pointer;
}

.treatment-survey .aggression .option-title {
  font-family: 'AlbertLight';
  font-size: 16px;
}

.treatment-survey .aggression .sentiment-image {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 5px;
}

.treatment-survey .aggression .option img {
  width: 60px;
}

.treatment-survey .aggression .sentiment-image.selected {
  border: 1px solid rgb(255, 72, 0);
}

.treatment-survey .search {
  margin-bottom: 10px;
}

.treatment-survey .side-effects {
  overflow: auto;
  overflow-x: hidden;
}

.treatment-survey .small-title {
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-family: 'AlbertLight';
}

.treatment-survey .thanks-image {
  text-align: center;
  margin-top: 25px;
}

.treatment-survey .thanks-text {
  margin-top: 20px;
}

.treatment-survey .thanks-text b {
  display: block;
  font-weight: bold;
  font-size: 18px;
  font-family: 'AlbertLight';
  margin-bottom: 15px;
}

.treatment-survey .no-side-effects {}

.treatment-survey .disabled {
  pointer-events: none;
  opacity: 0.3;
}

.treatment-survey .no-side-effects-button {
  color: #0C9BED;
  text-decoration: underline;
  font-family: AlbertLight;
  text-align: center;
  cursor: pointer;
  margin-top: 3px;
}

.treatment-survey .thank-you-image {
  text-align: center;
  margin-top: 30px;
}

.treatment-survey .thank-you-image img {
  filter: hue-rotate(-197deg) brightness(1.4);
}

.treatment-survey .thank-you-text {
  padding: 20px;
  box-sizing: border-box;
  font-family: AlbertLight;
  font-size: 18px;
}

.treatment-survey .thank-you-title {
  font-family: Albert;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
}

.treatment-survey .thank-you-bold {
  font-family: Albert;
}

.treatment-survey .thank-you-question {
  margin-top: 10px;
  font-family: Albert;
}

.popup-visible .popup.treatment_survey {
  bottom: 0px;
}

@media only screen and (max-width: 800px) { 
  .treatment_survey .popup-content {
    height: auto;
  }
}
