.change-location{}

.change-location .geo-suggest{
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  border: 1px solid #8a8a8a;
  border-radius: 3px;
  font-size: 15px;
}

.change-location .geo-suggest-suggests{
  margin: 0;
  font-size: 14px;
  padding: 0px;
  list-style: none;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  border-top: none;
  cursor: pointer;
}

.change-location .geo-suggest-suggests li{
  padding: 5px;
}

.change-location .geo-suggest-suggests li:hover{
  background: #f4f4f4;
  cursor: pointer;
}

.change-location .geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.change-location .set-distance{
  margin-top: 30px;
}

.change-location .geo-suggest-wrapper {
  display: grid;
  justify-items: end;
  position: relative;
}

.change-location .geo-suggest-wrapper .geo-clear-button {
  position: relative;
  top: 45px;
  right: 10px;
  cursor: pointer;
}

.change-location .geo-suggest-wrapper .geosuggest{
  width: 100%;
}

.geosuggest__input.geo-suggest {
  padding-right: 30px;
}

.rtl .change-location .geo-suggest-wrapper .geo-clear-button {
  left: 10px;
  right: initial;
}

.rtl .geosuggest__input.geo-suggest {
  padding-left: 30px;
}