.edit-phone {
	/* padding: 16px 24px !important; */
}

.edit-phone .popup-title {
	padding: 16px 24px !important;
	font-size: 20px;
	font-weight: 700;
}

.edit-phone .popup-footer {
	padding: 16px 0px !important;
	position: static;
}

@media only screen and (max-width: 800px) {
	.edit-phone .popup-footer {
		width: 100%;
	}
}
