.error-message-wrapper {
  font-family: AlbertLight;
}
.error-message-wrapper .oops {
  color: #EB4B62;
  font-size: 28px;
  margin-bottom: 17px;
  font-family: AlbertBold;
}
.error-message-wrapper .message-title {
  color: #EB5757;
  font-size: 16px;
}
.error-message-wrapper .message-content {
  font-size: 16px;
  margin: 7px 0 17px 0;
}