@charset "UTF-8";

html, body{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.new-ui{
  font-family: Albert;
}

body.rtl .new-ui{
  font-family: Varela;
}

@font-face{
  font-family: Albert;
  src: url(./assets/fonts/Albert/AlbertSans-SemiBold.ttf);
}

@font-face{
  font-family: AlbertXLight;
  src: url(./assets/fonts/Albert/AlbertSans-Light.ttf);
}


@font-face{
  font-family: AlbertLight;
  src: url(./assets/fonts/Albert/AlbertSans-Regular.ttf);
}

@font-face{
  font-family: AlbertBold;
  src: url(./assets/fonts/Albert/AlbertSans-Bold.ttf);
}

@font-face {
  font-family: Varela;
  src: url(./assets/fonts/Varela/VarelaRound-Regular.ttf);
}

:root {
  --selected-color: 'green';
}

#root{
  height: 100%;
}

button.MuiButton-root{
  transition: box-shadow 10ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.mobile-only, .mobile-only-flex{
  display: none !important;
}

.new-ui .cursor-default{ cursor: default !important;}
.new-ui .cursor-pointer{ cursor: pointer !important;}

.new-ui .white-background{ background-color: white; }
.new-ui .red-text{ color: red;}
.new-ui .center{ justify-content: center;}
.new-ui .height-10{ height: 10px; }
.new-ui .height-20{ height: 20px; }
.padding-5{ padding: 5px; }
.padding-10{ padding: 10px; }
.padding-20{ padding: 20px; }
.text-align-center{ text-align: center; }
.text-center{ text-align: center; }
.new-ui .margin-top-10{ margin-top: 10px;}
.new-ui .margin-top-20{ margin-top: 20px;}
.new-ui .margin-bottom-10{ margin-bottom: 10px;}
.new-ui .margin-bottom-20{ margin-bottom: 20px;}
.new-ui .font-light{ font-family: AlbertLight;}
.new-ui .rotate-180{ transform: rotate(180deg);}
.new-ui .flex-middle{ display: flex; align-items: center; justify-content: center;}
.flex-end{ display: flex; align-items: flex-end; justify-content: flex-end;}
.new-ui .opacity-zero{ opacity: 0 !important;}

.display-block{ display: block !important; }

.new-ui .hide, .new-ui .hidden{
  display: none !important;
}

.new-ui .middle{
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.new-ui .flex{
  display: flex;
}

.new-ui .space-between{
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.new-ui .relative{
  position: relative;
}

.new-ui .column-margin{
  margin-top: 10px;
  margin-bottom: 20px;
}

.new-ui .avoid-break{
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}

.app.new-ui{
  display: table;
  table-layout: fixed;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #F2F2F7;
}

.new-ui main {
  flex: auto;
  padding: 40px 80px;
  padding-top: 20px; /* 113 */
}

.new-ui main h1{
  font-size:28px;
  margin: 0px;
  padding: 0px;
  margin-bottom: 28px;
}

.new-ui .widget{
  /* margin-bottom: 20px; */
}

.new-ui .widget-body{
  background: #fff;
  border-radius: 8px;
  padding: 10px;
}

.new-ui h2 {
  padding: 0px;
  margin: 0px;
  font-size: 28px;
  margin-bottom: 8px;
  font-weight: bold;
}

.new-ui .box{
  background: #fff;
  border-radius: 8px;
  padding: 10px;
}

.new-ui .grid .widget{
  break-inside: avoid;
}

.new-ui .border-radius{
  border-radius: 10px;
}

.new-ui .blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media only screen and (min-width: 800px) { /* desktop mode */
  .desktop-hide{
    display: none !important;
  }
}

@media only screen and (max-width: 800px) { /* mobile mode */
  .new-ui main {
    padding: 0px;
    /* padding-top: 65px; */
  }
  .new-ui .widget{
    /* margin-bottom: 35px; */
  }
  .app.new-ui{
    padding-bottom: 70px;
  }
  .app.new-ui.with-mobile-footer{
    padding-bottom: 100px !important;
  }
  .mobile-only{
    display: block !important;
  }
  .mobile-only-flex{
    display: flex !important;
  }
  .desktop-only:not(.mobile-only), .desktop-only:not(.mobile-only-flex){
    display: none !important;
  }
}

.visibility-hidden{
  visibility: hidden !important;
}

.provider-mode #root {
  background: #F9EEF1;
}

.generic-popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 91;
}

.generic-popup .generic-popup-header{
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #ccc;
}

.generic-popup .generic-popup-header-close{
  cursor: pointer;
  padding: 10px;
}

.generic-popup .generic-popup-body{}

.generic-overlay{
  display: none;
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.generic-overlay.generic-overlay-visible{
  display: flex;
}

.generic-overlay .generic-overlay-content{}

.generic-overlay .lottie{
  width: 180px !important;
}

.quick-note-holder{
  width: 100%;
  bottom: 20px;
  display: flex;
  justify-content: center;
  position: fixed;
  transition: all 0.5s ease-out;
}
.quick-note-holder.note-hidden{
  bottom: -100px;
}
.quick-note{
  display: inline-block;
  background-color: green;
  color: #fff;
  padding: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}

@media only screen and (max-width: 800px) {
  .provider-mode .app.new-ui {
    padding-bottom: 0 !important;
    margin-bottom: 70px;
  }
  .provider-mode .new-ui .results .result-actions {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 360px) {
  body{
    zoom: 0.8;
  }
}

@media only screen and (max-width: 600px) {
  .other-cancer-button{
    font-size: 16px;
  }
}

.other-cancer-button{
  color: #000;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  cursor: pointer;
  border: 1px solid #FF8C69;
  font-size: 16px;
  text-align: unset;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  height: 50px;
  width: 450px;
  align-items: center;
  padding-inline-start: 14px;
  box-sizing: border-box;
}

.other-cancer-button:hover{
  color: #FF8C69;
  background-color: #fff4f1;
}

.other-cancer-success-popup{
  text-align: center;
  padding: 30px;
  font-family: AlbertLight;
  font-size: 16px;
  background-color: #fff;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.other-cancer-success-popup-wrapper{
  max-width: 800px;
}

.other-cancer-success-popup .other-cancer-logo{
  margin-bottom: 20px;
}
.other-cancer-success-popup .other-cancer-thank-you{
  margin-bottom: 20px;
  background-color: #d5f3f0;
  padding: 20px;
  box-sizing: border-box;
}
.other-cancer-success-popup .other-cancer-text1{
  margin-bottom: 20px;
}
.other-cancer-success-popup .other-cancer-text2{
  margin-bottom: 20px;
}
.other-cancer-success-popup .other-cancer-text2 span{
  color: #FF8C69;
  font-family: Albert;
}
.other-cancer-success-popup .other-cancer-text3{
  margin-bottom: 20px;
  font-family: Albert;
}

.dashboard-skeleton-overlay{
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #F2F2F7;
  pointer-events: none !important;
}

.dashboard-skeleton{
  width: 100%;
  height: 100%;
}

.dashboard-skeleton-header{}

.dashboard-skeleton-results{
  max-width: 800px;
}

.dashboard-skeleton-overlay .button-item{
  display: none !important;
}

.dashboard-skeleton-overlay .result-list-mobile-actions{
  display: none !important;
}
