.new-matches-popup{
  text-align: start;
}

.new-matches-popup .new-matches-popup-title{
  margin-bottom: 10px;
  font-weight: bold;
}

.new-matches-popup .new-matches-popup-text{
  margin-bottom: 10px;
}

.new-matches-popup .new-matches-popup-button{
  display: flex;
  justify-content: flex-end;
}

.new-matches-popup .new-matches-popup-button .button{
  max-width: 180px;
}

@media only screen and (max-width: 800px) {
  .new-matches-popup .new-matches-popup-button .button{
    width: 100% !important;
    max-width: initial;
  }
  .popups2 .new_matches.hanging-popup-theme.visible.animate-in{
    height: 100px;
  }
}