.results .grid{
  gap: 0px 28px;
  grid-template-columns: 70% 30%;
  display: grid;
}

.results .result-list .circle-title {
  height: 65px;
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
}

.result-actions{
  /* margin-top: 10px; */
}

.results .trial-item.list-item{
  margin-bottom: 10px;
  border: 1px solid #D1D1D6;
}

.results .trial-item:not(.popup-item) {
  box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 14%);
}

.results .result-list {
  order: 0;
  position: relative;
}
.results .upload-files {
 border: none;
 border-radius: 18px;
 padding: 10px 14px;
}

.search .upload-nav {
  display: flex;
  padding: 22px 14px;
}
.search .upload-nav .info {
  display: flex;
  align-items: center;
  margin-right: 14px;
}
.search .upload-nav .info >img {
  margin-right: 8px;
}
.search .upload-nav .buttons {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.search .upload-nav .button{
  font-family: AlbertLight;
  font-weight: normal;
  border: none;
}
.search .upload-nav .button:hover{
  border: none !important;
}

.search .upload-nav .or {
  margin: 0 14px;
}

.ai-wrapper .ai-assistant {
  background-color: #fff;
  position: sticky;
  top: 135px;
  padding: 30px 0px 12px 0px;
  border-radius: 4px;
}
.ai-wrapper .ai-assistant .ai-area {
  background-color: #fff;
  padding-bottom: 15px;
}

@media only screen and (min-width: 800px) {
  .main-search .upload-nav{
    padding: 0px 0px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .results .upload-nav .info{
    margin-bottom: 18px;
  }
  .results .upload-nav{
    flex-direction: column;
  }
  .results .grid {
    display: flex;
    flex-direction: column;
  }
  .results .next-steps {
    order: -1;
    margin-bottom: 20px;
  }
  .results .result-actions{
    /* margin-top: 10px; */
  }
  .new-ui .results .widget{
    margin-bottom: 20px;
  }
  .results .results-details-header{
    padding-left: 20px;
    padding-right: 20px;
  }
  .upload-nav .info >img {
    margin-right: 6px;
  }
  .upload-nav .buttons button {
    margin-right: 6px;
  }
}
