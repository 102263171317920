.pager{}

.pager .pager-index{
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pager .pager-index-button{
  display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 20px;
    background: #707070;
    cursor: pointer;
    margin-left: 3px;
    margin-right: 3px;
}

.pager .pager-index-button.pager-selected{
  background: #0C9BED;
}

.pager .pager-pages{
  position: relative;
  overflow: hidden;
}

.pager .pager-page{
  display: inline-block;
  width: 100%;
  vertical-align: top;
  white-space: initial;
}

.pager .pager-pages-strip{
  white-space: nowrap;
  position: relative;
  left: 0%;
  transition: left 0.2s ease-out;
}

.pager .pager-page-hidden{
  max-height: 10px;
  opacity: 0;
  pointer-events: none;
}

.pager .pager-arrows{
  margin-inline-start: 5px;
  margin-inline-end: 5px;
  cursor: pointer;
}

.pager .pager-arrows-prev{
  transform: rotate(-90deg);
}

.pager .pager-arrows-next{
  transform: rotate(90deg);
}

.pager .pager-arrows-hidden{
  visibility: hidden;
  pointer-events: none;
}

.pager .pager-disabled{
  pointer-events: none;
  cursor: default;
}