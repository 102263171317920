.root {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #CBE896;
    background-color: #eaf0e4;
    cursor: pointer;
}
.root input {
    display: none;
}
.root.checked {
    background-color: #d3eaa9;
}
.title {
    min-width: 142px;
    padding: 8px 18px;
    box-sizing: border-box;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    white-space: nowrap;
    text-align: center;
    color: rgba(33, 51, 50, 0.80);
}
