.item-box{
  background: #fff;
  border-radius: 8px;
  padding: 10px;
  overflow: hidden;
}
.search .item-box .missing-questions{
  /* display: none !important; */
}
.popup-item.item-box .bottom{
  border-bottom: 1px solid #D1D1D6;
}
.item-box.popup-item .tags{
  padding-left: 10px;
  padding-right: 10px;
}
.item-box .tags{
  /* padding-bottom: 10px; */
  padding: 0;
  display: flex;
  justify-content: flex-end;
}
.item-box .tag{
  display: inline-block;
  font-size: 12px;
  margin-inline-end: 5px;
}
.item-box .tag.tags-close{
  display: flex;
}
.item-box .tags .tags-new{
  color: #EB4B62;
}
.item-box .tags .tags-sponsored{
  /* display: flex; */
  align-items: center;
  cursor: pointer;
  color: rgba(33, 51, 50, 0.8);
}
.item-box .tags .tags-close{
  color: #67CE67;
}
.item-box .tags .tags-number{
  font-weight: bold;
  background: #F2F2F7;
  padding: 2px 7px;
  border-radius: 3px;
  border: 1px solid #D1D1D6;
  display: inline-flex;
  gap: 5px;
  cursor: pointer;
}
.item-box .rank3 *{
  fill: #67CE67;
}
.item-box .ranks{
  margin-inline-start: 5px;
}
.item-box .info{
  /* padding: 15px 0px 0; */
  cursor: pointer;
}

.item-box .item-biomarkers-tags{
  display: flex;
  justify-content: flex-start;
  height: 32px;
  overflow: hidden;
  font-size: 15px;
}

.item-box .item-biomarker-tag{
  background-color: #2EC4B6;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  margin-inline-end: 5px;
  white-space: nowrap;
  padding-right: 10px;
}
.item-box .item-biomarker-tag img{
  width: 15px;
  margin-inline-end: 5px;
}

.item-box-container.public-item .info{
  padding: 0px 2px 0;
}
.item-box .logo img{
  width: 123px;
  max-height: 93px;
  margin-left: 0;
  padding-right: 5px;
  margin-inline-end: 5px;
}
.item-box .info .description{
  margin-bottom: 15px;
  font-size: 16px;
  margin-top: 10px;
}
.item-box .info .description .logo{
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.item-box .info .description .logo > div{
  text-align: center;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-inline-end: 7px;
}
.item-box .info .description .logo img{
  width: 100%;
}
.item-box .info .description .logo span{
  font-size: 16px;
  font-family: 'AlbertLight';
}
.item-box .info .description .group-tags {
  margin-top: 10px;
  font-family: 'AlbertLight';
}
.item-box .info .description .group-tags span{
  color: #494949;
  border: 1px solid #D1D1D6;
  border-radius: 15px;
  padding: 5px 10px;
  margin: 5px 5px 5px 0;
  display: inline-block;
}
.item-box .info .description .item-subtitle {
  color: #000;
  font-size: 20px;
  line-height: 1.4;
}
.item-box .info > div:not(:first-of-type){
  margin-top: 10px;
}
.popup-item.item-box .details{
  padding-top: 15px;
  padding-bottom: 0px;
}
.item-box .details .detail{
  margin-bottom: 15px;
}
.item-box .detail span{
  margin-inline-start: 12px;
  font-size: 14px;
}
.item-box .important *{
  stroke: #EC6330;
}
.item-box .missing{
  padding: 15px;
}
.item-box .actions{
  display: flex;
  gap: 15px;
  padding-top: 15px;
  /* padding-bottom: 5px; */
  text-align: center;
  /* padding-left: 5px; */
  /* padding-right: 5px; */
}
.item-box.popup-item{
  border: none !important;
  padding: 0;
}
.item-box.list-item{
  border: 1px solid #21333266;
  margin-bottom: 10px;
}
.item-box button {
  font-family: 'AlbertLight';
}
.item-box .consult-button {
  color: white;
  background-color: #213332 !important;
  border-color: #213332;
}
.item-box .consult-button:hover {
  border-color: #FF8C69 !important;
}
.item-box .learn-more {
  background-color: white !important;
  color: #213332 !important;
}
.item-box .icon-line:not(.inline){
  padding-bottom: 15px;
}
.item-box .learn-more.not-sponsored {
  max-width: 335px;
}
.item-box .learn-more:hover {
  background-color: #FF8C6933 !important;
  border: 1px solid #FF8C69 !important;
}
.item-box .nct-number{
  color: #9a9a9a;
  font-size: 14px;
}
.item-box-container.public-item .missing-questions{
  display: none;
}

.item-box .drug-commercial-name{
  color: #AFB3B4;
  font-size: 18px;
}

.item-box .button-holder{
  width: 100%;
}
.item-box .collapser:hover{
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
}
.item-box .collapser img{
  position: relative;
  top: 2px;
}
.item-box-container.collapsed .collapser{
  transform: rotate(180deg);
}
.item-box-container.collapsed .missing-questions-text.hidden{
  display: flex !important;
}

.item-box-container .link-button{
  font-size: 16px !important;
  padding-inline-end: 10px;
  font-family: 'AlbertLight';
}


.item-box .button-holder-button{
  text-align: end;
}
.item-box .button-holder-button .button-part{
  display: inline-block;
  width: 300px;
}
.item-box-icons {
  border: 1px solid #F2F2F7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 14px;
  border-radius: 8px;
  margin-right: 24px;
}
.item-box-icons img:first-of-type {
  margin-bottom: 8px;
}
.item-box-info {
  display: flex;
}
.item-box-content .details .icon-line > div  {
  background-color: #F2F2F7;
  border-radius: 4px;
  min-width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-box .actions {
  justify-content: end;
}


.popup .item-box .info{
  padding-left: 15px;
  padding-right: 15px;
}
.popup .item-box .details{
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (max-width: 800px) {
  .item-box.full-item .actions{
    text-align: start!important;
  }
  .item-box.full-item .button{
    width: 250px !important;
  }
  .item-box .button-holder-button .button-part{
    display: block;
    width: 100%;
  }
  .item-box .actions {
    gap: 8px !important;
  }
  .item-list {
    flex-direction: column !important;
  }
  .item-box .info .description .item-subtitle.short-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 16px;
  }
  .item-box-icons {
    margin-right: 0;
    padding: 14px 14px 10px 14px;
  }
}

.drug-commercial-name-container {
  display: block;
}

.phase-range {
  display: inline-flex;
  gap: 5px;
}
.phase-range .icon-line:not(:last-child){
  gap: 3px !important;
}

.item-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  padding-left: 10px;
}


.dashborad-card-item-freindly-title-margin{
  margin-top: 20px;
}

.dashborad-card-item-freindly-title-icon {
  padding-right: 10px;
}

.dashborad-card-item-freindly-container {
  display: flex;
  font-family: 'AlbertLight';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  
}