.ngs-popup{
  padding: 20px !important;
}

.ngs-analyze-files-biomarkers{
  overflow: auto;
  max-height: 100px;
}

.popup-ngs-analyzer-results-container {
    padding: 10px 0;
}

.popup-ngs-analyzer-results-subtitle {
    font-size: 18px;
    padding-bottom: 10px;
}

