.hcp-select{
  position: relative;
}

.hcp-select-header{
  display: flex;
  border: 1px solid #ff8c69;
  justify-content: space-between;
  height: 52px;
  align-items: center;
  font-family: AlbertLight;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fff;
}

.hcp-select-header:hover{
  background-color: #ffe8e0;
}

.hcp-select-title{
  padding: 14px;
  width: 100%;
  color: #959595;
  font-size: 15px;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.hcp-select-icon{
  padding: 10px;
  display: flex;
  align-items: center;
}

.hcp-select-icon-down{
  transform: rotate(180deg);
}

.hcp-select-value .hcp-select-icon-down{
  transform: initial;
}

.hcp-tags {
  padding: 10px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-flow: wrap;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 3px;
}

.hcp-select-input input{
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
}

.hcp-select-input input:focus-visible{
  outline: 1px solid transparent;
}

.hcp-select-options{
  overflow: auto;
  max-height: 240px;
}

.hcp-select-option:hover{
  cursor: pointer;
  background: #efefef;
}

.hcp-select-body{
  display: none;
  background: #fff;
  white-space: nowrap;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  z-index: 4;
  max-width: 100%;
}

.hcp-select-option{
  padding: 10px;
  min-height: 18px;
  white-space: break-spaces;
}
.hcp-select-body .hidden {
  display: none;
}

.hcp-select.hcp-select-visible .hcp-select-body{
  display: block;
  position: relative;
}

.hcp-select-input{}

.hcp-select-option{}

.hcp-select-value .hcp-select-header{
  border: 1px solid #333;
}

.hcp-select-value .hcp-select-title{
 color: #000; 
}

.hcp-select-option-selected{
  background-color: #efefef;
}

.hcp-select-icon-hide{
  display: none;
}

.hcp-select-value .hcp-select-icon-hide{
  display: flex;
}

.hcp-select-value .hcp-select-icon-main{
  display: none;
}

.hcp-select-disabled{
  pointer-events: none;
}

.hcp-select-disabled .hcp-select-header{
  border: 1px solid #e9ebeb;
  background-color: #e9ebeb;
}

.hcp-select-disabled .hcp-select-icon{
  opacity: 0.2;
}

.hcp-select-disabled .hcp-select-title{
  opacity: 0.8;
}

.hcp-select-clear.hcp-select-option {
  display: none;
}