.search{}

.app-search header{
  height: 70px;
    background-color: #fff;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    /* position: fixed; */
    width: 100%;
    box-sizing: border-box;

}

.search h1{
  text-align: center;
}

.upload-files .button-text{
  white-space: nowrap;
}

.search .grid{
  grid-gap: 0px 40px;
    gap: 0px 40px;
    grid-template-columns: minmax(auto, 30%) 70%;
    display: grid;
}

.search .search-ngs-header{
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  font-family: 'AlbertBold';
}

.search .search-ngs-header img{
  margin-inline-end: 7px;
}

.search .results-header{
  display: flex;
  align-items: center;
  /* margin-bottom: 20px; */
}

.search .results-title{
  font-family: 'AlbertLight';
  margin-bottom: 15px;
  margin-top: 15px;
}

.search .results-title span{
  background: #fff;
  display: inline-block;
  border-radius: 20px;
  width: 20px;
  text-align: center;
  border: 1px solid #ccc;
  cursor: pointer;
}

.search .results-container{
  order: 1;
}

.search .results-header .results-header-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.search .results-header .results-header-icon img{
  width: 22px;
}

.search .download-button img{
  margin-inline-end: 5px;
}

.search .results-header .results-header-title{
  font-size: 17px;
  margin-inline-start: 5px;
  font-family: AlbertLight;
  max-width: 330px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search .results-header-button{
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
}

.search .result-list-header{
  padding: 14px 0!important;
  font-size: 20px;
  font-family: "AlbertBold";
}

.search .results-header-button:hover{
  background-color: #f5dedb;
}

.search .results-filters img{
  filter: saturate(2110%) hue-rotate(152deg) sepia(0.5);
}

.search .item-box .consult-button{
  display: none;
}

.search .item-box .nct-number{
  /* display: none; */
}

.search .item-box .learn-more{
  max-width: 350px;
}

.search .mobile-biomarkers{
  display: none;
}

.search .search-parameters .form-part.biomarkers{
  /* display: none; */
}

.search .search-subtitle{
  margin-bottom: 25px;
  font-family: 'AlbertBold';
}

.search .search-show-all-button{
  margin-top: 10px;
}

.search .search-results-wrapper{
  max-height: 900px;
  overflow: hidden;
}
.search .search-results-wrapper.search-results-wrapper-expanded{
  max-height: initial;
}

.search .search-results-title{
  margin-top: 10px;
  margin-bottom: 10px;
}

.search .results-tabs{
  display: none !important;
  background: #fff;
  /* padding: 20px; */
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  font-size: 30px;
  position: sticky;
  top:5px;
}

.search .results-tabs .results-tab{
  font-family: 'AlbertXLight';
  padding-top: 20px;
  padding-bottom: 10px;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  color: #FF8C69;
}

.search .results-tabs .results-tab.selected-tab{
  font-family: 'AlbertBold';
  border-bottom: 4px solid #FF8C69;
  color: black;
}

.search .search-mobile-header-wrapper{
  position: sticky;
  top:0px;
}

.search .search-mobile-header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-top: 1px solid #efefef;
}

.search .search-mobile-header-options{

}

.search .search-mobile-header-glass{
  display: flex;
  align-items: center;
  background-color: #ff8c69;
  width: 50px;
  position: relative;
  left: -10px;
  justify-content: center;
  padding: 15px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  cursor: pointer;
  box-shadow: 0px 3px 5px rgba(0,0,0,0.2);
}

.search .search-mobile-header-tabs{
  display: flex;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}

.search .search-mobile-header-tabs img{
  margin-inline-start: 10px;
}

.search .search-mobile-header-actions{
  padding: 10px;
  border-left: 1px solid #ccc;
  cursor: pointer;
}

.search .search-mobile-tabs{
  position: absolute;
  justify-content: center;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  z-index: 10;
  display: flex;
}

.search .search-mobile-tabs.mobile-tabs-show{
  pointer-events: all;
  opacity: 1;
}

.search .search-mobile-tabs .search-mobile-tabs-content{
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  display: flex;
  position: relative;
  /* top:5px; */
}

.search .search-mobile-tab{}

.search .search-mobile-tab-selected{
  color: #ff8c69;
}

.search .search-mobile-tabs-content-titles{
  cursor: pointer;
  font-size: 22px;
}

.search .search-mobile-tabs-content-arrow{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-start: 20px;
}

.search .search-mobile-actions{
  position: absolute;
  display: none;
  right: 10px;
  background: #fff;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #00000036;
}

.search .mobile-actions-show{
  display: block;
}

.search .search-mobile-actions .results-header{
  display: block;
  margin-bottom: 0;
}

.search .search-mobile-actions .results-header-button{
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.search .search-change-location{}

.search .search-change-location-title{}

.search .search-change-location-input{}

.search .search-change-location-bullet{}

.search .search-change-location-bullet-circle{}

.search .bullet-selected{}

.hcp-user .form-indication .type{
  opacity: 0.5;
  pointer-events: none;
  background-color: #efefef;
  border: none;
}

.hcp-user .form-indication .type .select-value-icon{
  display: none;
}

.hcp-user .form-indication .type .select-value-title{
}

.hcp-user .form-indication .type .select .select-value{
  background-color: #efefef;
  border: none !important;
  padding: 5px;
  margin-bottom: 5px;
  font-family: AlbertLight;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: initial !important;
  border-radius: 3px;
}

@media only screen and (max-width: 800px) {
  .main-search{
    padding: 0 !important;
  }
  .widget.result-list{
    /* padding: 10px; */
  }
  .search h1{
    margin-bottom: 15px !important;
  }
  .search .grid {
    display: flex;
    flex-direction: column;
  }
  .search .item-box .learn-more{
    max-width: 100%;
  }
  .search .desktop-biomarkers{
    display: none;
  }
  .search .mobile-biomarkers{
    display: block;
  }
  .search .search-parameters{
    margin-bottom: 5px;
  }
  .search .search-subtitle{
    border-top: 1px solid #ccc;
    padding-top: 10px;
  }
  .search .results-title{
    padding-left: 10px;
    padding-right: 10px;
  }
  .search .results-items{
    padding: 10px;
    padding-top: 0px;
  }
  .search .result-list-header{
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .search .result-list-header-sort{
    display: none;
  }
  .search .result-list-mobile-header-actions{
    padding-inline-start: 0px;
  }

  .hcp-form .intake-holder {
    padding-bottom: 10px;
  }
}

.search-form-mobile-header{
  display: flex;
  justify-content: space-between;
  height: 30px;
  align-items: center;
  background-color: #efefef;
}
.search-form-mobile-header-close{
  padding: 10px;
  cursor: pointer;
}

.hcp-mobile-search.generic-overlay .generic-overlay-content {
  width: 100%;
  height: 100%;
}

.app-search .ngs-popup{
  padding-top: 0px !important;
}

.app-search .popup.ngs .ngs-popup .ngs-title{
  font-family: AlbertLight;
  font-size: 25px !important;
  margin-top: 0px !important;
}
