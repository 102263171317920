.single-option-select{}

.single-option-select .single-option-select-subtitle{
  margin-bottom: 10px;
}

.select-option{
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 5px;
}

.select-option:last-of-type{
  margin-bottom: 0;
}

.select-option.option-selected{
  background-color: #fff;
}

.select-option .option-selector{
  width: 18px;
  height: 18px;
  border: 1px solid #333;
  background-color: #fff;
  border-radius: 20px;
  margin-inline-end: 10px;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-option .option-selector-circle{
  display: none;
}

.select-option.option-selected .option-selector-circle{
  width: 65%;
  height: 65%;
  border-radius: 20px;
  background-color: #ff8c69;
  display: block;
}

.select-option.option-selected .option-selector{
  background-color: #ffffff;
  border: 1px solid #ff8c69;
}

.select-option .option-label{
  font-family: AlbertLight;
  font-size: 18px;
}