.result-actions{}

.result-actions .icon-line{
  margin-bottom: 14px;
}

.result-actions .icon-line:last-child{
  margin-bottom: 0px;
}

.result-actions .icon-line.share_with_doctor img{
  
}