.question-title{
  margin-bottom: 10px;
}

.question-subtitle{
  margin-bottom: 10px;
  font-family: AlbertLight;
}

.edit-question-popup .question-renderer {
  min-height: 100vh;
  padding-bottom: 100vh;
}