.popup.medical-profile * {
  box-sizing: border-box;
}

.popup.medical_profile {
  overflow: initial;
}

.popup.medical_profile .popup-content {
  overflow: hidden;
}

.medical-profile-treatments {
  margin-bottom: 20px;
}

.medical-loading {
  padding: 10px;
  text-align: center;
  background: #efefef;
  margin-bottom: 10px;
}

.medical-profile-popup {
  overflow: initial;
  position: relative;
}

.medical-information-wrapper {
  border-bottom: 1px solid #e2e2e5;
  margin-bottom: 34px !important;
}

.medical-profile-popup .medical-profile-popup .actions {
  border-bottom: none;
}

.medical-profile-popup .popup-footer.popup-padding {
  position: relative;
  border-top: none;
  margin-bottom: 14px;
}

.medical-profile-popup .popup-footer.popup-padding button {
  display: flex;
  align-items: center;
  height: 36px;
  max-width: 100%;
  width: 100%;
  bottom: 0;
  padding: 16px 24px;
  margin-top: 40px;
  width: 100%;
  border: none;
}

.my-details-wrapper {
  margin-bottom: 34px !important;
}

.section-title-wrapper {
  margin-bottom: 14px !important;
}

.section-missed-parts .section-title-wrapper {
  margin-bottom: 14px !important;
  border-bottom: 1px solid #A6AAA5;
  padding-bottom: 4px;
}

.section-missed-parts .section-title-wrapper span {
  max-width: 24px;
  width: 100%;
}
.section-missed-parts .section-title-wrapper .title-wrapper{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.your-attention-wrapper {
  margin-bottom: 34px !important;
}

.medical-profile-popup .title {
  border-bottom: none !important;
  margin-bottom: 20px;
  font-family: 'AlbertLight';
}

.medical-profile-popup .part {
  margin-bottom: 30px;
}

.medical-profile-popup .part-box {
  cursor: pointer;
  background: #f2f2f2;
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.popup-visible .medical-profile-popup .medical-profile-popup-header {
  top: -44px;
}

.medical-profile-popup .subtitle {
  margin-bottom: 10px;
  color: #191919;
  font-size: 18px;
  font-family: 'AlbertBold';
}

.medical-profile-popup {
  padding-bottom: 0 !important;
}

.medical-profile-popup .property {
  margin-bottom: 5px;
}

.medical-profile-popup .property-value {
  font-size: 16px;
  font-weight: 600;
}

.medical-profile-popup .property-title {
  font-size: 14px;
  font-weight: 400;
  color: #848484;
  margin-bottom: -3px;
}

.medical-profile-popup .medical-profile-popup-header {
  position: absolute;
  top: 100px;
  text-align: center;
  width: 100%;
  left: 0;
  pointer-events: none;
  display: none;
  z-index: 1;
}

.medical-profile-popup .icon-wrapper {
  display: inline-block;
  padding: 8px;
  position: relative;
  top: -13px;
}

.medical-profile-popup .icon-wrapper img {
  width: 100px;
}

.medical-profile-popup .popup-property {
  margin-bottom: 14px;
  position: relative;
}

.medical-profile-popup .popup-property.empty .popup-property-value {
  border-bottom: 1px solid #d1d1d1;
  width: 100px;
  height: 20px;
  position: relative;
}

.medical-profile-popup .popup-property .popup-property-value {
  color: black !important;
  font-family: AlbertLight;
}

.medical-profile-popup .popup-property.highlighted .v-mark {
  display: flex;
}

.medical-profile-popup .popup-property-title {
  position: relative;
}

.medical-profile-popup .popup-property-title img {
  position: absolute;
  top: 14px;
}

.medical-profile-popup .actions {
  border-bottom: 1px solid #e2e2e5;
}

.medical-profile-popup .actions-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.medical-profile-popup .actions-title p {
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.medical-profile-popup .actions-title span {
  display: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 24px;
  width: 100%;
  height: 24px;
  border-radius: 50%;
  background-color: #EB4B62;
  color: #fff;
  margin-right: 4px;
}

.medical-profile-popup .action {
  margin-bottom: 14px;
}

.medical-profile-popup .action>div:nth-child(1) {
  max-width: 230px;
  overflow: hidden;
}

.medical-profile-popup .actions .button {
  max-width: 100px;
  font-size: 13px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.medical-profile-popup .language-menu .language-popup {
  position: static;
}

.medical-profile-system-language {
  margin-bottom: 14px;
}

.medical-drawer-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.medical-drawer-header {
  padding: 5px;
  text-align: end;
}

.medical-drawer-header img {
  cursor: pointer;
}

.medical-drawer-body {
  height: 100vh;
}

.medical-profile-popup .popup-property-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  font-weight: 600;
  border: 1px solid #FF8C69;
  border-radius: 12px;
  color: #FF8C69;
  background-color: #fff;
  cursor: pointer;
  padding: 0 24px;
  font-family: AlbertBold;
}

.medical-profile-popup .action .button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  font-weight: 600;
  border: 1px solid #FF8C69;
  border-radius: 12px;
  color: #FF8C69;
  background-color: #fff;
  cursor: pointer;
  max-width: fit-content;
  padding: 10px 24px;
}
.medical-profile-popup .popup-link {
  color: #191919;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 14px;
  line-height: 18px;
  font-size: 14px;
  font-style: normal;
  font-family: AlbertLight;
  font-weight: 400;
}
.medical-profile-popup .popup-link.logout {
  margin-bottom: 0;
  color:#0C9BED;
  text-decoration: underline;
}
.medical-profile-popup .popup-link.logout:hover {
  color:#0C9BED;
  text-decoration: none;
}
.medical-profile-popup .popup-link img{
  margin-right: 10px;
}
.medical-profile-popup .popup-property-main{
  width: 100%;
  padding-right: 5px;
}
.medical-profile-popup .popup-property-main .popup-property-title{
  display: inline;
  float: left;
  margin-right: 10px;
}

.section-missed-parts {
  border-radius: 8px;
  border: 1px solid var(--match-ligh-13, #D1D1D6);
  background: var(--Web--Yellow---10, rgba(244, 208, 111, 0.10));
  padding: 14px 10px 0;
  margin-bottom: 22px;
}

.section-missed-parts p {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
}
.section-missed-parts h3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; 
}
.section-missed-parts img {
  cursor: pointer;
}

.tooltip-wrapper {
  border: 1px solid var(--Web---Yellow, #F4D06F);
  border-radius: 6px;
  padding: 4px 8px;
  margin-bottom: 18px;
  display: none;
  opacity: 0;
  transition: 2s;
}

.tooltip-wrapper.visible {
  display: block;
  opacity: 1;
}

.tooltip-wrapper h3, .tooltip-wrapper p {
  padding: 0;
  margin: 0;
}
.title-content {
  width: 90%;
  display: flex;
  align-items: flex-start;
}
.title-content {
  font-size: 14px;
}
.title-content p {
  font-family: AlbertLight;
  font-weight: 400;
}
.title-content img {
  margin-right: 10px;
}
.title-close {
  display: flex;
  justify-content: flex-end;
}

.improve-accuracy{
  border: 1px solid #f8e2a7;
  border-radius: 3px;
  padding: 17px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.improve-accuracy-title{
  font-family: 'AlbertLight';
  font-size: 22px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.improve-accuracy-button{}

.new-improve-accuracy{
  border: 1px solid #D1D1D6;
  border-radius: 8px;
  background-color:  rgba(203, 232, 150, 0.10);
  padding: 8px 24px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.new-improve-accuracy-title{
  font-family: 'AlbertLight';
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  line-height: 32px;
  margin-bottom: 14px;
}

.new-improve-accuracy-button{
  font-size: 16px;
  font-weight: 600;
  font-family: 'AlbertLight';
  padding: 4px 8px;
  margin-bottom: 14px;
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
}

.new-improve-accuracy .button-text {
margin-left: 20px;
}

@media only screen and (max-width: 800px) {
  .medical-profile-popup .popup-header {
    height: 40px;
  }
}

@media only screen and (max-width: 400px) {
  .new-improve-accuracy-button img {
    left: 13px;
  }
}
