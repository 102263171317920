.records-wrapper {
    font-family: 'AlbertLight';
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.records-title {
    text-align: center;
    font-family: 'AlbertBold';
    font-size: 28px;
    margin-bottom: 8px;
}
.records-wrapper > div {
    max-width: 690px;
    min-width: 690px;
}
.records-wrapper .step-number {
    font-size: 28px;
    font-weight: 700;
    color: #2EC4B6;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
}
.records-wrapper  .title {
    font-size: 20px;
    margin: 0 4px 8px 4px;
}
.records-wrapper .step-one-wrapper {
    background-color: #F4D06F1A;
    padding: 14px 4px;
}
.records-wrapper .step-one {
    display: flex;
    align-items: center;
}
.records-wrapper .one{
    font-size: 28px;
    font-weight: 700;
    border: 2px solid #2EC4B6;
    border-radius: 50%;
    color: #213332;
}
.records-wrapper .copy-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 14px;
    margin-top: 12px;
}
.records-wrapper .copy {
    cursor: pointer;
}
.records-wrapper  .step-one-email {
    font-size: 20px;
    font-weight: 700;
    color: #213332CC;
}
.records-wrapper .copy {
    display: inline-flex;
    align-items: center;
    background-color: #FF8C69;
    border-radius: 8px;
    padding: 4px;
    color: #fff;
}
.records-wrapper .copy-icon {
    margin-right: 8px;
}
.records-wrapper .step-two-wrapper {
    display: flex;
    align-items: center;
    margin-top: 8px;
}
.records-wrapper .step-two-content {
    line-height: 24px;
}
.records-wrapper .step-two-content span {
    font-weight: 600;
}
.records-wrapper .step-three-wrapper {
    display: flex;
    align-items: center;
    line-height: 24px;
    margin-top: 14px;
}
.records-wrapper .next-btn {
    display: flex;
    justify-content: center;
}

.records-wrapper .records-bottom  {
    margin-top: 72px;
}
.records-wrapper .records-bottom .records-bottom-text {
    padding: 0 28px;
    font-family: 'Roboto';
    font-size: 10px;
}
.records-wrapper .records-bottom .records-bottom-text a {
    color: #FF8C69;
}
.records-wrapper .subtitle {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 32px;
    padding: 0 4px;
}

@media only screen and (max-width: 800px) {
    .records-wrapper > div {
        width: 100%;
        max-width: unset;
        min-width: unset;
    }
}

.records-wrapper .email-form {
    margin: 0 3rem 1rem;
}

.records-wrapper .message {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 2rem;
}