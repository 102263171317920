.root {
    padding: 2px 4px;
    color: #213332;
    background-color: #EB4B6233;
    font-size: 14px;
    line-height: 20px;
}
@media only screen and (max-width: 800px) {
    .root {
        padding: 2px;
    }
}
