.language-menu {
  position: relative;
}

.language-menu.open {}

.language-menu .language-popup {
  position: absolute;
  right: calc(100% - 35px);
  top: calc(100% + 3px);
  background: #fff;
  padding: 20px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  display: none;
}

.rtl .language-menu .language-popup {
  right: initial;
  left: calc(100% - 35px);
}

.language-menu.open .language-popup {
  display: block;
}

.language-menu .current {
  cursor: pointer;
  color: #767676;
}

.language-menu .fullwidth-wrapper {
  padding: 4px 10px;
  border-radius: 4px;
  border: 1px solid var(--match-ligh-13, #D1D1D6);
}
.language-menu .fullwidth-wrapper p {
  padding: 0;
  margin: 0;
}
.language-menu .fullwidth-wrapper .current svg {
  top: 0;
}

.language-menu .fullwidth-wrapper .current {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fullwidth-wrapper .title-wrapper {
  display: flex;
  align-items: center;
}
.language-menu .fullwidth-wrapper .title-wrapper p {
  font-family: AlbertBold;
  color: black;
  font-weight: 700;
  margin-right: 5px;
}
.language-menu .fullwidth-wrapper .title-wrapper span {
  font-family: AlbertLight;
  color: black;
}
.language-menu .fullwidth-wrapper svg *{
  fill: black !important;
}

.language-menu .current span {
  text-transform: uppercase;
}

.language-menu.open .current {
  color: #0C9BED;
}

.language-menu .current svg {
  transform: rotate(90deg) scale(0.9);
  position: relative;
  top: 4px;
  left: -2px;
}

.language-menu.open .current svg {
  transform: rotate(-90deg) scale(0.9) !important;
  top: 2px !important;
}

.language-menu .current svg * {
  fill: #767676;
}

.language-menu.open .current svg * {
  fill: #0C9BED;
  ;
}

.rtl .language-menu .current svg {
  left: 2px;
}