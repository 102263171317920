.root {
    position: relative;
    align-items: center;
    display: flex;
    gap: 5px;
    height: 40px;
    padding: 0 12px;
    border: 1px solid transparent;
    border-radius: 20px;
    color: white;
    background-color: #ff8c69;
    font-family: AlbertLight;
    cursor: pointer;
}
.root:hover {
    color: #FF8C69;
    background-color: rgb(255, 140, 105, 0.1);
    border: 1px solid rgb(255, 140, 105, 0.1);
}
.statusCircle {
    position: absolute;
    left: -4px;
    top: 3px;
    width: 15px;
    height: 15px;
    background-color: #eb4b62;
    border-radius: 20px;
}
.label {}

@media only screen and (max-width: 800px) {
    .label {
        display: none;
    }
}
