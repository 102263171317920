.slider-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 44px 24px 24px 24px;
}
.slider-wrapper .slider-image-block {
    display: flex;
    justify-content: center;
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
}
.slider-wrapper .slider-image-block img {
    width: 100%;
}
.slider-wrapper .swiper-block {
    display: flex;
    justify-content: flex-start;
}
.slider-wrapper  .swiper-3d {
    max-width: 500px;
    width: 100%;
    margin: 0;
    perspective:unset
}
.slider-wrapper .swiper-button-prev {
    top: 305px;
    background-image: url("./images/right_left.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 30px;
    z-index: 9999;
}
.slider-wrapper .swiper-button-next {
    top: 305px;
    left: 250px;
    background-image: url("./images/right_arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 30px;
    z-index: 9999;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    max-width: 300px;
    width: 100%;
}
.swiper-block .name {
    margin-top: 8px;
    font-size: 16px;
    font-family: 'Albert';
}
.swiper-block .condition {
    margin-top: 8px;
    font-size: 16px;
    font-family: 'AlbertLight';
}
.swiper-block .comment {
    margin-top: 8px;
    font-size: 20px;
    font-family: 'AlbertLight';
    color: #21333299;
    padding-bottom: 140px;
}
.slider-wrapper .swiper-pagination-bullet {
    background-color: #5B9BF3;
}


@media only screen and (max-width: 800px) {
    .slider-wrapper {
        grid-template-columns: 1fr ;
    }
    .slider-wrapper .swiper-block {
        display: flex;
        justify-content: center;
        margin-top: 14px;
    }
}

@media only screen and (max-width: 550px) {
    .slider-wrapper .swiper-3d {
        max-width: 300px;
    }
}