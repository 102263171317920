.datepicker{
  position: relative;
  font-family: 'AlbertLight';
}

.datepicker.datepicker-inline .datepicker-picker{
  position: static;
}

.datepicker-value-wrapper{
  position: relative;
}

.datepicker-value-wrapper img{
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.datepicker .datepicker-float{
  position: fixed;
  z-index: 99;
}

.datepicker .datepicker-input{
  width: 100%;
  box-sizing: border-box;
}

.datepicker-visible .datepicker-chooser{
  display: block !important;
}

.datepicker-chooser{
  display: none;
  position: relative;
}

.datepicker-picker{
  width: 100%;
  min-height: 200px;
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  position: absolute;
}

.datepicker-value{
  font-size: 16px;
  padding: 12px 16px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid #ccc;
  direction: ltr;
}

.datepicker-visible .datepicker-value{
  border: 1px solid #81CFFA;
}

.datepicker-picker-header{
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.datepicker-picker-header .datepicker-page{
  cursor: pointer;
  padding: 10px;
}

.datepicker-picker-header .datepicker-page:hover{
  background: #eee;
  border-radius: 3px;
}

.datepicker-picker-body{
  
}

.datepicker-picker-middle{
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 34px;
}

.datepicker-current-view{
  background: #686868;
  padding: 3px;
  border-radius: 3px;
  color: #fff;
}

.datepicker-picker-middle > *{
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.datepicker-month{}

.datepicker-year{}

.datepicker-table{
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
}

.datepicker-table-cell{
  height: 52px !important;
}

.datepicker-table-cell:hover{
  background: #eee;
  cursor: pointer;
}

.datepicker-table td{
  width: 42px;
  height: 34px;
  text-align: center;
  border-radius: 8px;
}

.datepicker-date-cell{
  cursor: pointer;
}

.datepicker-date-cell:hover{
  background: #eee;
}

.datepicker-date-selected{
  background-color: #0C9BED !important;
  color: #fff !important;
  font-weight: 600;
}

.datepicker-head-cell{
  color: rgba(33, 51, 50, 0.4);
}

.datepicker-date-inactive{
  color: rgba(33, 51, 50, 0.1);
}

.datepicker-date-today{
  color: #FF8C69;
  font-weight: 600;
}

.datepicker-date-no-selection{
  opacity: 0.2;
  cursor: default;
  pointer-events: none;
}