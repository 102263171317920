.see-all-options {
    order: 1;
}

.see-all-options .content {
    padding: 30px;
}

.see-all-options .info {
    margin-top: 20px;
    font-weight: bold;
}

.see-all-options-button{
    background: #0c9bed;
    text-decoration: none;
    width: 100%;
}