.whats-next{
  margin-bottom: 10px;
}

.mobile-only .whats-next{
  margin-top: 10px;
}

.whats-next .steps{
  background: #fff;
  padding: 15px;
  font-size: 14px;
}

.whats-next .steps .step{
  align-items: baseline;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.whats-next .steps .step-number{
  min-width: 30px;
  position: relative;
  top:5px;
  left: -5px;
}

.rtl .whats-next .steps .step-number{
  right: -5px;
  left: initial;
}

.whats-next .steps .step-content{}

.whats-next .steps .step-content a{
  font-size: 14px !important;
  line-height: inherit !important;
  color: rgb(58 130 247) !important;
  text-decoration: none !important;
}

.whats-next .steps .step-number span{
  display: inline-block;
  background: #0C9BED;
  color: #fff;
  padding: 4px;
  border-radius: 17px;
  width: 16px;
  text-align: center;
}