.profile-button{
  position: relative;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile-button .profile-button-actions{
  position: absolute;
  top:100%;
  background-color: #fff;
  right: 0px;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  border-radius: 5px;
}

.profile-button .profile-button-action{
  font-family: AlbertLight;
  padding: 5px;
}

.profile-button .profile-button-action:hover{
  background-color: #efefef;
  cursor: pointer;
}
