.upload-popup {
    padding: 14px 39px 41px 39px !important;
}
.upload-popup .browse a {
    color: #0C9BED !important;
}
.upload-popup  .upload-popup-title {
    font-size: 28px;
    text-align: center;
    margin-bottom: 14px;
}
.upload-popup .files-uploader-multi-buttons {
    display: flex;
}
.upload-popup .files-uploader-multi-buttons .multi-button-holder {
    width: 100%;
    margin-top: 0;
    margin-left: 10px;
}
.upload-popup .files-uploader .files-uploader-preview {
    border: none;
}
.upload-popup .upload-popup-title {
    margin-bottom: 24px;
}
.upload-popup .files-uploader-preview-text {
    margin-bottom: 20px;
}

.upload-popup .uploading {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-popup .uploading .uploading-animate {
    min-height: 90px;
}

.upload-popup .uploading .text {
    margin-top: 14px;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    max-width: 320px;
    min-height: 64px;
}

.upload-popup{
    min-height: 200px;
}

.upload-popup .top-title{
    font-family: AlbertLight;
    margin-bottom: 10px;
}

.upload-popup .upload-intake-block{
    max-height: 500px;
    overflow: auto;
}

.popup .animate .close {
    display: none !important;
}

.upload-popup .desktop-files-uploader img {
    width: 43px;
    height: 43px;
}

.upload-intake-parent{
    padding-bottom: 10px;
}

.file-uploader-title{
    text-align: center;
    font-size: 26px;
    margin-bottom: 10px;
}
.file-uploader-text{
    padding: 20px;
}

.upload-popup .files-uploader-progress{
    display: none;
}

.upload-popup .upload-lottie-holder{
    width: 50%;
    height: ;
}

.upload-popup .upload-title{
    font-size: 25px;
    margin-bottom: 20px;
}

.upload-preloader{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.upload-preloader svg{
    height: 140px !important;
}

.upload-popup-preloader-bar{
    margin-bottom: 10px;
}

.upload-popup .upload-preloader .skip{
    display: none;
    margin-top: 10px;
}

.upload-popup .upload-preloader .skip.visible{
    display: block;
}

.upload-popup-preloader-bar svg{
    position: relative;
    top: -45px;
}

@media only screen and (max-width: 800px) { /* mobile mode */
    .upload-intake-parent{
        padding-bottom: 60px;
    }
}