.multi-pages{}

.multi-pages .multi-pages-selector{
}

.multi-pages .multi-pages-selector-item{
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
}

.multi-pages .multi-pages-selector-header{
  display: flex;
  justify-content: space-between;
}

.multi-pages .multi-pages-selector-title{
  font-size: 18px;
  word-break: break-word;
}

.multi-pages .multi-pages-selector-edit{
  cursor: pointer;
}

.multi-pages .multi-pages-selector-menu{
  gap: 10px;
}

.multi-pages .multi-pages-selector-body{
  font-family: 'AlbertLight';
}

.multi-pages .multi-pages-selector-body ul{
  padding-inline-start: 16px;
}

.multi-pages .multi-pages-selector-body li{
  margin-bottom: 5px;
}

.multi-pages .multi-pages-selector-menu{
  display: flex;
}

.multi-pages .multi-pages-selector-item-menu{
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}

.multi-pages .multi-pages-subtitle{}

.multi-pages .multi-pages-selector .button{}

.multi-pages .multi-pages-submenu{
  margin-top: 10px;
}