.popup.consult_with_us{
    height: 100% !important;
    overflow: hidden;
}
.popup .consult-with-us{
    height: 100% !important;
    width: 100%;
    display: table;
}

.popup .consult-with-us > div{
    display: table-row;
}

.popup .consult-with-us-title{
    height: 10px;
}

.popup .consult-with-us-description{
    height: 10px;
}

.popup.consult_with_us .popup-content > div{
    /* padding: initial; */
}

.popup.consult_with_us .popup-content {
    overflow: hidden;
    height: calc(100% - 27px);
}

.popup .popup-content .calendly-inline-widget {
    height: 100% !important;
}

.calendly-inline-widget iframe, .calendly-badge-widget iframe, .calendly-overlay iframe{
    /* min-height: 760px; */
}

@media only screen and (max-width: 800px) {

    .popup-visible .popup.consult_with_us {
        right: initial;
        bottom: 0%;
    }
}