.popup.contact_us{
  bottom: 0;
}

.contact-us{}

.contact-us .privacy{
  text-align: center;
  font-size: 12px;
  font-family: 'AlbertLight';
  margin-top: 5px;
}