
.STATE_UPLOADING .popup-header img{
  display: none;
}

.files-uploader{}


.files-uploader .mobile-files-uploader{
  display: none;
}

.files-uploader .desktop-files-uploader{
  background-color: #fff4f0;
  border: 3px dashed #ff8c69;
  font-family: AlbertLight;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.2s ease-out;
}

.files-uploader .desktop-files-uploader > div{
  text-align: center;
}

.files-uploader .desktop-files-uploader.dragged{
  border: 2px dashed #00a2ff;
  background-color: #f0fcff;
  transform: scale(1.4);
}

.files-uploader .mobile-files-uploader-overlay{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #0000008c;
}

.files-uploader .mobile-files-uploader-overlay-buttons{
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.files-uploader .mobile-files-uploader-overlay-button{
  background-color: #e4e4e4;
  color: #007AFF;
  margin-top: 5px;
  text-align: center;
  font-family: AlbertLight;
  padding: 15px;
  font-size: 17px;
  border-radius: 10px;
  cursor: pointer;
}

.files-uploader .mobile-files-uploader-overlay-button:first-child {
  border-radius: 10px 10px 0 0;
}

.files-uploader .mobile-files-uploader-overlay-button:nth-child(2) {
  border-radius: 0 0 10px 10px;
  margin-top: 0;
  border-top: 0.5px solid #3C3C435C;
}

.files-uploader .multi-upload{

}

.files-uploader .multi-display{
  /* display: flex; */
  /* gap: 20px; */
  /* flex-wrap: wrap; */
  overflow: auto;
  padding-bottom: 10px;
}

.files-uploader .multi-button-holder{
  margin-top: 20px;
}

.files-uploader .files-uploader-preview{
  /* width: 150px; */
  /* height: 200px; */
  
  border-radius: 10px;
  position: relative;
  border:1px solid #efefef;
  /* flex-shrink: 0; */
  overflow: hidden;
  margin-bottom: 10px;
}

.files-uploader .files-uploader-preview-header{
  display: flex;
  justify-content:space-between;
  align-items: center;
}

.files-uploader-preview-icon{

}

.files-uploader-preview-text{
  margin-inline-start: 10px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.files-uploader-preview-rmove{

}

.files-uploader-preview-header > div{
  display: flex;
  align-items: center;
  font-family: 'AlbertLight';
  max-width: 91%;
  overflow: hidden;
}

.files-uploader .files-uploader-preview > div{
  padding: 10px;
}

.files-uploader .files-uploader-preview object{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: none;
  transform: scale(1.1);
}

.files-uploader .files-uploader-preview img.preview-object{
  width: 100%;
  height: 100%;
}

.files-uploader .files-uploader-plus{}

.files-uploader .files-uploader-add-more{
  border: 2px solid #FF8C69;
  color: #FF8C69;
  cursor: pointer;
  text-align: center;
}

.files-uploader .files-uploader-add-more-text{}

.files-uploader .files-uploader-preview-menu{
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  box-sizing: border-box;
}

.files-uploader .files-uploader-preview-menu-button{
  width: 30px;
  height: 30px;
  background-color: #efefef;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.files-uploader-camera-preview{
  display: none;
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
}
.files-uploader-camera-preview.visible{
  display: flex;
  background: #ffffffe3;
}
.files-uploader-camera-preview-top{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1;
}

.files-uploader-camera-preview-top > div{
  cursor: pointer;
  color: #fff;
  font-size: 27px;
  background-color: rgba(255, 255, 255, 0.144);
  border-radius: 100px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.files-uploader-camera-preview-bottom{
  position: absolute;
  bottom:0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;
}
.files-uploader-camera-photo-button{
  width: 70px;
  height: 70px;
  border:6px solid #fff;
  border-radius: 50px;
  box-shadow: 0px 0px 10px #000;
  cursor: pointer;
}
.files-uploader-camera-canvas{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

.files-uploader-camera-canvas canvas{
  width: 100%;
  height: 100%;
}

.files-uploader-preview-title{
  text-align: center;
  font-family: AlbertLight;
}

.files-uploader-preview-num{
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: center;
  font-family: AlbertLight;
}

.files-uploader-preview-error .files-uploader-preview-text{
  color: #EB4B62 !important;
}

.files-uploader-preview-remove{
  margin-inline-start: 10px;
}

.files-uploader-preview-error-tag{
    border: 1px solid #EB4B62 !important;
    padding: 3px;
    /* margin-inline-end: 10px; */
    font-size: 12px;
    border-radius: 2px;
}

.files-uploader-success .files-uploader-preview-error-tag{
  display: none;
}

.files-uploader-progress{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0px !important;
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
}

/* .files-uploader-progress-bar{
  background-color: #EB4B62 !important;
  width: 100% !important;
} */

.files-uploader-progress{
  background-color: #2EC4B6;
  width: 0%;
  border-radius: 5px;
  height: 14.5px;
  display: none;
}

.files-uploader-progress-percent{
  margin-inline-start: 5px;
  font-family: AlbertLight;
}

.files-uploader-preview-error .files-uploader-progress-bar{
  background-color: #EB4B62 !important;
  width: 100% !important;
}

.files-uploader-select-buttons{}

.uploader-start-content {
  border: 1px solid #D1D1D6;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4px;
  height: 127px;
  width: 100px;
}

.files-uploader-multi {
  padding: 0 10px;
}
.files-uploader-error {
  margin : 0 10px;
}

@media only screen and (min-width: 800px) { /* desktop mode */
  .files-uploader-select-buttons .button{
    margin-inline-end: 10px;
  }
}

@media only screen and (max-width: 800px) { /* mobile mode */
  .files-uploader .desktop-files-uploader:not(.desktop-edit-mode .files-uploader .desktop-files-uploader){
    display: none;
  }
  .files-uploader .mobile-files-uploader:not(.desktop-edit-mode .files-uploader .desktop-files-uploader){
    display: block;
  }
  .desktop-edit-mode .files-uploader .mobile-files-uploader{
    display: none !important;
  }
  .files-uploader .multi-button-holder:not(.desktop-edit-mode .files-uploader .desktop-files-uploader){
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
  }
  .files-uploader-select-buttons .button{
    margin-bottom: 10px;
  }
}