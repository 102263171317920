.publication-section {
    margin-top: 20px;
    padding: 10px;
  }
  
  .publication-section h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;

  }
  
  .publication-item {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
  }
  
  .publication-source {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #777;
    margin-bottom: 5px;
  }
  
  .publication-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .publication-summary {
    font-size: 14px;
    color: #555;
  }