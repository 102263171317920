.popup.personalize .personalize{
  /* padding-left: 10px;
  padding-right: 10px; */
}

.personalize .change-location-button{
  text-align: center;
  color: #0C9BED;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 18px;
}

.personalize .change-location-button:hover{
  /* text-decoration: underline; */
  cursor: pointer;
}

.personalize .form-input.multi-checkbox{
  max-height: 216px;
  overflow: auto;
}

.personalize .form-input.checkbox{
  padding: 0;
  border: none;
}

.phase .multi-checkbox{
  max-height: inherit !important;
  overflow: visible !important;
}