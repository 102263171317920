
.result-list-header{
  position: sticky;
  top:-2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}

.result-list .results-header{
  display: flex;
  align-items: center;
  /* margin-bottom: 20px; */
}

.result-list .results-title{
  font-family: 'AlbertLight';
  margin-bottom: 15px;
  margin-top: 5px;
}

.result-list .results-title span{
  background: #fff;
  display: inline-block;
  border-radius: 20px;
  width: 20px;
  text-align: center;
  border: 1px solid #ccc;
  cursor: pointer;
}

.result-list .results-container{
  order: 1;
}

.result-list .result-list .results-header-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.result-list .result-list .results-header-icon img{
  width: 22px;
}

.result-list .download-button img{
  margin-inline-end: 5px;
}

.result-list .result-list-mobile-header-actions{
  display: flex;
  align-items: center;
  /* border-left: 1px solid #ccc; */
  padding-inline-start: 10px;
}

.result-list .result-list .results-header-title{
  font-size: 17px;
  margin-inline-start: 5px;
  font-family: AlbertLight;
  max-width: 330px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result-list .results-header-button{
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  border-radius: 15px;
}

.result-list .result-list-header{
  /* padding: 14px 0!important; */
  font-family: "AlbertBold";
}

.result-list .results-header-button:hover{
  background-color: #f5dedb;
}

.result-list .results-filters img{
  filter: saturate(2110%) hue-rotate(152deg) sepia(0.5);
}

.result-list .item-box .consult-button{
  display: none;
}

.result-list .item-box .nct-number{
  /* display: none; */
}

.result-list .item-box .learn-more{
  max-width: 350px;
}

.result-list .mobile-biomarkers{
  display: none;
}

.result-list .result-list-parameters .form-part.biomarkers{
  /* display: none; */
}

.result-list .results-header-title{
  font-size: 17px;
  margin-inline-start: 5px;
  font-family: AlbertLight;
  max-width: 330px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result-list .results-header-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.result-list .results-header-icon img{
  width: 22px;
}

.result-list .result-list-subtitle{
  margin-bottom: 25px;
  font-family: 'AlbertBold';
}

.result-list .results-tabs{
  background: #fff;
  /* padding: 20px; */
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  font-size: 30px;
  position: sticky;
  top:-12px;
  z-index: 10;
}

.result-list .results-tabs .results-tab{
  font-family: 'AlbertXLight';
  padding-top: 20px;
  padding-bottom: 10px;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  color: #FF8C69;
}

.result-list .results-tabs .results-tab.selected-tab{
  font-family: 'AlbertBold';
  border-bottom: 4px solid #FF8C69;
  color: black;
}

.result-list .result-list-mobile-header-wrapper{
  position: sticky !important;
  top:0px;
  z-index: 1;
}

.result-list .result-list-mobile-header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  padding-bottom: 5px;
  padding-top: 5px;
  /* background-color: #fff; */
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.result-list-mobile-header-options{
  
}

.result-list-mobile-header-glass{
  display: flex;
  align-items: center;
  background-color: #ff8c69;
  width: 35px;
  position: relative;
  left: -15px;
  justify-content: center;
  padding: 15px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  cursor: pointer;
  box-shadow: 0px 3px 5px rgba(0,0,0,0.2);
}

.result-list .result-list-mobile-header-tabs{
  display: flex;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}

.result-list .result-list-mobile-header-tabs img{
  margin-inline-start: 10px;
}

.result-list-mobile-header-actions-menu{
  /* padding: 10px; */
  padding-inline-end: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.result-list .result-list-mobile-tabs{
  position: absolute;
  justify-content: center;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  z-index: 10;
  display: flex;
}

.result-list .result-list-mobile-tabs.mobile-tabs-show{
  pointer-events: all;
  opacity: 1;
}

.result-list .result-list-mobile-tabs .result-list-mobile-tabs-content{
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  display: flex;
  position: relative;
  /* top:5px; */
}

.result-list .result-list-mobile-tab{}

.result-list .result-list-mobile-tab-selected{
  color: #ff8c69;
}

.result-list .result-list-mobile-tabs-content-titles{
  cursor: pointer;
  font-size: 22px;
}

.result-list .result-list-mobile-tabs-content-arrow{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-start: 20px;
}

.result-list .result-list-mobile-actions{
  position: absolute;
  display: none;
  right: 10px;
  background: #fff;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #00000036;
  z-index: 1;
}

.result-list .mobile-actions-show{
  display: block;
  top: 15px;
}

.result-list .result-list-mobile-actions .results-header{
  display: block;
  margin-bottom: 0;
}

.result-list .result-list-mobile-actions .results-header-button{
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.result-list .result-list-empty{
  
}

.result-list .result-list-change-location{}

.result-list .result-list-change-location-title{}

.result-list .result-list-change-location-input{}

.result-list .result-list-change-location-bullet{}

.result-list .result-list-change-location-bullet-circle{}

.result-list .bullet-selected{}

.results-header .button-item{
  /* display: block; */
}

.results-header > .flex{
  /* display: block !important; */
}

.results-header{
  /* font-family: AlbertLight; */
  cursor: default;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.results-details-header{
  cursor: default;
  padding-bottom: 5px;
  justify-content: space-between;
}

.results-header.bottom-line {
  border-bottom: 1px solid #21333266;
  margin-bottom: 15px;
}
.results-header-name{
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-family: 'AlbertBold';
}
.results-header-details {
  padding-bottom: 8px;
}
.results-header-details, .results-see-more{
  font-size: 16px;
  font-family: 'AlbertLight';
}
.results-header-details p {
  margin: 12px 12px 12px 0;
  display: inline;
}
.results-header-details p:last-child {
  margin-bottom: 8px;
}
.results-see-more{
  margin: 0;
  padding: 0;
  color: #0C9BED;
  cursor: pointer;
}

main[class="main-dashboard/results"] .results-tabs{
  display: none;
}

main[class="main-dashboard/results"] .result-list-mobile-header-tabs{
  pointer-events: none;
}

main[class="main-dashboard/results"] .result-list-mobile-header-tabs img{
  display: none;
}

main[class="main-dashboard/results"] .full-screen-smartchat{
  display: flex;
  justify-content: center;
  width: 750px;
  /* border: 2px solid; */
  background: white;

}

main[class="main-dashboard/results"] .grey-overlay{
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .main-search{
    padding: 0 !important;
  }
  .widget.result-list{
    padding: 0px;
  }
  .result-list .results-items{
    padding: 5px;
  }
  .result-list h1{
    margin-bottom: 15px !important;
  }
  .result-list .grid {
    display: flex;
    flex-direction: column;
  }
  .result-list .item-box .learn-more{
    max-width: 100%;
  }
  .result-list .desktop-biomarkers{
    display: none;
  }
  .result-list .mobile-biomarkers{
    display: block;
  }
  .result-list .result-list-parameters{
    margin-bottom: 5px;
  }
  .result-list .result-list-subtitle{
    border-top: 1px solid #ccc;
    padding-top: 10px;
  }
  .result-list .results-title{
    padding-left: 10px;
    padding-right: 10px;
  }
  .result-list .results-items{
    padding: 5px;
    padding-top: 0px;
  }
  .result-list .result-list-header{
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .results-header-details p {
    margin-bottom: 6px;
    display: block;
  }
  .results-header-details {
    padding-bottom: 0;
  }
  .result-list .results-header{
    display: block;
    margin-bottom: 0px;
  }
  .result-list .results-header > .flex{
    display: block;
  }
}