.icon-line{
  gap: 10px;
}
.icon-line span::first-letter{
  text-transform: capitalize;
}
.icon-line span{
  font-size: 16px;
  font-family: 'AlbertLight';
}
.icon-line.no-margin{
  margin-bottom: 0;
}
.icon-line.cursor{
  cursor: pointer;
}
.enrollment .icon-line.middle{
  margin-bottom: 0;
}

.icon-line.inline{
  display: inline-flex !important;
}
