
.ai-assistant .profile-header-actions{}

.ai-assistant .ai-area{
  background-color: #f2f2f7;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  text-align: center;
  margin-bottom: 10px;
}

.ai-assistant .title{
  margin-bottom: 0;
  text-align: center;
}

.ai-assistant .ai-area .buttons{
  display: flex;
  justify-content: center;
}

.ai-assistant .ai-area .button{
  margin-top: 10px;
}

.ai-assistant .intake-area{
  display: flex;
  justify-content: center;
}

.ai-assistant .button{
  font-family: 'Albert';
}

.ai-assistant .intake-button{
  margin-bottom: 20px;
  width: 235px !important;

}

.ai-assistant .info{
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.ai-assistant .info img{
  margin-inline-end: 7px;
}

.ai-assistant .upload-nav{
  /* display: flex; */
}

.popup.ai_assistant .title{
  margin-bottom: 10px;
  font-size: 18px;
}

/* THEMES START */
.ai-assistant.dark .title {
  display: none;
}
.ai-assistant.dark .intake-area {
  display: none;
}
.ai-assistant.dark .ai-area {
  margin: initial;
  padding: initial;
  border: initial;
  background: transparent;
}
.ai-assistant.dark .info {
  display: none;
}
.ai-assistant.dark .buttons .button {
  margin: initial;
  background-color: transparent !important;
  padding: 0px;
}
.ai-assistant.dark .buttons .button:hover {
  border: initial !important;
  color: #fff !important;
  background-color: transparent !important;
}
.ai-assistant.dark .buttons .button img {
  display: block;
  margin-right: 0px;
}
.ai-assistant.dark .buttons .button .button-text {
  margin-left: 8px;
}
/* THEMES END */