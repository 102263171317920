.popup.chat {
  padding-bottom: 90px;
}

.chat-popup {
  background-color: white;
  height: 100%;
  padding: 10px;
  background-color: #F7F7F8;
  overflow-x: hidden;
}

.popup.chat .popup-content{
  overflow:hidden;
}

.chat-popup-conversation {
  margin: auto;
}

.popup.chat .chat-message {
  margin-bottom: 10px;
}

.popup.chat .chat-input {
  width: 100%;
    right: 0;
    bottom: -5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
  }
  

  .popup.chat .chat-input .flex-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    }
    


  
.popup.chat .chat-input button {
    position: absolute;
    /* right: 20px; */
    border: none;
    background: none;
    font-size: 20px;
  }

.popup.chat .chat-icon{
    margin-top: 10px;
    height: 20px;
  }

  .chat-actions-panel-divider-fullmode{
    margin-bottom: -46px;
  }

  .popup.chat .chat-actions-panel-divider{
    height: 20px;
  }

  .popup.chat .chat-actions-panel{
    position: absolute;
    margin-left: -9px;
    box-shadow: 0px 1px 0px 0px #000;
    width: 100%;
  }

  .popup.chat .link{
   color: #FF8C69;
  }

  .popup.chat .chat-role{
   margin-top: 10px;
  }

  .popup.chat .chat-message-content{
    display: flex;
  }

  .popup.chat .chat-message-text{
    padding: 8px;
    font-family: 'AlbertLight';
  }

  .chat-popup .thumb-icon{
    cursor: pointer;
  }

  .chat-popup .chat-message-thumb{
    margin-left: 30px;
  }

  .chat-popup .chat-message-thumb:hover{
  }

  .chat-input #support {
    margin-top: 10px; /* Adjust as needed */
    width: 100%; /* Ensure it takes full width */
    text-align: center; /* Center the text */
   }

   .chat-intake{

    width: 330px;
    margin-top: 25px;
    z-index: 1;
  }
  

  .chat-intake-done-button{
     background-color: rgb(255, 140, 105);
    color: rgb(255, 255, 255);
    width: 95px;
    height: 40px;
    border: none;
    border-radius: 15px;
    font-weight: 700;
    font-size: medium;
    cursor: pointer;
  }

  .chat-intake-done-button:hover{
    background-color: rgb(255, 140, 105, 0.1) !important;
    border: 1px solid rgb(255, 140, 105, 0.1) !important;
    color: #FF8C69 !important;
  }
  
  .chat-intake-done-button:disabled{
    background-color: rgb(255, 140, 105, 0.1) !important;
    border: 1px solid rgb(255, 140, 105, 0.1) !important;
    color: #9e9e9e !important;
    cursor: not-allowed;
    
  }


  .chat-popup .next-scan{
      /* padding: 25px;
      margin-bottom: -300px; */
  }

  .chat-popup .next-scan-full{
    padding: 75px;
    margin-bottom: -90px;
  }



  
  
 