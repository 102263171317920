.root {
    height: 40px;
    padding: 0 12px;
    border-radius: 20px;
    background-color: #F2F2F7;
    font-family: AlbertLight;
    cursor: pointer;
    align-items: center;
    display: flex;
    position: relative;
    border: 1px solid #ff8c69;
}
.resultsCount {
    background-color: #fff;
    font-size: 14px;
    font-family: Albert;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-end: 10px;
    color: #000;
    border: 1px solid #ccc;
}
.loader {
    width: 40px;
    position: absolute;
    left: 1px;
    top: 0px;
}
.zeroOpacity {
    opacity: 0;
}
.hidden {
    display: none;
}
.resultsTitle {
    margin-inline-end: 10px;
    color: #213332;
    font-family: 'Albert';
}
.resultsIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FF8C69;
    border-radius: 20px;
    padding: 5px;
}
.resultsIcon {
    width: 20px;
}
