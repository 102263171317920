.points-to-discuss{
  
}
.points-to-discuss h2 {
  text-align: center;
  
}

.points-to-discuss .widget-body{
  /* padding: 20px !important; */
}

.points-to-discuss .bullet{
  margin-bottom: 20px;
  font-family: AlbertLight;
  display: flex;
}

/* .points-to-discuss button.consult-button{
  display: none !important;
} */

.treatment.isSearchPage .points-to-discuss button.consult-button{
  display: none !important;
}

.points-to-discuss .bullet img{
  margin-inline-end: 10px;
}

.points-to-discuss button{
  max-width: 350px;
  display: inline-flex !important;
}

@media only screen and (max-width: 800px) {
  .points-to-discuss button{
    max-width: 100%;
  }
}