.number-input-question{

}

.number-input-question .number-input-question-input{
  margin-bottom: 10px;
}

.number-input-question input{
  padding: 10px;
  font-size: 16px;
  width: 80px;
  border: 1px solid #333;
  /* border-radius: 10px; */
}

.number-input-question .number-input-instructions{
  margin-bottom: 10px;
}

.number-input-question span{
  
}