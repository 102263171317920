.feed-page {
    margin: auto;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.feed-page-container {
    width: calc(100% - 420px);
}
.feed-page .feed-sidebar {
    width: 375px;
}
.feed-page .ai-assistant {
    background-color: #fff;
    position: sticky;
    top: 150px;
    padding: 30px 0px 12px 0px;
    border-radius: 4px;
}
.feed-page .ai-assistant .ai-area {
    background-color: #fff;
    padding-bottom: 15px;
}
.feed-page .ai-assistant .ai-area {
    margin-bottom: 15px;
}
.feed-page .ai-assistant .ai-area .button, 
.feed-page .ai-assistant .intake-button {
    min-width: 250px;
}
.feed-page .welcome {
    margin-bottom: 28px;
}
.welcome.welcome-mobile {
    display: none;
}
.welcome {
    display: block;
}

@media only screen and (max-width: 1200px) {
    .feed-page {
        flex-direction: column;
        padding-bottom: 80px;
    }
    .feed-page-container {
        width: 100%;
        order: 2;
    }
    .feed-page .feed-sidebar {
        width: 100%;
        order: 1;
        margin-bottom: 30px;
    }
    .welcome.welcome-mobile {
        display: block;
        margin-top: 10px;
        margin-left: 14px;
    }
    .welcome {
        display: none;
    }
}
