.location-input{
  position: relative;
}

.location-input .geo-suggest{}

.location-input .geo-suggest-suggests{
  margin: 0;
  list-style: none;
  padding: 0;
  position: absolute;
  min-width: 260px;
  background: #fff;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  z-index: 1;
}

.location-input .geo-suggest-suggests li{
  padding: 10px;
  font-size: 14px;
}

.location-input .geo-suggest-suggests li:hover{
  cursor: pointer;
  background: #efefef;
}

.location-input .geo-clear-button{
  position: absolute;
  right: 2px;
  top: 2px;
  background: #fff;
  width: 35px;
  height: 89%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 15px;
}

.location-input .geo-clear-button.map-icon{
  /* top:9px; */
  width: 35px;
}

.location-input .geo-clear-button.map-icon img{
  width: 21px;
}

.rtl .location-input .geo-clear-button{
  right: initial;
  left: 8px;
}

.location-input .geosuggest__input.geo-suggest{
  font-family: 'AlbertLight';
  font-size: 15px;
  width: 100%;
  padding: 14px;
  box-sizing: border-box;
  border: 1px solid #333;
  height: 52px;
}

.location-input .geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.location-input .geosuggest__input.geo-suggest, .params-popup .location-input .geosuggest__input.geo-suggest{
  border-radius: 0;
  font-family: 'AlbertLight';
  font-size: 15px;
  border: 1px solid #ff8c69;
}

.select .select-box.select-box-visible .select-value{
  border: 1px solid #ff8c69;
  outline: none;
}

.location-input:hover .geosuggest__input.geo-suggest, 
.location-input:hover .geo-clear-button{
  background-color: #ffe8e0;
  cursor: pointer;
}

.location-input .map-icon{
  background: transparent !important;
}