.select-v2{
  width: 100%;
  position: relative;
}

.select-v2.select-v2-has-values .select-v2-value-holder{
  border: 1px solid #000;
  background-color: transparent;
}

.select-v2 .select-v2-value-holder{
  border: 1px solid #FF8C69;
  border-radius: 14px;
  background-color: #FF8C691A;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.select-v2 .select-v2-value{
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
}

.select-v2 .select-v2-icon{
  display: flex;
  align-items: center;
  cursor: pointer;
  transform: rotate(180deg);
}

.select-v2.select-v2-opened .select-v2-icon img{
  transform: rotate(180deg);
}

.select-v2 .select-v2-options-holder.select-v2-static-options{
  position: static;
}

.select-v2 .select-v2-options-holder{
  display: none;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #FF8C69;
  border-radius: 10px;
  margin-top: 5px;
  position: absolute;
  background-color: #fff;
  z-index: 1;
}

.select-v2.select-v2-opened .select-v2-options-holder{
  display: block;
}

.select-v2 .select-v2-options{
  height: calc(100vh - 380px);
  overflow: auto;
  margin-top: 10px;
}

.select-v2 .select-v2-autocomplete{
  padding: 10px;
  border: 1px solid #FF8C69;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
}

.select-v2 .select-v2-autocomplete input{
  width: 100%;
  font-size: 16px;
  border: none;
}

.select-v2 .select-v2-autocomplete input:focus-visible{
  border: none;
  outline: none;
}

.select-v2 .select-v2-autocomplete-icon{}

.select-v2 .select-v2-option.select-v2-option-selected{
  border: 1px solid #000;
}

.select-v2 .select-v2-option{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #FF8C69;
  border-radius: 15px;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 10px;
  cursor: pointer;
}

.select-v2 .select-v2-option-checkbox{
  display: flex;
  align-items: center;
  margin-inline-end: 10px;
}

.select-v2 .select-v2-option-title{}

@media only screen and (max-width: 360px) {
  .select-v2 .select-v2-options{
    height: calc(100vh - 205px);
  }
}

@media only screen and (max-width: 500px) {
  .select-v2 .select-v2-options-holder{}
  .select-v2 .select-v2-option{
    font-size: 16px;
    font-family: 'AlbertLight';
  }
  .select-v2 .select-v2-option-checkbox{}
  .select-v2 .select-v2-option.select-v2-option-selected{}
}