.overlay{
  position: absolute;
  top:0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  flex-flow: column;
  background-color: #fff;
  z-index: 99999999;
}

.overlay > div{
  width: 100%;
}

.overlay .overlay-top{
  display: flex;
  border-bottom: 1px solid #efefef;
  justify-content: flex-end;
}

.overlay .overlay-top .overlay-close{
  cursor: pointer;
  padding: 15px;
  display: flex;
  align-items: center;
}

.overlay .overlay-top .overlay-close img{
  width: 18px;
}

.overlay .logo-pos {
  position: absolute;
  padding: 7px;
  left: 0;
}

.overlay .overlay-main {

  flex-direction: column; /* Stack children vertically */

}

.overlay .ai-chat-upload-box {
  width: 725px;
}

.singup-chat{
  display: flex; /* Enable flexbox for centering */
  justify-content: center; /* Center horizontally within .singup-chat */
  width: 100%; /* Ensure .singup-chat takes full width */
  padding-bottom: 25px;
}

