.radio{
  
}

.radio .radio-option{
  padding: 13px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  align-items: center;
  display: flex;
  font-family: AlbertLight;
}

.radio .radio-option:last-of-type{
  margin-bottom: 0 !important;
}

.radio .radio-option-value{
  width: 14px;
  min-width: 14px;
  height: 14px;
  border-radius: 20px;
  border: 3px solid #000;
  align-items: center;
  display: flex;
  justify-content: center;
}

.radio .radio-option-value-inner{
  width: 10px;
  height: 10px;
  background: #FF8C69;
  border-radius: 30px;
  display: none;
}

.radio .radio-option-title{
  margin-inline-start: 10px;
}

.radio .radio-option.selected{
  border: 1px solid #FF8C69;
  font-family: Albert;
}

.radio .radio-option.selected .radio-option-value{
  border: 3px solid #FF8C69;
}

.radio .radio-option.selected .radio-option-value-inner{
  display: block;
}

.radio .radio-option.selected .radio-option-title{}