.send-email-analyzing {
    background: #0C9BED1A;
    max-width: 690px;
    min-width: 690px;
    margin: 62px auto 0 auto;
}
.send-email-analyzing > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px;
}
.send-email-analyzing .text {
    font-size: 20px;
    font-family: 'AlbertBold';
    line-height: 32px;
    margin-top: 14px;
}

@media only screen and (max-width: 800px) {
    .send-email-analyzing {
        width: 100%;
        max-width: unset;
        min-width: unset;
    }
}