.full-screen-chat{

    display: flex;
    justify-content: center;
}

.full-screen-chat .full-screen-chat-width{
    width: 950px;
    max-height: 900px;
}

.full-screen-chat .img-pos-chatinput-full-mode {
    left: -4px;
}

.full-screen-chat .chat-answer-picker .answer-picker-container {
    width: 100%;
}

.full-screen-chat .chat-send-button {
    position: absolute;
    right: 60px;
    top: 47%;
    transform: translateY(-50%);
    padding: 8px;
}

.full-screen-chat .chat-input-textarea {
    width: 87%;
    min-height: 30px;
    max-height: 100px;
    border: 1px solid #FF8C69;
    margin-bottom: 6px;
    padding: 5px 40px 5px 10px;
    resize: none;
    overflow-y: auto;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.4;
}


