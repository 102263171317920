.chat-button-container {
  background-color: #EB4B62;
  font-size: 13px;
  font-family: AlbertLight;
  color: #fff;
  border-radius: 35px;
  /* width: 180px; */
  padding-inline-end: 15px;
  /* padding-inline-start: 10px; */
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.1s ease-out;
}

.chat-button-icon img{
  position: relative;
  left: -1px;
}

.chat-button-icon {
  margin-top: 5px;
  padding: 5px;
}

.chat-button{
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.chat-button:hover{
  transform: scale(1.05);
}

body.fetching-data .chat-button{
  pointer-events: none;
  opacity: 0.5;
}