.switch-box{
  display: flex;
  font-size: 14px;
  font-family: AlbertLight;
  align-items: center;
  cursor: pointer;
}

.switch-box.switch-box-on{
  
}

.switch-box .switch-box-start{
  margin-inline-end: 5px;
}

.switch-box .switch-box-end{
  margin-inline-start: 5px;
}

.switch-box .switch-box-button-rail{
  width: 40px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 50px;
  position: relative;
}

.switch-box .switch-box-button{
  width: 20px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  display: inline-block;
  position: absolute;
  left: 0px;
  transition: left 0.2s ease-out;
}

.switch-box.switch-box-on .switch-box-button{
  left: calc(100% - 20px);
  background-color: rgb(255, 140, 105);
  box-shadow: 0px 0px 10px rgba(255, 140, 105, 1);
}

.switch-box.switch-box-on .switch-box-button-rail{
  border: 1px solid #ff8c69;
  box-shadow: 0px 0px 10px rgba(255, 140, 105,0.2);
}