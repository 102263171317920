.text-input-question{

}

.text-input-question .text-input-question-input{
  margin-bottom: 10px;
}

.text-input-question input{
  padding: 10px;
  font-size: 16px;
  border: 1px solid #333;
  /* border-radius: 10px; */
}