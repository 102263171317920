.closest-trial {}

.closest-trial .title,
.closest-trial .subtitle {
  font-size: 16px;
  font-weight: 400;
  font-family: AlbertLight;
}

.closest-trial .map {
  height: 300px;
  background-color: #efefef;
  border-radius: 10px;
  margin: 8px 0;
  border-radius: 10px;
  overflow: hidden;
}

.closest-trial .from-you span {
  font-size: 14px;
}

.closest-trial .from-you {
  margin-bottom: 14px;
}

.closest-trial .consult-button {
  color: white;
  background-color: #213332 !important;
  border-color: #213332;
}

.closest-trial .consult-button:hover {
  border-color: #FF8C69 !important;
}

@media only screen and (max-width: 800px) { 
  .closest-trial .consult-button{
    max-width: 200px;
  }
}