.open-in-new-tab{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999;
  width: 100%;
  height: 100%;
  background: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.oint-window{
  padding-left: 20px;
  padding-right: 20px;
}

.oint-text{
  text-align: center;
  font-family: 'Albert';
}

.oint-text .oint-text-title{
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: bold;
}

.oint-text .oint-text-description{
  margin-bottom: 40px;
  font-family: AlbertLight;
}

.oint-button{
  text-align: center;
}

.oint-button{} .oint-text-button{
  background: rgb(58, 130, 247);
  min-width: 200px;
  text-decoration: none;
}

