.animate-wrapper .animate-video {
  width: 100%;
  margin: 0 auto;
}
.animate-wrapper .animate-video iframe {
  border-radius: 20px;
}

.animate-wrapper .ngs-title{

}


.animate-title{
  margin-bottom: 10px;
  font-size: 18px;
}
.animate-loader{
  margin-bottom: 5px;
}
.animate-header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.animate-skip{
  display: none;
}

.animate-skip.visible{
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.animate-skip button{
  width: 200px !important;
}

@media only screen and (max-width: 800px) { /* mobile mode */
  .animate-skip button{
    width: 100% !important;
  }
  .animate-skip.visible{
    padding-left: 10px;
    padding-right: 10px;
  }
}