.intake-page{
  display: flex;
  align-items:center;
  justify-content: center;
  flex-direction: column;
}

.main-intake{
  /* padding-left: 15px !important;
  padding-right: 15px !important; */
  padding-top: 86px !important;
}

.intake-page .intake{
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.intake-page .intake-wrapper{
  max-width: 700px;
  position: relative;
}

.intake-page .back-to-results {
  padding-top: 20px;
  width: 100%;
}

.back-to-results-button{
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 20px;
  padding-inline-end: 15px;
  transition: all 0.2s ease-out;
  background-color: rgba(255,255,255,0);
}

.back-to-results-button-results{
  border: 1px solid #ff8c69;
}

.back-to-results-button:first-of-type{
  margin-inline-end: 10px;
}

.back-to-results-button:hover{
  background-color: rgba(255,255,255,1);
}

.back-to-results-button-icon{
  background-color: #FF8C69;
  border-radius: 30px;
  width: 25px;
  height: 25px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 7px;
}

.back-to-results-button-icon-itself {
  width: 20px;
  color: white;
}

.back-to-results-button-icon img{
  width: 20px;
}

.back-to-results-button-text{
  color: #FF8C69;
}

.new-results.generic-overlay.generic-overlay-visible{
  align-items: baseline;
}

.intake-page .intake-page-title{
  margin-bottom: 10px;
  font-size: 18px;
}

@media only screen and (min-width: 800px) { /* desktop mode */
  .intake-page .intake .button{
    max-width: 200px !important;
  }
}

@media only screen and (max-width: 800px) { /* mobile mode */
  .intake-page .intake-wrapper{
    padding-left: 10px;
    padding-right: 10px;
  }
  .app.app-intake{
    padding-bottom: 0px !important;
    background: #fff !important;
  }
  .intake-page{
    display: block;
  }
  .intake-page .intake{
    padding: 0;
  }
  .back-to-results{
    text-align: center;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
  .back-to-results-button:hover{
    background-color: #f5f5f5;
  }
}
