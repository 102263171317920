.box.actions{
  margin-top: 10px;
}

.actions.list-item{
  text-align: end;
}

/* .actions.list-item span{
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  color: #0C9BED;
} */

.result-list {
  display: flex;
  flex-direction: column;
}
.result-list .results-group-title{
  font-size: 20px;
  margin-top: 10px;
}

.result-list .map{
  width: 100%;
  height: 540px;
  background-color: #eee;
  border-radius: 10px;
}

.result-list .result-list-description{
  margin: 12px 0;
  font-size: 16px;
  padding: 15px;
  border: 1px solid #FF8C69;
  border-radius: 5px;
  font-family: 'AlbertLight';
}

.result-trials-bottom{
  margin-top: 0px;
  margin-bottom: 40px;
  border-top: 1px solid #ccc;
  padding-top: 3px;
}

.result-list .results-items{
  margin-bottom: 20px;
}
.result-list .results-items .item-box-content {
  width: 100%;
}

.result-list .result-list-title{}

.result-list .tabs {
  /* border: 1px solid #D1D1D6; */
}

.result-list .sticky-header{
  z-index: 1;
  background-color: #F2F2F7; /* like .app */
  padding-top: 5px;
  position: sticky;
  top: 0;
}

.result-list .no-data .no-data-text-1{
  margin-bottom: 20px;
}
.result-list .no-data .no-data-text-2{
  margin-bottom: 20px;
  font-weight: bold;
}

.result-list .no-data{
  display: flex;
  gap: 20px;
}

.result-list .no-data > div{
  width: 50%;
}

.result-list .results-group-title-trials{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result-list .show-all-trials{
  font-size: 14px;
  cursor: pointer;
  color: #ff8c69;
}

@media only screen and (max-width: 800px) {
  .result-list .results-group-title{
    padding-left: 10px;
    padding-right: 10px;
  }
  .result-list .result-list-description{
    font-size: 14px;
  } 
  .result-list .no-data{
    display: block;
  }
  .result-list .no-data > div{
    width: initial;
  }
}

@media (hover: hover) {
  .button-group .button-item:hover:not(.loading){
    background: #fff;
    border: 1px solid #ccc;
  }
}

.result-list .results-title{
  font-family: 'AlbertLight';
  margin-bottom: 15px;
  margin-top: 5px;
}

.result-list .results-title span,
.results .result-list-header.sticky-header span {
  margin-left: 5px;
  background: #fff;
  display: inline-block;
  border-radius: 20px;
  width: 20px;
  text-align: center;
  border: 1px solid #ccc;
  cursor: pointer;
}

.results .result-list-header.sticky-header span {
  font-family: 'AlbertLight';
  font-size: 17px;
}

.general-condition .missing-questions{
  display: none !important;
}