.questions-popup{}

.questions-popup .question{
  display: flex;
  cursor: pointer;
  font-family: 'AlbertLight';
  border-bottom: 1px solid #ececec;
}

.questions-popup .question:hover{
  background-color: #f8f8f8;
}

.questions-popup .question-circle{
  margin: 10px;
  padding: 10px;
  background: #EB4B62;
  color: #fff;
  width: 5px;
  height: 5px;
  border-radius: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.questions-popup .question-title{
  display: flex;
  align-items: center;
}