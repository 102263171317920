.root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.rightButtons {
    display: flex;
}

.button {
    margin-inline-start: 10px;
    font-family: AlbertLight !important;
}

.buttonLabel {
    font-family: AlbertLight !important;
    font-weight: normal;
    white-space: nowrap;
}

.buttonIcon {
}

.checkQualificationButton {
    border-radius: 16px;
}

.backButton:hover {
    background-color: #f8f8f8 !important;
}

.backButtonLabel {
    color: #000;
}

@media only screen and (max-width: 800px) {
    .backButtonLabel {
        display: none;
    }

    .backButton {
        padding: 0px 10px;
        height: 30px;
        border-radius: 18px;
    }

    .backButton > img {
        margin-right: 0px;
        scale: 0.8;
    }

    .buttonIcon {
        display: none;
    }
}
