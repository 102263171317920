.root {
    display: flex;
    cursor: pointer;
    width: calc(50% - 16px);
    box-sizing: border-box;
    margin-bottom: 24px;
    padding: 16px 12px;
    border-radius: 16px;
    background: #FFF;
    flex-direction: column;
}
.root:hover {
    box-shadow: 0px 0px 8px #a5a5a5;
}
.root:hover .title {
    text-decoration: underline;
}
.imageContainer {
    height: 124px;
    width: 100%;
    position: relative;
}
.imageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    background-color: #D9D9D9;
    background-image: url('../../../../assets/icons/fallback_image.svg');
    background-repeat: no-repeat;
    background-position: center center;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.tag {
    border-radius: 4px;
    position: absolute;
    bottom: -10px;
    left: 15px;
    color: rgba(33, 51, 50, 0.80);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    border: 1px solid #CBE896;
    background-color: #eaf0e4;
    height: 22px;
    box-sizing: border-box;
}
.selectedTag {
    background-color: #d3eaa9;
}

.title {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 20px 0px 8px 0px;
}

.description {
    font-family: 'AlbertLight';
    color: rgba(33, 51, 50, 1);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    position: relative;
    margin-top: 12px;
}

.duration {
    margin-bottom: 8px;
}

.duration, 
.urgency {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
}

.duration > .key, 
.urgency > .key {
    color: rgba(33, 51, 50, 0.60);
    display: flex;
    align-items: center;
    gap: 4px;
}
.duration > .value,
.urgency > .value {
    color: rgba(33, 51, 50, 1);
    margin-left: 4px;
}

@media only screen and (max-width: 800px) {
    .root {
        width: 100%;
        margin-bottom: 18px;
    }
}
