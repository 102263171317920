.biomarkers-question{}

.biomarkers-question-title{
  margin-bottom: 15px;
}

.biomarkers-question-footer{
  margin-top: 10px;
}

.biomarkers-question .files-uploader-success .ngs-title{
  display: none;
}

.biomarkers-question .ngs-analyze svg{
  height: 125px !important;
}

.biomarkers-question .ngs-analyze svg rect{
  fill: transparent !important;
}

.biomarkers-question-selecteds{}

.biomarkers-question-selecteds-title{
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: AlbertLight;
}

.biomarkers-question-selecteds-body{
  display: flex;
  flex-wrap: wrap;
}

.biomarkers-question-selected{
  display: flex;
  background: #fff;
  padding: 10px;
  align-items: center;
  border-radius: 10px;
  margin-inline-end: 5px;
  margin-bottom: 5px;
}

.biomarkers-question-selected img{
  cursor: pointer;
}

.biomarkers-question-selected-title{
  margin-inline-end: 10px;
}

.biomarkers-question-selected-menu{}

@media only screen and (max-width: 800px) { /* mobile mode */
  .biomarkers-question .mobile-files-uploader{
    display: none !important;
  }
}