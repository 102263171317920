.partners {
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 48px;
}
.partners .partners-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.partners .partners-image img {
    margin-right: 16px;
    margin-bottom: 14px;
}
.partners .title {
    margin-bottom: 14px;
    font-size: 20px;
    text-align: center;
    align-self: stretch;
    color: var(--match-black, #213332);
    text-align: center;
    font-family: "Albert";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 10px;
}
.publications-articles {
    max-width: 1250px;
    margin: 0 auto;
}
.publications-articles .title {
    margin-top: 24px;
    margin-bottom: 14px;
    font-size: 36px;
    text-align: center;
    font-family: 'AlbertLight';
}
.publications-articles .publications-articles-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.publications-articles .publications-articles-image img {
    margin-right: 16px;
    margin-bottom: 14px;
}


@media only screen and (max-width: 800px) {
    .partners {
        margin-top: 38px;
    }
}