.provider-footer{
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 7px;
  box-sizing: border-box;
  width: 100%;
}
.new-ui .provider-footer{
  background: #F2F2F7;
}
.provider-footer > div{
  display: flex;
}

.provider-footer .link{
  text-decoration: underline;
  cursor: pointer;
}

.provider-footer .provider-middle{
  align-self: center;
}

.provider-footer img{
  margin-inline-end: 10px;
}

.provider-mode .app > div > .provider-footer{
  position: fixed;
  bottom: 0;
}