.button{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size:16px;
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  font-weight: bold;
  color: #fff;
  border: 1px solid #FF8C69;
  height: 44px;
  /* max-width: 350px; */
}

.button.icon-button{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
}

.button.icon-button img{
  margin-inline-end: 10px;
}
.button svg{
  margin-right: 10px;
}

.button .forward-icon{
  margin-inline-start: 10px;
}

.button:hover{
  background-color: rgb(255, 140, 105, 0.1) !important;
  border: 1px solid rgb(255, 140, 105, 0.1) !important;
  color: #FF8C69 !important;
}

.button.white{
  background: #fff !important;
  color: #333 !important;
  border: 1px solid #333;
}

.button.white:hover{
  background: #efefef;
  color: #81cffa !important;
  border: 1px solid #81cffa;
}

.button:disabled {
  pointer-events: none;
  cursor: default;
  background: #efefef;
  background-color: rgb(255 208 194) !important;
  border: rgb(255 208 194) !important;
}

.button.orange-theme{
  color: #FF8C69 !important;
  background-color: #fff !important;
  border: 1px solid #FF8C69 !important;
}

.button.orange-theme:hover{
  background-color: rgba(255, 140, 105, 0.1) !important;
}

.button.light-theme{
  background-color: #f2f2f7 !important;
  border: 1px solid #ccc !important;
  font-family: 'AlbertXLight';
  color: #535353 !important;
  font-size: 14px;
  padding-inline-end: 14px;
}

.button.icon-arrow img{
  transform: rotate(90deg);
  filter: brightness(10);
}

.button.icon-arrow:hover img{
  filter: brightness(1);
}

.button.button.light-theme:hover{
  background-color:rgb(255 140 105 / 13%) !important;
  border: 1px solid #ff8c69f2 !important;
}

.button.small-grey-theme{
  width: 100px !important;
  background-color: #f2f2f2 !important;
  border: none !important;
  font-family: AlbertXLight;
  font-size: 15px;
  border-radius: 20px;
  color: #989898 !important;
  padding-inline-start: 0px;
  padding-inline-end: 5px;
}

.button.small-grey-theme:hover{
  background-color: #eaeaea !important;
}

.button.small-grey-theme img{
  width: 21px;
}

.button.purple-theme{}

.button.black-theme{
  background-color: #000 !important;
  border: none !important;
  font-family: AlbertLight;
  font-weight: normal;
}

.button.black-theme:hover{
  background-color: rgb(255, 140, 105) !important;
  color: #fff !important;
}

.button.black-theme svg path{
  stroke: #fff;
}

.button.black-theme:hover svg path{
  /* stroke: #000; */
}

.button.grey-theme{
  background-color: #efefef !important;
  border: none !important;
  font-family: AlbertLight;
  font-weight: normal;
  color: rgb(255, 140, 105) !important;
}

.button.grey-theme:hover{
  background-color: #ffe8e1 !important;
  color: rgb(12, 12, 12) !important;
}
  
.button.purple-theme{
  background: rgb(199, 125, 255);
  border: none;
}

.button.purple-theme:hover{
  background: rgb(199, 125, 255);
}

@media only screen and (max-width: 800px) { /* mobile */
  .button{
    max-width: initial;
  }
}