.about-you-popup{}

.about-you-popup .about-you-popup-image{
  background-color: #cce896;
  text-align: center;
}

.about-you-popup .about-you-popup-title{
  text-align: center;
  font-size: 20px;
  margin-bottom: 15px;
}

.about-you-popup .about-you-popup-text{
  margin-bottom: 15px;
  font-family: AlbertLight;
}

.about-you-popup .about-you-popup-button{}

@media only screen and (max-width: 800px){
  .popup-visible .popup.about_you{
    bottom: -240px;
  }
}