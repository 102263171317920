.providerOverlay{
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 999999;
  top: 0;
  left: 0;
  background: #ffffffed;
  align-items: center;
  justify-content: center;
  font-family: 'Albert';
  text-align: center;
}

.providerOverlay .close-button{
  position: absolute;
  top:0;
  right: 0;
  padding: 20px;
  cursor: pointer;
}

.providerOverlay .message{
  text-align: center;
  padding: 30px;
}

.providerOverlay .title{
  font-size: 30px;
  margin-bottom: 20px;
}

.providerOverlay .subtitle{
  font-size: 20px;
}

.providerOverlay .button{
  margin-top: 20px;
  background: rgb(58, 130, 247);
}

.providerOverlay .provider-button:hover{

}