.chat-loader {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: block;
    margin:15px auto;
    position: relative;
    background: #FFF;
    box-shadow: -24px 0 #FFF, 24px 0 #FFF;
    box-sizing: border-box;
    animation: shadowPulse 2s linear infinite;
  }
  
  @keyframes shadowPulse {
    33% {
      background: #FFF;
      box-shadow: -24px 0 #FF3D00, 24px 0 #FFF;
    }
    66% {
      background: #FF3D00;
      box-shadow: -24px 0 #FFF, 24px 0 #FFF;
    }
    100% {
      background: #FFF;
      box-shadow: -24px 0 #FFF, 24px 0 #FF3D00;
    }
  }