.results-skeleton{
  width: 100%;
  box-sizing: border-box;
}
.skeleton-header{
  margin-top: 10px;
  /* margin: 0 10px; */
  border: 1px solid #D1D1D6;
  border-radius: 10px;
  padding: 16px;
}
.results-skeleton .skeleton-box{
  border-radius: 10px;
}
.results-skeleton .skeleton-box1{
  height: 25px;
}
.results-skeleton .skeleton-box2{
  margin-top: 20px;
  width: 300px;
  height: 20px;
}
.results-skeleton-result{
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
}
.results-skeleton .skeleton-line{
  align-items: center;
}

@keyframes gradientAnimation {
  0% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.results-skeleton .skeleton-effect {
  background: linear-gradient(90deg, rgb(214, 214, 214) 30%, rgb(240, 240, 240) 32%, rgba(238,238,238,1) 48%, rgb(211, 211, 211) 50%);
  background-size: 400% 400%; 
  animation: gradientAnimation 2.5s ease-out infinite;
}
.results-skeleton .skeleton-effect-light {
  background: linear-gradient(90deg, rgb(224, 224, 224) 30%, rgba(212,212,212,1) 32%, rgba(238,238,238,1) 58%, rgb(223, 223, 223) 61%);
  background-size: 400% 400%; 
  animation: gradientAnimation 2.5s ease-out infinite;
}

@media only screen and (max-width: 800px) { 
  .results-skeleton{
    padding-left: 10px;
    padding-right: 10px;
  }  
}