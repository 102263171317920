.trial{}

.trial .back-button-holder{
  margin-bottom: 30px;
}

.trial .grid{
  gap: 28px;
  grid-template-columns: calc(70% - 14px) calc(30% - 14px);
  display: grid;
}

@media only screen and (min-width: 800px) {
  .main-search .upload-nav{
    padding: 0px 0px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 800px) {
  .trial{
    padding: 10px;
  }
  .trial .grid {
    display: flex;
    flex-direction: column;
  }
  .trial .action{
    margin-bottom: 0px !important;
    margin-top: 20px;
  }
}

.new-ui h2 {
  padding: 0px;
  margin: 0px;
  font-size: 28px;
  margin-bottom: 8px;
  font-weight: bold;
  text-align: left;
}
