.edit-question-popup{
    padding: 10px !important;
}

.single-question-intake{

}

.edit-question-popup .intake-question-collapser{
    display: none !important;
}

.edit-question-popup .treatment-question-next{
    margin-inline-start: 0px;
    display: none !important;
}

.edit-question-popup .intake-question {
    background: none;
    border: none;
    padding: 5px;
    padding-top: 0px;
}

.edit-question-popup .accordion .accordion-item-body{
    overflow: auto;
}

.edit-question-popup .intake-done-button{
    display: none !important;
}