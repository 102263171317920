.main-finance_intake{
  display: flex;
  justify-content: center;
}
.finance-intake-wrapper{
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
} 
.finance-intake{
  max-width: 700px;
  margin-top: 20px;
  position: relative;
}
.finance-intake .finance-intake-title{
  margin-bottom: 10px;
}

.finance-intake .back-to-results{
  margin-top: 10px;
  padding-inline-start: 10px;
}

.finance-intake .back-to-results-button{
  border: 1px solid #ff8c69;
}

.finance-loading{
  padding: 20px;
  margin-top: 20px;
}

.intake-finance-wait{
  margin-top: 20px;
}

.finance-intake .intake-location-distance{
  display: none;
}

.finance-intake .intake-location-holder{
  margin-bottom: 15px;
}