.multiple-option-select{}

.multiple-option-select .select-option{
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 5px;
}

.multiple-option-select .select-option:last-of-type{
  margin-bottom: 0;
}

.multiple-option-select .select-option.option-selected{
  background-color: #fff;
}

.multiple-option-select .select-option.option-selected .option-label{
  font-weight: bold;
}

.multiple-option-select .select-option .option-selector{
  width: 22px;
  height: 22px;
  background-color: #fff;
  margin-inline-end: 10px;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  border: none;
}

.multiple-option-select .select-option.option-selected .option-selector{
  border: none;
}

.multiple-option-select .select-option .option-label{
  font-family: AlbertLight;
  font-size: 18px;
}

.multiple-option-select .button{
  margin-top: 15px;
}