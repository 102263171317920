.backButton {
    border: none;
    border-radius: 20px;
}
.button {
    padding: 10px 24px;
}
.updateProfileButton {
    min-width: 242px;
}

@media only screen and (max-width: 800px) {
    .updateProfileButton {
        min-width: 158px;
    }
}
@media only screen and (max-width: 400px) {
    .button {
        padding: 0;
    }
    .updateProfileButton {
        min-width: 126px;
    }
}
