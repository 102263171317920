.root {
    padding: 20px;
    font-family: Albert;
    text-align: center;
    margin-bottom: 20px;
}

.status_gone {
    background-color: #efafbb;
}

.status_not_eligible {
    background-color: rgba(235, 75, 98, 0.40);
    display: none !important;
}
