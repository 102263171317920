.chat-mode #launcher{
    display: none !important;
}

.call-us {
    padding: 12px;
    padding-top: 0px;
}

.call-us button {
    margin-bottom: 10px;
}

.call-us h2 {
    text-align: left;
    font-size: 20px;
    font-family: AlbertBold;
    line-height: 32px;
    margin-top: 0px;
}

.call-us>p {
    font-size: 16px;
    font-family: AlbertLight;
    line-height: 24px;
}

.call-us .call-us-btn {
    background-color: #213332 !important;
}
.call-us .phone-number {
    font-weight: 600;
}

.call-us svg {
    transition: fill 0.3s ease; /* Плавное изменение цвета */
}

.call-us .call-us-btn:hover svg, .call-us .shadule-a-call-btn:hover svg {
    fill: #FF8C69 !important;
}

.call-us .chat-with-us-btn {
    background-color: #fff !important;
    color: #FF8C69 !important;
}
.call-us .chat-with-us-btn:hover {
    background-color: #FF8C69 !important;
    color: #fff !important;
}
.call-us .chat-with-us-btn:hover svg {
    fill: #fff !important;
}

@media only screen and (min-width: 800px) {
    .popup2.call_us{
        top: -100px;
        right: initial;
        left: 80px;
        height: auto;
        min-height: 200px;
        opacity: 0;
        border-radius: 4px;
        box-shadow: 2px 2px 8px 8px rgba(0, 0, 0, 0.18);
    }

    .popup2.call_us.animate-in{
        top: 80px;
        left: 80px;
        opacity: 1;
    }
    .call-us-buttons > *{
        display: inline-flex;
        width: 32% !important;
        margin-inline-end: 10px;
    }
    .call-us{
        padding-bottom: 12px !important;
    }
}

@media only screen and (max-width: 800px) {
    .popup2.call_us.animate-in{
        top: 0;
        opacity: 1;
    }

    .popup2.call_us{
        top: -100px;
        right: initial;
        height: 280px;
        min-height: 200px;
        opacity: 0;
      }

    .popup-visible .popup.call_us {
        right: initial;
        top: 0%;
    }
    .popup.call_us .popup-content>div{
        padding-bottom: 20px!important;
    }
    .popups2 .call_us.visible.animate-in {
        top: 0% !important;
        height: 280px;
    }
    
}
