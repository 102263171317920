.upload-page-start-section-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 38px;
}

.ai-form-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 680px;
}

.center-headtitle{
    /* display: flex; */
    justify-content: center;
    align-items: center;
    font-size: 22px !important;
    padding-bottom: 10px;
}

.email-signup-center-headtitle-div {
}

@media (max-width: 800px) {
    .form-margin-top{
        margin-top: 0px !important;
    }
    .email-signup-center-headtitle-div {
        height: 60px;
    }
}
.signup-page-link-color {
    color: rgb(255, 140, 105);
}

.form-margin-top {
    margin-top: 5vh;
    color: var(--match-black, #213332);
    text-align: center;
    /* Extra */
    font-family: "Albert";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.small-height {
    height: 14px;
}

.have-an-account{
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.have-an-account span{
  font-family: AlbertLight;
  font-size: 14px;
  cursor: pointer;
}

.have-an-account span b{
  font-weight: normal;
  color: rgb(255, 140, 105);
}

.have-an-account span b:hover{
  text-decoration: underline;
  cursor: pointer;
}

.signup-page-secoundry-title{
    color: #000;

text-align: center;
font-family: "AlbertLight";
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 24px; /* 150% */
}

.upload-page .large-width-gap {
    width: 40px
  }
  
  .upload-page .ai-chat-upload-box {
    margin-top: 25px;
    border-radius: 4px;
    border: 1px solid var(--web-dark-40, rgba(33, 51, 50, 0.40));
  }
  
  .upload-page .upload-page .ai-chat-upload {
    overflow-y: hidden;
    width: 725px;
  }

  .upload-page .ai-chat-upload .chat-icon{
    margin-top: 10px;
    height: 20px;
    cursor: pointer;
  }

  @media (max-width: 768px) { /* Adjust the breakpoint as needed */
    .upload-page .ai-chat-upload {
      width: calc(100vw - 30px); /* Make it take almost full width */
    }
  }

  .upload-page .chat-popup .full-screen-height{
    height: unset
  }

  .upload-page .chat-popup .img-pos-chatinput-full-mode {
bottom: 7px;
  }