.actionbox {
  background: #fff;
  border-radius: 10px;
  padding-top: 10px;
  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
}

.actionbox .top {
  display: flex;
  padding: 20px;
  border-bottom: 2px solid #fff;

}

.actionbox .close {
  text-align: end;
}

.actionbox .clear {
  clear: both;
}

.actionbox .icon-wrapper {
  display: inline-block;
}

.actionbox .icon {
  text-align: center;
  width: 45px;
}

.actionbox .icon img {
  width: 45px;
}

.actionbox .titles {
  padding-inline-start: 15px;
}

.actionbox .title {
  font-size: 20px;
  margin-bottom: 3px;
}

.actionbox .subtitle {
  font-size: 14px;
}

.actionbox .action {
  padding: 10px;
  text-align: end;
}

.actionbox .action-button{
  display: inline-block;
  border:2px solid #fff;
  padding: 7px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  text-decoration:none;
}

.actionbox .action-button img{
  display: inherit;
  margin-inline-start: 5px;
  position: relative;
  top:2px;
}

@media only screen and (max-width: 800px) {

}