.item-box .missing-questions{
  display: flex;
  background: rgba(244, 208, 111, 1);
  padding: 4px;
  border-radius: 14px;
  cursor: pointer;
}
.main-search .item-box .missing-questions-text-subtitle{
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
}
.item-box .missing-questions.missing-questions-pending{
  background: rgba(244, 208, 111, 1);
}
.item-box .missing-questions.missing-questions-ready{
  background: rgba(203, 232, 150, 1);
}
.item-box .missing-questions-text{
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
}
.item-box .missing-questions-text-title{}
.item-box .missing-questions-text-subtitle{
  font-family: 'AlbertLight';
}
.item-box .missing-questions-arrow{
  display: flex;
  width: 30px;
  margin-inline-start: 30px;
}
.item-box .missing-questions-arrow img{
  width: 10px;
}
.rtl .item-box .missing-questions-arrow img{
  transform: rotate(180deg);
}