.popups2{}

.popups2-overlay{
  position: fixed;
  z-index: 9999;
  top:0;
  left: 0;
  background-color: rgba(0,0,0,0.36);
  width: 100%;
  height: 100%;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.popups2-overlay.visible{
  display: block;
}

.popups2-overlay.animate-in{
  opacity: 1;
}

.popup2{
  box-sizing: border-box;
  position: fixed;
  background: #fff;
  transition: all 0.3s ease-out;
  z-index: 10000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
}

.popup2.visible{
  display: block;
}

@media only screen and (min-width: 800px) {
  .popup2{
    top: 0;
    width: 400px;
    height: 100%;
    right: -400px;
  }
  .popup2.animate-in{
    right: 0px;
  }
}

@media only screen and (max-width: 800px) { /* mobile */

  .popup2{
    bottom: -100%;
    width: 100%;
    height: auto;
    left: 0;
  }

  .popup2.animate-in{
    bottom:0%;
  }

}

.popup2-header{
  padding: 5px;
  display: flex;
  justify-content: flex-end !important;
}

.popup2-header .close{
  cursor: pointer;
}

.popup2-content{
}

.popup2 .popup2-content .popup-padding{
  padding: 16px 24px;
}

.popup2 .popup2-content .popup-title{
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #81CFFA;
}

.popup2 .popup-footer{
  width: 100%;
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  border-top: 1px solid #f0f0f0;
  background: #fff;
  left: 0;
}

@media only screen and (min-width: 800px) {
  .popup2.hanging-popup-theme{
      top: -100px;
      right: initial;
      left: 80px;
      height: auto;
      min-height: 200px;
      opacity: 0;
      border-radius: 4px;
      box-shadow: 2px 2px 8px 8px rgba(0, 0, 0, 0.18);
  }

  .popup2.hanging-popup-theme.animate-in{
      top: 80px;
      left: 80px;
      opacity: 1;
  }
}

@media only screen and (max-width: 800px) {
  .popup2.hanging-popup-theme.animate-in{
      top: 0;
      opacity: 1;
  }

  .popup2.hanging-popup-theme{
      top: -100px;
      right: initial;
      height: 280px;
      min-height: 200px;
      opacity: 0;
    }

  .popup-visible .popup.hanging-popup-theme {
      right: initial;
      top: 0%;
  }
  .popup.hanging-popup-theme .popup-content>div{
      padding-bottom: 20px!important;
  }
  .popups2 .hanging-popup-theme.visible.animate-in {
      top: 0% !important;
      height: 280px;
  }
  
}