.root {
    max-width: 1200px;
    margin: auto;
}
.filterWrapper {
    display: flex;
    gap: 12px;
    margin-bottom: 30px;
    overflow: auto;
    padding-bottom: 8px;
}
.contentWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
}

.flipMove {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer {
    padding: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
    min-height: 35px;
}
.moreButton {
    display: flex;
    width: 248px;
    height: 36px;
    justify-content: center;
    align-items: center;
    color: #213332;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    border-radius: 14px;
    border: 1px solid rgba(33, 51, 50, 0.80);
    cursor: pointer;
}
.moreButton:hover {
    background-color: #F3E8E9;
}

@media only screen and (max-width: 800px) {
    .root {
        padding: 18px 14px;
    }
    .flipMove {
        flex-direction: column;
    }
    .filterWrapper {
        margin-bottom: 16px;
    }
}
