.chat-actions-panel-button{
    border: none;
    /* min-width: 100%; */
    height: 80px;
    background-color: white;
    font-size: 11px;
    font-weight: 600;
    cursor: pointer;
    width: auto;
    flex: 1;

  }
  .chat-actions-panel-button:hover{
    background-color: #F7F7F8;
    border: #FF8C69;
    border-width: 1px;
  }

  .chat-active-action-panel-button{
    background-color: #F7F7F8; 
  }

  .chat-actions-panel-icon {
    height: 45px;
  }


.chat-actions-panel-title {
    height: 20px;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: -10px;
    color: #FF8C69;
    text-align: center;
    background-color: white;
    width: 400px;
    margin-left: -20px
  }

  .chat-actions-panel-buttons {
    display: flex;
    margin-top: -10px;
    width: 100%;
  }