.drugs-received{}
.drugs-received .drugs-received-treatments{
  margin-bottom: 20px;
}
.drugs-received .drugs-received-treatment{}
.drugs-received .drugs-received-add-treatment{}
.drugs-received .drugs-received-add-treatment-title{
  font-family: 'AlbertLight';
}
.drugs-received .drugs-received-treatments-title{
  font-size: 18px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.drugs-received .drugs-received-treatments-delete{
  cursor: pointer;
}
.drugs-received .drugs-received-actions{}

.drugs-received .drugs-received-actions button:first-of-type{
  margin-inline-end: 10px;
}

.drugs-received .drugs-received-treatment{
  padding-bottom: 20px;
  border-bottom: 1px solid #cbe896;
  margin-top: 20px;
}

.drugs-received .drugs-received-not-sure{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.drugs-received .drugs-received-not-sure-box{
  width: 17px;
  height: 17px;
  border: 2px solid #333;
  border-radius: 5px;
  margin-inline-end: 7px;
}
.drugs-received .drugs-received-not-sure-text{
  font-family: AlbertLight;
}

.drugs-received .drugs-received-add-treatment-body{
  margin-top: 5px;
}

.drugs-received .drugs-received-actions button{
  max-width: 200px;
}

@media only screen and (max-width: 500px) { /* mobile mode */
  .drugs-received .drugs-received-actions button{
    max-width: 100%;
    margin-bottom: 10px;
  }
}