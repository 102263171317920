.add-caregiver {
  padding: 20px !important;
}

.add-caregiver-title{
  text-align: center;
  font-size: 36px;
  margin-bottom: 10px;
}

.add-caregiver-subtitle{
  margin-bottom: 20px;
  text-align: center;
}

.add-caregiver-footer{
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.add-caregiver-agreement{
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 10px;
}

.add-caregiver-buttons{
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.add-caregiver-form .radio-option{
  padding: 0;
  margin: 0;
  border:none;
}

.add-caregiver-form .radio-option.selected{
  border: none;
}

.add-caregiver-button{
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 300px;
}

.add-caregiver-form .phone-input-dropdown.flag-dropdown{
  border: 1px solid #ccc !important;
}

.add-caregiver-form .radio .radio-option-value {
  border-width: 1px !important;
}
.add-caregiver .radio .radio-option {
  margin-bottom: 5px;
}
.add-caregiver-form button {
  max-width: 300px;
  justify-self: center;
  margin: 20px auto;
}
.add-caregiver-form button > img {
  order: 1;
  margin-left: 10px;
}
.add-caregiver-error-handling {
  border: 1px solid red;
  padding: 10px;
  border-radius: 10px;
}

@media only screen and (max-width: 800px) {
  .add-caregiver {
    padding-bottom: 60px !important;
  }
}