.alerts{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000000000000;
}

.alerts .alert{
  box-sizing: border-box;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 15%);
  display: flex;
  border: 2px solid #ccc;
  border-radius: 7px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  background: #fff;
  margin: 40px;
}

.alerts .alert .icon{
  position: relative;
  top:2px;
}

.alerts .alert .text{
  font-family: 'AlbertLight';
  font-size: 19px;
  width: 100%;
  text-align: left;
  padding-inline-start: 20px;
}

.alerts .alert .close{
  cursor: pointer;
  position: relative;
  top:2px;
}

.alerts .edit-button{
  color: #0c9bed;
  cursor: pointer;
}

.alerts .edit-button:hover{
  text-decoration: underline;
}

@media only screen and (max-width: 800px){
  .alerts .alert{
    margin: 10px;
  }
}