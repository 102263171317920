.form-input-holder{
  display: flex;
  position: relative;
}

.form-input-holder input{
  font-family: 'AlbertLight';
  font-size: 18px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border:1px solid #aaa;
  border-radius: 3px;
}

.form-input-holder .icon{
  position: absolute;
  align-self: center;
  right: 10px;
  width: 20px;
  cursor: pointer;
}

.rtl .form-input-holder .icon{
  left: 10px;
  right: initial;
}

.form-input-holder.theme-orange{}

.form-input-holder.theme-orange input{
  border: 1px solid #FF8C69;
  border-radius: 16px;
  padding-inline-start: 15px;
  font-family: 'AlbertLight';
  font-size: 16px;
}

.form-input-holder.theme-orange input:focus-visible{
  border: 1px solid #FF8C69;
  outline: none;
}