.multi-tags-selector{
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  border-radius: 5px;
  flex-flow: wrap;
  padding-bottom: 0px;
  overflow: hidden;
}
.multi-tags-selector .multi-tags-tag{
  display: flex;
  background-color: #f1f1f1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 50px;
  margin-inline-end: 5px;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s ease-out;
  white-space: nowrap;
  margin-bottom: 10px;
  min-width: 0;
  overflow: hidden;
}
.multi-tags-selector .multi-tags-tag:hover{
  border: 1px solid #aaa !important;
  box-shadow: 0px 3px 10px rgba(0,0,0,0.2);
}
.multi-tags-selector .multi-tags-tag.multi-tags-tag-selected{
  background-color: #cbe896;
  border: 1px solid #cbe896;
  font-weight: normal;
}

.multi-tags-selector .multi-tags-tag-title{
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multi-tags-selector .multi-tags-tag-icon{
  display: none;
}

.multi-tags-selector .multi-tags-tag-icon img{
  width: 17px;
}

.multi-tags-selector .multi-tags-tag.multi-tags-tag-selected .multi-tags-tag-icon{
  display: flex;
  margin-inline-end: 5px;
}

.multi-tags-selector .multi-tags-tag-icon-empty{
  width: 17px;
  height: 17px;
  border: 1px solid #787878;
  margin-inline-end: 7px;
  border-radius: 2px;
  min-width: 17px;
}

.multi-tags-selector .multi-tags-tag.multi-tags-tag-selected .multi-tags-tag-title{
  font-family: AlbertLight;
  margin-inline-end: 5px;
}

.multi-tags-selector .multi-tags-tag-static{
  pointer-events: none;
}

.multi-tags-selector .multi-tags-add-tag{
  display: flex;
  background-color: #f1f1f1;
  border: 1px solid #f1f1f1;
}

.multi-tags-selector .multi-tags-add-tag img{
  margin-inline-end: 5px;
}

.multi-tags-selector .multi-tags-add-tag-title{}