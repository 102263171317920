.results-skeleton{
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.skeleton-header{
  margin-bottom: 30px;
  margin-top: 20px;
}

.results-skeleton .skeleton-box{
  border-radius: 10px;
  background-color: #ccc;
}

.results-skeleton .skeleton-box1{
  height: 25px;
}

.results-skeleton .skeleton-box2{
  margin-top: 10px;
  width: 300px;
  height: 20px;
}

.results-skeleton .skeleton-box3{
  margin-top: 10px;
  width: 50px;
  height: 20px;
  margin-inline-end: 5px;
}

.results-skeleton .skeleton-box4{
  margin-top: 10px;
  width: 50px;
  height: 20px;
}

.results-skeleton .skeleton-box5{
  margin-top: 10px;
  width: 80%;
  height: 20px;
}

.results-skeleton .skeleton-box6{
  margin-top: 10px;
  width: 50%;
  height: 20px;
}

.results-skeleton .skeleton-box7{
  margin-top: 10px;
  width: 40%;
  height: 20px;
}

.results-skeleton-result{
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
}

/* Define the keyframes for the animation */
@keyframes gradientAnimation {
  0% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
  /* 100% { background-position: 0% 50%; } */
}

/* Apply the gradient and animation to the element */
.results-skeleton .skeleton-effect {
  background: linear-gradient(90deg, rgb(214, 214, 214) 30%, rgb(240, 240, 240) 32%, rgba(238,238,238,1) 48%, rgb(211, 211, 211) 50%);
  background-size: 400% 400%; /* Adjust as needed */
  animation: gradientAnimation 2.5s ease-out infinite;
}

/* Apply the gradient and animation to the element */
.results-skeleton .skeleton-effect-light {
  background: linear-gradient(90deg, rgb(224, 224, 224) 30%, rgba(212,212,212,1) 32%, rgba(238,238,238,1) 58%, rgb(223, 223, 223) 61%);
  background-size: 400% 400%; /* Adjust as needed */
  animation: gradientAnimation 2.5s ease-out infinite;
}

@media only screen and (max-width: 800px) { /* mobile mode */
  .results-skeleton{
    padding-left: 10px;
    padding-right: 10px;
  }  
}