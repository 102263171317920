.treatment-item-title{
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
}

.treatment-item-title > div{
  overflow: hidden;
}

@media only screen and (max-width: 800px) {
  .details {
    margin-top: 4px;
  }
}

.treatment-item-freindly-title-margin{
  margin-top: 20px;
}

.treatment-item-freindly-title-icon {
  padding-right: 10px;
}

.treatment-item-freindly-container {
  display: flex;
  font-family: 'AlbertLight';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  
}