.about-you-part{
  margin-top: 25px;
}

.about-you-title{
  font-size: 18px;
  margin-bottom: 15px;
}

.about-you-part .about-you-icons{
  /* padding-top: 10px; */
  padding-bottom: 25px;
  justify-content: flex-start;
  max-width: 400px;
  width: 100%;
  gap: 15px;
}

.about-you-icons > div{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* width: 25%; */
}

.about-you-part .about-you-icons svg{}

.about-you-part .about-you-icon{
  padding: 20px;
  background: #fff;
  /* margin-inline-end: 10px; */
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid rgb(255, 140, 105);
  transition: all 0.1s ease-out;
  position: relative;
}

.about-you-part .user-type .about-you-icon.selected{
  /* box-shadow: 0px 5px 10px rgba(0,0,0,20%); */
}

.about-you-part .user-type > div:nth-child(1) .about-you-icon.selected{
  background-color: #ff8c69;
  border-color: #ff8c69;
}
.about-you-part .user-type > div:nth-child(2) .about-you-icon.selected{
  background-color: #fc92a5;
  border-color: #fc92a5;
}
.about-you-part .user-type > div:nth-child(3) .about-you-icon.selected{
  background-color: #81cffa;
  border-color: #81cffa;
}
.about-you-part .user-type > div:nth-child(4) .about-you-icon.selected{
  background-color: #f4d06f;
  border-color: #f4d06f;
}

.about-you-part .user-type > div:nth-child(1) .about-you-icon:hover{
  border-color: #ff8c69;
}
.about-you-part .user-type > div:nth-child(2) .about-you-icon:hover{
  border-color: #fc92a5;
}
.about-you-part .user-type > div:nth-child(3) .about-you-icon:hover{
  border-color: #81cffa;
}
.about-you-part .user-type > div:nth-child(4) .about-you-icon:hover{
  border-color: #f4d06f;
}

.about-you-part .about-you-icon img{
  position: absolute;
  right: 0px;
  top:0px;
}

.about-you-part .gender-button.selected{
  border: 2px solid #fff;
}

.about-you-part .gender-button.selected path{
  stroke: #000;
}

.about-you-part .about-you-icon-title{
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
  font-family: AlbertLight;
  position: relative;
  /* left: -5px; */
  text-transform: capitalize;
}

.about-you-part .datepicker-picker{
  bottom: 54px;
  height: initial;
  width: 300px;
}

.about-you-part .datepicker{
  width: 250px;
}

@media only screen and (max-width: 800px) {
  .about-you-part .about-you-icons{
    flex-wrap: wrap;
  }
  .about-you-icons.user-type{
    max-width: 200px;
  }
  .about-you-icons.user-type > div{
    flex: 1 0 41%;
  }
}

@media only screen and (max-width: 480px) {
  /* .about-you-icons.user-type > div{
    flex: 1 0 41%;
  } */
}