.treatment-list .collapse-container {
  overflow: hidden;
  transition: max-height 0.1s ease-in-out;
  max-height: 1000000px;
}
.treatment-list .collapse-container .result-list-description {
  margin-top: 0;
}
.treatment-list .collapsed .collapse-container {
  max-height: 0;
  overflow: hidden;
}
.treatment-header {
  font-family: 'AlbertLight';
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  word-break: break-word;
  /* background: white; */
  cursor: pointer;
}
.treatment-header.list-item {
  border-top: 2px solid rgba(33, 51, 50, 0.2);
  margin-bottom: 14px;
}
.treatment-header.list-item .title {
  font-family: 'Albert';
  margin: 0;
}
.treatment-header .arrow, .treatment-header .index {
  display: inline-block;
  min-width: 20px;
  height: 20px;
  border-radius: 20px;
  text-align: center;
}
.treatment-header .arrow {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
.treatment-list .collapsed .treatment-header .arrow {
  transform: rotate(0deg);
}
.treatment-header .index {
  font-size: 12px;
  line-height: 1.7;
  background: #D3D6D6;
}