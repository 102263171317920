.popupOverlay {
    background-color: #fff;

    :global(.generic-overlay-content) {
        width: 100%;
        height: 100%;
    }
}

.popupOverlay:global(.generic-overlay) {
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    z-index: 90;
}

.popupHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #fff;
    padding-inline-start: 15px;
    padding-inline-end: 15px;
}

.popupHeaderStartGroup button {
    color: #000 !important;
}

.popupHeaderEndGroup {
    display: flex;
}

.popupHeaderEndGroup button {
    margin-inline-start: 10px;

    :global(.light-theme) {
        border: 1px solid rgb(255, 140, 105) !important;
        background-color: #fff !important;
    }
}

.aiAssistant {
    border-bottom: 1px solid #ccc;
    padding: 15px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f7;

    :global(.upload-nav) {
        display: flex;
        justify-content: center;
    }

    :global(.upload-nav) :global(.info) {
        display: flex;
        align-items: center;
        margin-right: 14px;
    }

    :global(.upload-nav) :global(.buttons) {
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    :global(.upload-nav) :global(.or) {
        margin: 0 14px;
    }

    :global(.upload-nav) :global(.button) {
        font-family: AlbertLight;
        font-weight: 400;
        border: none;
    }

    :global(.upload-nav) :global(.button):hover {
        border: none !important;
    }

    :global(.upload-nav) :global(.info) > img {
        margin-right: 8px;
    }
}


.intakeHolder {
    display: flex;
    justify-content: center;
    overflow: auto;
    background-color: #f2f2f7;
    min-height: 100%;
    padding-bottom: 20px;
    padding-top: 10px;
}

.intakeHolder :global(.hcp-form) {
    background-color: #fff !important;
    margin-top: 10px;
    border-radius: 4px;
}

@media only screen and (min-width: 800px) {
    .intakeHolder :global(.hcp-form) {
        max-width: 600px;
        background-color: #f2f2f7;
    }
}
