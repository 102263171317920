.root {
    display: flex;
    align-items: center;
    gap: 20px;
}

.button {
    padding: 20px;
}

.buttonLabel {
}

.buttonIcon {
}

@media only screen and (max-width: 800px) {
    .root {
        gap: 3px;
    }

    .button {
        border-radius: 30px;
        padding: 10px;
        width: 45px !important;
    }

    .buttonLabel {
        display: none;
    }

    .buttonIcon {
        margin: 0 !important;
        width: 24px !important;
        height: 24px !important;
    }
}
