.iframe-overlay{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: #fff;
  z-index: 99999999;
}

.iframe-overlay > div{
  width: 100%;
}

.iframe-overlay .top{
  display: flex;
  border-bottom: 1px solid #efefef;
  justify-content: flex-end;
}

.iframe-overlay .top .close{
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
}

.iframe-overlay .top .close img{

}

.iframe-overlay .bottom{
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  box-sizing: border-box;
  border-top: 1px solid #efefef;
  padding-right: 30px;
  background-color: #FF8C69;
}

.iframe-overlay .bottom .share{
  display: flex;
  margin-inline-end: 25px;
  font-weight: bold;
  cursor: pointer;
  background-color: #fff;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}

.iframe-overlay .bottom .share-icon{
  margin-right: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.iframe-overlay .bottom .share-title {
  color: #FF8C69;
  font-family: 'AlbertLight';
}
.iframe-overlay .bottom .email-title {
  color: #fff;
  margin-left: 6px;
}

.iframe-overlay .bottom .share-icon.link img {
  width: 24px;
  height: 24px;
}
.iframe-overlay .bottom .share-icon.email img {
  width: 22px;
  height: 22px;
}

.iframe-overlay .middle{
  flex-grow: 1;
  flex-shrink: 1;
}

.iframe-overlay .middle iframe{
  width: 100%;
  height: 100%;
  border: none;
}