.root {
    display: flex;
}
.newButton {
    display: flex;
    height: 48px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    border: none;
    border-radius: 16px;
    background: #213332;
    color: #FFF;
    font-family: "Albert";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
}
.newButtonLabel {
    margin-left: 6px;
}
.profileButtonWrapper {
    display: flex;
    align-items: center;
    margin-inline-start: 10px;
}
@media only screen and (max-width: 800px) {
    .newButton {
        min-width: initial;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        padding: inherit;
    }
    .newButtonLabel {
        display: none;
    }
}
