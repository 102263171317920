.range{
  margin-bottom: 15px;
  margin-top: 15px;
}

.range .range-title{
  margin-bottom: 14px;
}

.range .range-options{}

.range .range-options-titles{
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.range .range-options-title{
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.range .range-options-title.range-selected{
  color: #0C9BED;
}

.range .range-options-bar{
  margin-top: 14px;
  height: 6px;
  background: #d1d1d1;
  position: relative;
}

.range .range-options-bar-full{
  background-color: #81CFFA;
  height: 6px;
  position: relative;
}

.range .range-options-handle{
  position: absolute;
    width: 22px;
    height: 22px;
    background: #0C9BED;
    top: -12px;
    border-radius: 20px;
    border: 3px solid #fff;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
    cursor: pointer;
}

.range .range-handle-start{
  left: -13px;
}
.range .range-handle-end{
  left: calc(100% - 15px);
}
.range .range-handle-right{
  left: calc(100% - 26px);
}
.range .range-handle-left{
  left: calc(100% - 0px);
}