.form{}

.form .form-input{
  margin-bottom: 10px;
}

.form .form-input-hidden{
  display: none !important;
}

.form .form-input-title{
  font-size: 14px;
  font-weight: 400;
  color: #474747;
  margin-bottom: 5px;
  margin-top: 10px;
}

.form .required{
  color: #ff5d5d;
}

.form .clear_btn{
  color: #ff5d5d;
}

.form .form-submit{
  width: 100%;
}

.form .form-input:last-child{
  margin-bottom: 0 !important;
}

.form .form-part{
  margin-bottom: 20px;
}

.form .form-part:last-of-type{
  margin-bottom: 0px;
}

.form .form-input.checkbox{
  border: none;
  padding: 0;
}