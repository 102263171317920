.patients-like-you  {
  color: #213332;
  font-family: 'AlbertLight';
}
.patients-like-you  {
  color: #213332;
  font-family: 'AlbertLight';
}
.patients-like-you .community-section {
  text-align: center;
  padding: 0 10px;
}
.patients-like-you .title{
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}
.patients-like-you .subtitle{
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}
.patients-like-you .intensity-title{
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.patients-like-you .intensity-block {
  display: flex;
  justify-content: center;
}
.patients-like-you .intensity-block > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 24px;
}
.patients-like-you .intensity-block > div:last-child {
  margin-right: 0;
}
.patients-like-you .intensity-text {
  font-size: 14px;
  margin: 4px 0;
}
.patients-like-you .intensity-percent {
  font-size: 30px;
  font-weight: 500;
}
.patients-like-you .side-effects .title {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 24px;
}
.patients-like-you .side-effects-section {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.patients-like-you .side-effects-section-title {
  font-size: 16px;
}
.patients-like-you .side-effects-section-percent {
  position: relative;
  width: 80px;
  height: 24px;
  background-color: #21333266;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.patients-like-you .show-percent {
  height: 100%;
  background-color: #0C9BED;
  position: absolute;
  top: 0;
  left: 0;
}
.patients-like-you .side-effects-section-text {
  z-index: 9999;
  position: relative;
}
.patients-like-you .side-effect {
  display: flex;
  justify-content: space-between;
}
.patients-like-you .side-effects .side-effect{
  padding-top: 10px;
}
