@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo&display=swap');

/** Global scrollbar styles */
html {
  --scrollbarBG: #fff;
  --thumbBG: #ccc;
  --tjbgGrey: #fafafa;
  overscroll-behavior: none;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

*::-webkit-scrollbar {
  width: 11px;
}

*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 10px;
  border: 2px solid var(--scrollbarBG);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.rtl {
  font-family: 'Heebo', sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    transition-delay: background-color 5000s, color 5000s;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 30px #FFFFFF inset;
  -webkit-box-shadow: 0 0 0 30px #FFFFFF inset;
}

#credential_picker_container {
  height: unset !important;
  top: 55px !important;
}
