.patients-page::after {
    content: "";
    display: block;
    position: fixed;
    background-color: #f2f2f7;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 100%;
    z-index: -1;
}