.user-intent{}

.user-intent .user-intent-other{
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
  height: 120px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #f6f6f6;
  padding: 15px;
  font-family: 'AlbertLight';
  font-size: 16px;
  resize: none;
}

.user-intent .user-intent-title{
  margin-bottom: 10px;
}

.user-intent .user-intent-textarea{}

.user-intent .user-intent-textarea textarea{
  height: 200px;
  font-family: 'Roboto';
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border:1px solid #aaa;
  border-radius: 3px;
  font-size: 18px;
}