.receiving-wrapper {
    display: flex;
    justify-content: center;
}
.receiving-wrapper > div {
    min-width: 500px;
}
.receiving-wrapper .title{
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 16px;
}
.receiving-wrapper .image-section {
    width: 100%;
    padding: 35px 0;
    background-color: #0C9BED1A;
    display: flex;
    justify-content: center;
    margin-bottom: 51px;
}
.receiving-wrapper .receiving-btn {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 800px) {
    .receiving-wrapper > div {
        width: 100%;
        min-width: unset;
    }
}