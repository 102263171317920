.headerWrapper {
    position: sticky;
    top: 0px;
    z-index: 10;
}
.defaultHeader {
    background-color: #213332;
}
.whiteHeader {
    background-color: #fff;
}
.hcpHeader {
    background-color: #fff;
}
.noProviderHeader {
    /* provider specific props, the same as in providerHeader */
    height: 70px;
}
.providerHeader {
    height: 36px;
}
.header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    width: 100%;
    box-sizing: border-box;
    z-index: 5;
}
.profileButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-start: 10px;
    color: white;
}
.profileLabel {
    display: none;
}

.buttonsWrapper {
    display: flex;
    align-items: center;
}

.hcpHeader {
    .profileButton {
        color: #292D32;
    }
}
.logoContainer {
    align-items: center;
    display: flex;
    justify-content: left;
}
.rightCornerSlot {
    display: flex;
    align-items: center;
    gap: 20px;
}
.logoNoTitle {
    display: none;
}
.logo, .customLogo {
    cursor: pointer;
}

.mobileMenuOpen .header {
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 80px;
    padding-bottom: 20px;
    align-items: flex-start;
    width: initial;
}
.mobileMenuOpen.headerWrapper {
    overflow: auto;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: #213332;
    display: flex;
    justify-content: center;
}
.mobileMenuOpen::after {
    content: "";
    display: block;
    position: fixed;
    height: 80px;
    background-color: #213332;
    bottom: 0px;
    width: 100%;
    z-index: -1;
}

.mobileMenuOpen .logoContainer {
    display: none;
}

.burger {
    cursor: pointer;
    font-size: 24px;
    color: #FFF;
    margin-right: 8px;
    display: flex;
    align-items: center;
    margin-left: 16px;
}

.mobileMenuOpen .burger {
    position: fixed;
    top: 0px;
    right: 0px;
    height: 54px;
    padding: 8px 16px;
}

.burgerMenuWrapper {
    display: none;
}
.helpButton {
    color: #fff;
    border-radius: 8px;
    background: #EB4B62;
    padding: 14px 8px;
    cursor: pointer;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1200px) {
    .logo {
        display: none;
    }
    .logoNoTitle {
        display: inline;
        margin-right: 10px;
    }
}

@media only screen and (max-width: 1024px) {
    .hcpHeader {
        .logoContainer {
            display: none;
        }
    }
    .whiteHeader {
        border-bottom: 2px solid #eee;
    }
    .logo {
        display: none;
    }
    .logoNoTitle {
        display: inline;
    }

    .burgerMenuWrapper {
        display: flex;
    }
    .headerWrapper:not(.mobileMenuOpen) .profileButton {
        display: none;
    }
    .headerWrapper.mobileMenuOpen .profileButton {
        margin-left: 0px;
        order: 1;
        margin-top: 10px;
    }
    .mobileMenuOpen .helpButton {
        display: none;
    }
    .mobileMenuOpen .profileLabel {
        display: block;
        margin-left: 8px;
    }
    .uploadButton {
        display: none;
    }
    .mobileMenuOpen .uploadButton {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #fff;
    }
    .mobileMenuOpen .buttonsWrapper {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 40px;
        gap: 12px;
    }
    
}
