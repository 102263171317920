.trial-details{}

.trial-details .nct{
  font-size: 14px;
  padding-top: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid #d0d0d0;
  color: #3A3A3C;
}

.trial-details .nct a {
  text-decoration: none;
  color: #000;
}

.trial-details .content{
  font-size: 16px;
  line-height: 18px;
  color: #212121;
  padding-top: 15px;
  padding-bottom: 15px;
  /* max-width: 800px; */
  word-break: break-word;
  font-family: AlbertLight;
}

.trial-details iframe{
  width: 100%;
}