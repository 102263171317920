.intake-location{}

.intake-location .geo-suggest{
  /* padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  border: 1px solid #8a8a8a;
  border-radius: 3px;
  font-size: 15px; */
}

.intake-location .geo-suggest-suggests{
  margin: 0;
  font-size: 14px;
  padding: 0px;
  list-style: none;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  border-top: none;
  cursor: pointer;
  position: absolute;
  z-index: 9;
}

.intake-location .geo-suggest-suggests li{
  padding: 10px;
}

.intake-location .geo-suggest-suggests li:hover{
  background: #f4f4f4;
  cursor: pointer;
}

.intake-location .geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.intake-location .set-distance{
  margin-top: 10px;
}

.intake-location .geo-suggest-wrapper {
  display: grid;
  justify-items: end;
  position: relative;
}

.intake-location .geo-suggest-wrapper .geo-clear-button {
  position: relative;
  top: 45px;
  right: 10px;
  cursor: pointer;
}

.intake-location .geo-suggest-wrapper .geosuggest{
  width: 100%;
}

.intake-location .intake-location-distance{
  margin-top: 30px;
}

.intake-location .intake-location-holder{
  border: 1px solid salmon;
  padding: 10px;
  max-width: 250px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.intake-location .geosuggest__input.geo-suggest {
  padding-right: 10px;
  border: none;
  font-size: 16px;
  font-family: Albert;
}

.intake-location .geosuggest__input.geo-suggest:focus-visible{
  outline: none;
  border: none;
}

.rtl .intake-location .geo-suggest-wrapper .geo-clear-button {
  left: 10px;
  right: initial;
}

.rtl .geosuggest__input.geo-suggest {
  padding-left: 30px;
}