.edit-treatment {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
}

.edit-treatment-label {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid rgba(33, 51, 50, 0.40);
}

.edit-treatment-label span {
	width: 80%;
	word-wrap: break-word;
}

.edit-treatment-label img {
	cursor: pointer;
}

.edit-treatment-input-wrapper {
	width: 100%;
}

.edit-treatment-input-wrapper:last-of-type {
	margin-bottom: 50px;
}

.edit-treatment-input-wrapper p {
	font-family: 'AlbertLight';
}

.edit-treatment-input-wrapper select {
	height: 36px;
	width: 100%;
	border-radius: 14px;
	padding: 6px 11px 6px 16px;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-image: url('/assets/icons/arrow-circle-right.svg');
	background-repeat: no-repeat;
	background-position: right 1rem center;
	font-weight: 600;
	cursor: pointer;
}

.edit-treatment-input-wrapper select>option {
	height: 36px;
}

.edit-treatment-update-btn {
	height: 36px;
	border: none;
	border-radius: 14px;
	background-color: #FF8C69;
	color: #FFF;
	max-width: 250px;
	width: 100%;
	cursor: pointer;
}

.edit-treatment-update-btn:hover {
	background-color: #FFF3F0;
	color: #FF8C69;
}
