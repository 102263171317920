.tags{
  padding: 10px;
  display: flex;
  background-color: #fff;
  gap: 10px;
  flex-flow: wrap;
}

.tag{
  display: flex;
  padding: 5px;
  background-color: #fefaf1;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  align-items: center;
}

.tag .tag-title{
  margin-inline-end: 7px;
}