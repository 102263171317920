.chat-answer-picker .answer-picker-container {
    width: 300px; /* Adjust based on your design */
    text-align: center; /* Center the text inside the box */
  }
  .chat-answer-picker{}
  .chat-answer-picker-full-screen {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .chat-answer-picker .chat-question-title {
    margin-bottom: 20px; /* Space between the title and the options */
    font-size: 20px; /* Font size of the title */
  }
  
  .chat-answer-picker .option-button {
    width: 100%; /* Full width of the container */
    padding: 10px; /* Padding around the button text */
    margin-bottom: 5px; /* Space between buttons */
    border: none; /* No border */
    background-color: #FF8C69; /* Button background color */
    color: white; /* Button text color */
    cursor: pointer; /* Cursor changes to pointer on hover */
    border-radius: 4px; /* Rounded corners for the button */
  }
  
  .chat-answer-picker .option-button:hover {
    background-color: white; /* Darker shade on hover */
    color: #FF8C69;
    border: #FF8C69 2px solid;
  }
  