.chatbot-overlay{
  background-color: rgba(0,0,0,0.2);
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.chatbot{
  background-color: #fff;
  width: 380px;
  min-height: 300px;
  position: absolute;
  top:100px;
  right: -380px;
  border-radius: 5px;
  box-shadow: 0px 5px 12px rgba(0,0,0,0.2);
  transition: all 0.2s ease-out;
}

.chatbot.chatbot-visible{
  top:100px;
  right: 40px;
}

.chatbot .chatbot-header{
  display: flex;
  justify-content: flex-end;
  padding: 7px;
}

.chatbot .chatbot-close{
  cursor: pointer;
}

.chatbot .chatbot-body{
  padding: 25px;
}

.chatbot .chatbot-icon{
  margin-inline-end: 10px;
}

.chatbot .chatbot-icon-img{
  width: 36px;
}

.chatbot .chatbot-chat-item{
  display: flex;
}

.chatbot .chatbot-chat-message{
  padding: 10px;
  background-color: #f2f2f2;
  margin-bottom: 10px;
  border-radius: 10px;
}

.chatbot .chatbot-thread{

}

.chatbot .chatbot-loading{
  display: none;
}

.chatbot .chatbot-loader{
  width: 40px;
}

.chatbot .chatbot-messages{

}

.chatbot .chatbot-message{

}

.chatbot .chatbot-button{
  margin-bottom: 10px;
}

@media only screen and (max-width: 800px) {
  .chatbot{
    top:-400px;
    right: 0px;
    width: 100%;
    border-radius: 0px;
  }
  .chatbot.chatbot-visible{
    top: 0px;
    right: 0px;
  }
  .chatbot .chatbot-body{
    padding-top: 0px;
  }
}