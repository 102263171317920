.popup-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.2);
}

.popup-mobile-only {
  display: none !important;
}

.popup-visible .popup-overlay {
  display: block;
}

.popup-manual-position .popup{
  /* transition: none; */
}

.popup {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  width: 400px;
  background: #fff;
  height: 100%;
  right: -400px;
  transition: all 0.3s ease-out;
  z-index: 1001;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
}

.rtl .popup {
  right: initial;
  left: -400px;
}

.popup-visible .popup {
  right: 0px;
}

.rtl .popup-visible .popup {
  left: 0px;
  right: initial;
}

.popup .popup-header {
  padding: 5px;
  justify-content: end !important;
}

.popup .popup-header img {
  cursor: pointer;
}

.popup .popup-content {
  height: calc(100% - 27px);
  overflow: scroll;
}

.popup .popup-content>div {
  padding-bottom: 90px;
}

.popup .popup-content .popup-title {
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #81CFFA;
}

.popup .popup-content .popup-subtitle {
  font-size: 18px;
  font-weight: 400;
}

.popup .popup-content .popup-padding{
  padding: 15px;
}

.popup.medical_profile .popup-padding {
  padding: 0;
}

.popup .popup-footer {
  display: none;
  width: 100%;
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  border-top: 1px solid #f0f0f0;
  background: #fff;
  left: 0;
}

.popup-visible .popup-footer {
  display: block;
}

.popup .popup-property {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.popup .popup-property-side {
  display: flex;
}

.popup .popup-property-side img {
  width: 18px;
}

.popup .popup-property-button {
  background: rgb(255, 140, 105);
  color: #fff;
  text-align: center;
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 13px;
  cursor: pointer;
  align-self: center;
}

.popup .popup-property-button:hover {
  background: rgba(255, 140, 105, 0.1);
  color: #FF8C69;
}

.popup .popup-property .popup-property-title {
  font-size: 16px;
  margin-bottom: -3px;
}

.popup .popup-property .popup-property-value {
  color: #848484;
  font-size: 16px;
}

.popup .popup-link {
  color: rgb(58 130 247);
  font-weight: bold;
  margin-bottom: 2px;
}

.popup .popup-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.popup-theme-middle .popup-overlay{
  background: rgb(0 0 0 / 60%);
}

.popup-theme-middle .popup, .popup-theme-full .popup{
  background: transparent;
  right: -400px !important;
}

.popup-theme-middle .popup-title{
  text-align: center;
  border-bottom: none !important;
  border-top: 1px solid #e4e4e4;
  font-size: 27px !important;
  font-family: AlbertBold;
}

.popup-theme-middle .popup .popup-header img{
  width: 40px;
}

.popup-theme-middle .popup .popup-header{
  padding: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: #fff;
}

.popup-theme-middle .popup .popup-content{
  background: #fff;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  height: 100%;
}

.popup-theme-middle .popup-footer, .popup-theme-full .popup-footer{
  position: static;
}

.popup-theme-middle .popup .popup-content>div, .popup-theme-full .popup .popup-content>div{
  padding: 0;
}

@media only screen and (min-width: 800px) {

  .popup-theme-middle, .popup-theme-full{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    left: 0;
  }

  .popup-theme-middle .popup{
    position: static;
    height: fit-content;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 600px;
  }

  .popup-theme-full .popup{
    position: static;
    height: fit-content;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }

}

@media only screen and (max-width: 800px) {

  .popup-theme-middle .popup, .popup-theme-full .popup{
    height: 100%;
    width: 100%;
  }

  .popup-theme-full .popup-header{
    display: none;
  }

  .popup-theme-full .popup .popup-content{
    height: 100%;
  }

  .popup, .popup-theme-full .popup .popup-content > div{
    width: 100%;
    height: 100%;
  }

  .popup-theme-middle .popup .popup-header{
    border-radius: 0px;
  }

  .popup-mobile-only {
    display: initial !important;
  }

  .popup .popup-footer {
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    width: calc(100% - 20px);
    left: 10px;
  }

  .popup {
    bottom: -100%;
    left: 10px;
    top: initial;
    width: calc(100% - 20px);
  }

  .popup-visible .popup {
    right: initial;
    bottom: -20%;
  }

  .popup-visible.popup-full-height .popup {
    bottom: 0%;
  }

  .rtl .popup-visible .popup {
    left: initial;
    right: initial;
  }

  .popup .popup-content>div {
    padding-bottom: 200px;
  }

  @media only screen and (max-width: 800px) { 
    .popup-visible .popup{
      width: 100%;
      right: initial;
      bottom: 0;
      left: 0;
    }
  }

}