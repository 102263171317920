.quotes-widget {
    display: flex;
}
.quotes-widget .image-cont {
    height: 286px;
    width: 312px;
}
.quotes-widget .image-cont img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.quotes-widget .quote-cont {
    background: #F5FAEA;
    flex: 1;
    padding: 25px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.quotes-widget .quote-cont .quote {
    color: #000;
    font-family: "AlbertLight";
    font-size: 24px;
}
.quotes-widget .quote-cont .author {
    color: #000;
    font-family: "Albert";
    font-size: 16px;
    line-height: 24px;
}

@media only screen and (max-width: 800px) {
    .quotes-widget {
        flex-direction: column;
    }
    .quotes-widget .quote-cont .quote {
        font-size: 16px;
        margin-bottom: 12px;
    }
    .quotes-widget .image-cont {
        width: 100%;
    }
}