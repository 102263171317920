.profileTreatments * {
  box-sizing: border-box
}

.profileTreatments {
  margin-bottom: 34px;
  border-bottom: 1px solid #E2E2E5;
}

.mainOrangeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 310px;
  width: 100%;
  height: 36px;
  padding: 10px 24px;
  border: 0px;
  border-radius: 14px;
  color: #fff;
  background-color: #FF8C69;
  cursor: pointer;
}

.mainOrangeButton:hover {
  background-color: #FFF3F0;
  color: #FF8C69;
}

.mainOrangeButton:hover span {
  background-color: #FFF3F0;
  color: #FF8C69;
  border-color: #FF8C69;
}

.currentTreatmentSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.currentTreatmentSection button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  font-weight: 600;
  padding: 10px 24px;
  border: 1px solid #FF8C69;
  border-radius: 12px;
  color: #FF8C69;
  background-color: #fff;
  cursor: pointer;
}

.currentTreatmentSection button:hover{
  background-color: #FFF3F0;
}

.section-title-wrapper {
  display: flex;
  align-items: center;
}

.section-title-wrapper span {
  display: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #EB4B62;
  color: #fff;
  margin-right: 4px;
}
.section-title-wrapper h3 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  margin: 0;
}

.addNewTreatmentTitle {
  margin-top: 0;
}

.addNewTreatmentSection {
  border: 1px solid #E5E5EA;
  border-radius: 4px;
  padding: 4px 10px 14px 10px;
  margin-bottom: 12px;
  font-size: 16px;
}

.addNewTreatmentSection span {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 22px;
  height: 22px;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: #FF8C69;
  color: #FFF;
}

.addNewTreatmentButtonTitle {
  line-height: 24px;
  font-weight: 600;
}

.lastTreatmentSection {
  margin-bottom: 14px;
}

.lastTreatmentHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
}

.lastTreatmentHeader img {
  margin-right: 10px;
}

.lastTreatmentHeader span {
  font-size: 14px;
  font-family: AlbertLight;
  line-height: 18px;
  color: #0C9BED;
}

.lastTreatmentBody {}

.lastTreatmentInfoWrapper {
  border: 1px solid #E5E5EA;
  border-radius: 4px;
  padding: 4px 10px;
  margin-bottom: 12px;
}

.lastTreatmentLabelWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(33, 51, 50, 0.40);
  margin-bottom: 14px;
}

.lastTreatmentLabelWrapper p {
  width: 80%;
  margin: 0;
  word-wrap: break-word;
}

.lastTreatmentLabelWrapper img {
  word-wrap: break-word;
  cursor: pointer;
}

.stopReasonWrapper {
  margin-bottom: 14px;
}

.stopReasonTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.stopReasonValue {
  font-size: 16px;
  font-family: AlbertLight;
  line-height: 24px;
}

.treatmentAdminWrapper {
  margin-bottom: 14px;
}

.treatmentAdminTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

.treatmentAdminValue {
  font-size: 16px;
  font-family: AlbertLight;
  line-height: 24px;
}

.previousTreatmentHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
}

.previousTreatmentHeader img {
  margin-right: 10px;
}

.previousTreatmentHeader span {
  font-size: 14px;
  font-family: AlbertLight;
  line-height: 18px;
  color: #2EC4B6;
}

.previousTreatmentWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #E5E5EA;
  border-radius: 4px;
  padding: 4px 10px;
  margin-bottom: 12px;
}

.previousTreatmentWrapper p {
  margin: 0;
  width: 80%;
  word-wrap: break-word;
}

.previousTreatmentWrapper img {
  cursor: pointer;
}

.lastTreatmentStateWrapper {
  border: 1px solid #E5E5EA;
  border-radius: 4;
  padding: 4px 10px;
  margin-bottom: 14px;
  background-color: #FEFBF1;
}

.lastTreatmentStateWrapper ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.lastTreatmentStateWrapper li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  min-height: 36px;
  padding: 10px 16px;
  margin-bottom: 16px;
  border: 1px solid #FF8C69;
  border-radius: 14px;
  cursor: pointer;
}

.lastTreatmentStateWrapper li span {
  border: 1px solid black;
  border-radius: 50%;
  min-width: 16px;
  min-height: 16px;
  margin-right: 10px;
}

.lastTreatmentStateWrapper li:hover {
  background-color: #FFF3F0;
}

.lastTreatmentTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 14px;
}

.lastTreatmentTitleWrapper img {
  margin-right: 14px;
}

.lastTreatmentTitleWrapper span {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}


.survey_popup_wrapper {
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.survey_popup_wrapper p {
	margin: 0;
	padding: 0;
}

.survey_popup_wrapper img {
	max-width: 115px;
	max-height: 115px;
	margin-bottom: 30px;
}

p.survey_popup_body1 {
  word-break: break-word;
	font-family: AlbertLight;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
	margin-bottom: 27px;
 }
.survey_popup_body2_wrapper {
	margin-bottom: 100px;
}
.survey_popup_body2_wrapper p {
  font-family: AlbertBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.survey_popup_body2_wrapper span {
  font-family: AlbertLight;
}

.survey_popup_buttons_wrapper {
	display: flex;
}

.survey_popup_yes_btn {
	height: 36px;
	border: none;
	border-radius: 14px;
	padding: 10px 24px;
	background-color: #FF8C69;
	color: #FFF;
	width: 100%;
	white-space: nowrap;
	cursor: pointer;
}

.survey_popup_yes_btn:hover {
	background-color: #FFF3F0;
	color: #FF8C69;
}

.survey_popup_no_btn {
	height: 36px;
	padding: 10px 24px;
	max-width: 70px;
	width: 100%;
	margin-right: 10px;
	border: 1px solid #FF8C69;
	border-radius: 14px;
	background-color: #FFF;
	color: #FF8C69;
	cursor: pointer;
}

.survey_popup_no_btn:hover {
	background-color: #FF8C69;
	color: #FFF;
}

@media only screen and (max-width: 800px) {
	.survey_popup_body2_wrapper {
		margin-bottom: 30px;
	}
}