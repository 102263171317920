.dropdown-button{
  display: inline-block;
  position: relative;
  font-family: AlbertLight;
}
.dropdown-button-header{
  display: flex;
  align-items: center;
  background-color: #f2f2f7;
  border-radius: 10px;
  padding-inline-end: 10px;
  cursor: pointer;
}
.dropdown-button-header-icon{}
.dropdown-button-header-title{}
.dropdown-button-options{
  position: absolute;
  background: #fff;
  box-shadow: 0px 0px 10px #c4c4c4;
  padding: 10px;
}
.dropdown-button-option{
  display: flex;
  margin-top: 10px;
  padding: 10px;
  border-radius: 20px;
  align-items: center;
  cursor: pointer;
}
.dropdown-button-option:hover{
  background-color: #efefef;
}
.dropdown-button-option-icon{
  padding-inline-end: 5px;
}
.dropdown-button-option-title{
  white-space: nowrap;
}
.dropdown-button-option-selected{
  background-color: #ffe8e1 !important;
}