.search-select{}
.search-select-title{
  margin-bottom: 15px;
}
.search-select .search-select-input{
  border: 1px solid salmon;
  padding: 10px;
  max-width: 250px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;

}
.search-select .search-select-input.search-select-input-value{
  border: 1px solid #000;
}

.search-select .search-select-input input{
  border: none;
  font-size: 16px;
  font-family: Albert;
}

.search-select .search-select-input input:active,
.search-select .search-select-input input:focus,
.search-select .search-select-input input:focus-visible{
  border: none;
  outline: none;
}

.search-select .search-results{
  max-height: 180px;
  overflow: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search-select .button{
  max-width: 100px;
}

.search-select .multiple-option-select .select-option .option-label{
  white-space: pre-wrap;
  word-break: break-word;
}

.search-select .search-results{
    scrollbar-width: thin;
    scrollbar-color: rgb(255, 140, 105) transparent;
}

.search-select .search-select-selecteds{
  /* padding: 10px; */
  border: 1px solid #333;
  margin-top: 10px;
}

.search-select .search-select-selecteds-title{
  font-family: AlbertLight;
  margin-bottom: 10px;
}

.search-select .button{
  margin-top: 10px;
}