.root {
    display: flex;
    align-items: center;
    height: 100%;
}

.defaultTabs {}

.hcpTabs {}

.tab {
    display: flex;
    align-items: center;
    margin-inline-end: 15px;
    cursor: pointer;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    height: 100%;
}

.activeTab {
    border-bottom: 4px solid #ff8c69;
    border-radius: 0px;
    font-family: Albert;
    font-weight: bold;
}

.tabIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-end: 5px;
    border: 2px solid #FF8C69;
    background-color: #FF8C69;
    height: 40px;
    width: 40px;
    border-radius: 50px;
    color: white;
}
.activeTab {
    .tabIcon {
        width: 40px;
        height: 40px;
        background-color: #FFF;
        border: 0px;
        color: #000;
    }
}
.tabTitle {
    position: relative;
    color: #fff;
    font-family: AlbertLight;
}
.defaultTabs .tabIcon {
    display: none;
}
.hcpTabs .tabTitle {
    color: #000;
}

.mobileMenuOpen {
    background-color: #213332;
    flex-direction: column;
}
.mobileMenuOpen .tab {
    height: initial;
    margin-bottom: 46px;
    padding: 6px;
}

.mobileMenuOpen .tab .tabTitle {
    font-size: 16px;
}
.mobileMenuOpen.root {
    height: initial;
    align-items: flex-start;
}
.mobileMenuOpen.root .activeTab {
    /* border-bottom: 0px; */
}

.tinaTab .tabTitle {
    background-color: #EB4B62;
    height: calc(100% - 20px);
    align-items: center;
    display: flex;
    border-radius: 6px;
    padding: 0px 8px;
}

.mobileMenuOpen .tinaTab .tabTitle {
    height: 48px !important;
}
.tinaEmoji {
    margin-left: 4px;
}

@media only screen and (max-width: 1024px) {
    .root:not(.mobileMenuOpen) .tab:not(.activeTab) {
        display: none !important;
    }
    .root {
        border: initial !important;
    }
    .mobileMenuOpen .tab {
        display: block !important;
    }
    .mobileMenuOpen .tinaTab .tabTitle {
        height: initial;
    }
    .root:not(.mobileMenuOpen) .tinaTab .tabTitle {
        background-color: initial;
    }
    .root:not(.mobileMenuOpen) .tinaTab .tinaEmoji {
        display: none;
    }
    .mobileMenuOpen .tab:nth-last-child(2) {
        margin-bottom: 24px;
    }
    .mobileMenuOpen .tab:nth-last-child(1) {
        margin-bottom: 10px;
    }
    .root:not(.mobileMenuOpen) .activeTab {
        border-bottom: 0px;
    }

    .root {
        border-inline-end: 1px solid #cacaca;
    }

    .root.defaultTabs {
        border-inline-end: 1px solid #385755;
    }

    .tab {
        align-items: center;
        justify-content: center;
        display: flex;
        margin-inline-end: 8px;
    }

    .tabTitle {
        text-align: center;
        font-size: 12px;
    }

    .hcpTabs .tabTitle {
        display: none;
    }
}
