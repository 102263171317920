.hcp-form{
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;
}

.hcp-form .intake-preloader{
  padding-top: 20px;
  padding-bottom: 15px;
  position: relative;
  top:-2px;
}

.hcp-select-indication{
  margin-bottom: 10px;
}

.hcp-question .intake-question{
  border: none;
  background-color: transparent;
  padding: 0;
}

.hcp-question .intake-question-title{
  display: none;
}

.hcp-question .intake-question-collapser{
  display: none;
}

.hcp-form .intake-done-button{
  display: none;
}

.hcp-input{}

.hcp-number-unit{
  color: #ff8c69;
  background-color: #f7f7f7;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}

.hcp-input-title{
  font-size: 14px;
  margin-bottom: 8px;
  padding-inline-start: 3px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  box-sizing: border-box;
}

.hcp-number-input{
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ff8c69;
  height: 52px;
  font-family: AlbertLight;
  text-align: center;
  cursor: pointer;
  color: #383838;
  font-size: 22px;
}

.box-question-title{
  padding-bottom: 5px;
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
}

.hcp-form .intake-question-collapser{
  display: none !important;
}
.hcp-form .family_name {
  background: rgba(33, 51, 50, 0.10);
  padding: 4px 8px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.hcp-form .family_name img {
  margin-right: 8px;
}

.box-question-treatments{
  /* padding-top: 15px; */
  /* padding-bottom: 15px; */
  margin-top: 10px;
  /* padding-left: 5px; */
  /* padding-right: 5px; */
}

.box-question-treatment{
  margin-bottom: 10px;
}

.box-question-treatment-section{
  margin-bottom: 10px;
}

.box-question-treatment-title{
  margin-bottom: 5px;
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box-question-treatment-title span{
  color: red;
}

.hcp-form .box-question-treatment-background{
  background-color: #f2f2f2;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}

.hcp-form .accordion .accordion-item-body{
  overflow: initial;
}

.box-question .add-more{
  margin-top: 5px;
  
}

.box-question .add-more button{
  width: initial !important;
  padding: 5px !important;
  height: 30px !important;
  font-family: AlbertLight;
}

.box-question .add-more button span{
  font-size: 14px;
  font-family: 'AlbertLight';
  font-weight: normal;
}

.box-question .still-on-treatment{
  display: flex;
  align-items: center;
  font-family: AlbertLight;
  cursor: pointer;
  font-size: 14px;
}

.box-question .still-on-treatment-box{
  width: 17px;
  height: 17px;
  background-color: #fff;
  border: 1px solid #575757;
  border-radius: 5px;
  margin-inline-end: 5px;
}