.text{}

.text textarea{
  font-family: 'AlbertLight';
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border:1px solid #aaa;
  border-radius: 3px;
  font-size: 15px;
}