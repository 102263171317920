.button-item {
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1px solid transparent;
  font-family: AlbertLight;
  margin-inline-end: 5px;
  margin-inline-start: 5px;
  cursor: pointer;
  /* border: 1px solid #ccc; */
}

.button-item.download img{
  width: 20px;
  padding-inline-end: 3px;
  padding-inline-start: 3px;
}

.button-item img {
  width: 29px;
}

.button-item .button-item-title {
  padding-inline-start: 5px;
}

.button-item .button-item-icon{
  display: flex;
  align-items: center;
}

.button-item .button-item-loading {
  display: inline-block;
  cursor: default;
}

.button-item .button-item-loading:after {
  content: " ";
  display: block;
  width: 14px;
  height: 14px;
  margin: 8px;
  border-radius: 50%;
  border: 1px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: button-loading 1.2s linear infinite;
}

.button-item.loading img {
  cursor: default;
  opacity: 0.3;
}

@keyframes button-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (hover: hover){
  .button-item:hover:not(.loading) {
      background: #fff;
      border: 1px solid #ccc;
  }
}

@media only screen and (max-width: 800px) { /* mobile mode */
  .button-item:hover:not(.loading) {
    background: transparent;
    border: 1px solid transparent;
  }
}