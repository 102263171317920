.login{
  /* display: flex; */
}

.login .login-form .link {
  color: #FF8C69;
  cursor: pointer;
}

.login .login-container{
  display: inline-flex;
  width: 100%;
  gap: 30px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.login .login-options{
  /* padding: 10px 0; */
  width: 335px;
  text-align: center;
}

.login .login-box{
  width: 500px;
}

.login .login-form{
  background: #fff;
  padding: 24px 70px;
  border-radius: 5px;
}

.login .google-button{}

.login .login-error{
  text-align: center;
  padding: 20px;
  color: red;
  padding-top: 0;
}

.login .login-options .title{
  margin-bottom: 10px;
}

.login .action{
  text-align: center;
  padding: 10px 0;
  /* margin-bottom: 20px; */
  position: relative;
}

.login .action span{
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  color: #FF8C69;
}

.login .back-holder{
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.login .login-form{}

.login .login-form input{
  background: #F2F2F7;
  border: 1px solid #D1D1D6;
  font-size: 14px;
  padding: 14px;
}

.login .phone-input .phone-input-dropdown{
  background: #F2F2F7;
  border: 1px solid #D1D1D6;
  border-radius: 5px;
}

.login .login-button{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 12px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 14px;
  border: 1px solid #FF8C69;
  color: #FF8C69;
  font-weight: bold;
  cursor: pointer;
}

.login .login-button:hover {
  background-color: rgba(255, 140, 105, 0.1);
}

.login .icon {
  padding-right: 10px;
}

.login .inline-button{
  text-decoration: underline;
  color: #FF8C69;
  cursor: pointer;
  font-size: 14px;
}

.login .inline-button:hover{}

.login .header{
  text-align: center;
}

.login .header h1{
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}

.login .login-buttons{}

.login .login-buttons .login-button{
  margin-bottom: 10px;
}

.login .no-account{
  margin-top: 15px;
  font-size: 14px;
}

.login .forgot-password{
  max-width: 460px;
  text-align: center;
}

.login .reset-password{
  max-width: 460px;
  text-align: center;
}

.login .form{
  margin-bottom: 20px;
}

.login .title{
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 20px;
}

.login .text{
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 40px;
}

.login .input-title{
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.login .forgot-password .required{
  color: red;
}

/* otp */

.login .otp{}

.login .otp-verify{
  text-align: center;
}

.login .otp-verify-box{}

.login .phone-container{}

.login .phone-input{}

.login .phone-button{}

.login .verify-action{}

.login .verification-container{
  width: 100%;
}

.login .verification-character{
  background: #F2F2F7;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
}

.login .verification-character-inactive{}

.login .verification-character-selected{}

.login .otp .text{
  margin-bottom: 20px;
}

.login .verify-action{
  text-align: center;
  font-size: 14px;
  padding: 15px;
}

.login .otp .title{
  margin-bottom: 20px;
}

.login .otp .text{}

.login .otp .vi__wrapper{
  display: inline-block;
  width: 100%;
  height: 65px;
}

.login .otp .vi__wrapper input{
  position: absolute;
  top: 60px;
  pointer-events: none;
  opacity: 0;
}

.login .otp .input{
  text-align: center;
}

.login .approve-text{}

.login .approve-text .masked{
  margin-top: 10px;
  margin-bottom: 40px;
}

/* EXTERNAL LOGN */

.login .external-button{
  display: flex;
  justify-content: flex-start;
  padding-top: 8px;
  padding-bottom: 8px;
}

.login .external-button .external-button-icon{
  position: relative;
  top:2px;
  margin-inline-end: 10px;
}

.login .external-button .external-button-title{
  align-self: center;
}

.login .outer-space{
  position: absolute;
  top: -10000px;
}

@media only screen and (max-width: 850px) {
  .login .login-box{
    width: 100%;
  }
}

@media only screen and (max-width: 650px) {
  .login .login-form{
    padding: 24px 10px;
  }
}