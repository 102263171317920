.scroll-tabs *{
  box-sizing: border-box;
}
.scroll-tabs{
  position: relative;
}

.scroll-tabs-head{
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  position: sticky;
  top:0;
  left: 0;
  background-color: #fff;
  z-index: 10;
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
  user-select: none;
  padding-left: 10px;
  padding-right: 10px;
}

.scroll-tabs-head::-webkit-scrollbar {
  display: none;
}

.scroll-tabs-body{
  overflow: auto;
  padding: 16px 24px;
  padding-top: 20px;
}

.scroll-tabs-tab{
  display: flex;
  align-self: center;
  white-space: nowrap;
  padding: 5px;
  padding-left: 5px;
  padding-right: 10px;
  cursor: pointer;
}

.scroll-tabs-tab span{
  display: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #EB4B62;
  color: #fff;
  margin-right: 4px;
  pointer-events: none;
}

.scroll-tabs-tab.selected-tab{
  background-color: #FF8C6933;
  border-radius: 15px;
}

.scroll-tabs-content{}