.new-ui main.main-upload {
    padding: 0;
}
.new-ui .header-upload {
  padding: 18px 100px !important;
}
.main-upload {
    background-color: #fff;

}

.upload-page .popup {
 z-index: 999999;
}

.upload-page .popup-overlay {
    z-index: 999999;
}
.upload-page .upload-page-title {
    text-align: center;
    font-size: 64px;
    font-family: 'Albert';
    margin-bottom: 24px;
    color: #FF8C69;
    font-weight: 600;
}

.upload-page .upload-page-title-main {
  text-align: center;
  font-family: "Albert";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}
.upload-page .upload-page-title-main strong {
  font-weight: 700;
  font-family: "AlbertBold";
}
.upload-page .upload-page-start {
    font-size: 36px;
    text-align: center;
    margin-bottom: 24px;
    font-family: 'AlbertLight';
    color: #213332;
}

.upload-page .upload-page-start-low-title {
  color: #213332;
  text-align: center;
  font-family: "AlbertLight";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px; /* 160% */
  margin-top: 10px;
}
.upload-page .upload-page-start-wrapper {
    display: grid;
    grid-template-columns:1fr 1fr 1fr;
    background-color: #2133321A; 
}
.upload-page .upload-page-start-wrapper .start {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.upload-page .upload-page-start-wrapper .start img {
    margin-bottom: 280px;
    margin-right: 26px;
    max-width: 320px;
    width: 100%;
}
.upload-page .upload-page-start-wrapper .empty {
   max-width: 320px;
   width: 100%;
}
.upload-page .upload-page-start-section {
    padding: 24px 0 14px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.upload-page .upload-page-start-section .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 14px;
}
.upload-page .upload-page-start-section-bottom {
    display: flex;
    margin-top: 38px;
    zoom: 0.8;
}
.upload-page .upload-page-start-section-bottom img:nth-last-child(2) {
    margin: 0 44px;
}
.upload-page .upload-page-file-uploader-multi {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999999;
    background-color: #fff;
    display: flex;
    justify-content: center;
}

.upload-page .file-uploader-block {
    min-width: 500px;
}
.upload-page .upload-registration-wrapper {
    min-width: 500px;
}
.upload-page .upload-page-file-uploader {
    max-width: 500px;
    width: 100%;
}
.upload-page .upload-page-file-uploader .desktop-files-uploader {
    background-color: #fff;
    padding:  26px 0;
}
.upload-page .upload-page-file-uploader .desktop-files-uploader .browse {
  margin-top: 24px;
}
.upload-page .upload-page-start-text {
    margin: 6px 0 8px 0;
}
.upload-page .testimonials {
    margin-top: 14px;
}
.upload-page .ebook-download {
    display: block;
}
.upload-page .ebook-download h3 {
    text-align: center;
}
.upload-page .ebook-download img {
    margin-right: 24px;
}
.upload-page .upload-page-start-blcok {
    display: flex;
    align-items: center;
    margin-top: 24px;
}
.upload-page .upload-page-start-blcok .or {
    font-size: 16px;
    font-weight: 600;
    margin: 0 14px;
}
.upload-page .upload-page-start-blcok .or-none {
    display: none;
}
.upload-page .upload-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #21333266;
    padding: 16px 0;
    font-size: 12px;
    margin-top: 50px;
}
.upload-page .upload-footer img {
    margin-right: 4px;
}
.upload-page .upload-registration-wrapper {
    padding: 0 10px;
}
.upload-page .upload-registration-wrapper .MuiInput-root {
    color:#000
}
.upload-page .upload-registration-wrapper .next-btn {
    margin-top: 26px;
}
.upload-page .upload-registration-wrapper .registration-email input{
    outline: none;
    border: 1px solid #FF8C69;
}
.upload-page .upload-registration-wrapper .registration-phone input, .phone-input-dropdown{
    outline: none;
    border: 1px solid #FF8C69 !important;
}
.upload-page .none .files-uploader-preview {
    display: none;
} 
.upload-page .upload-registration-wrapper .MuiFormLabel-root,.special-label {
    font-size: 16px;
    font-weight: 600;
    font-family: 'AlbertLight';
}
.upload-page .upload-registration-title {
    font-size: 20px;
    margin-top: 14px;
}
.upload-page .privacy-text {
    font-family: 'Roboto';
    margin-top: 26px;
    font-size: 10px;
}
.upload-page .privacy-text a {
    color: #000;
}
.upload-page .files-uploader-preview {
    border: none;
}
.upload-page .files-uploader-preview-title {
    font-size: 20px;
    font-weight: 700;
    margin: 4px 0 7px 0;
    padding-top: 100px;
}
.upload-page .files-uploader-progress {
    margin-top: 10px;
}
.upload-page .overlay-main {
    width: 500px !important;
    display: flex;
    justify-content: center;
}
.upload-page .overlay {
    display: flex;
    align-items: center;
}
.upload-page .animate-section {
    width: 460px;
    margin-top: 100px;
}
.upload-page .upload-page-form-error {
    margin-top: 12px;
    border: 2px solid red;
    padding: 15px;
    border-radius: 5px;
    box-sizing: border-box;
}
.upload-page .upload-page-error {
    padding: 20px;
    text-align: center;
    font-size: 18px;
    border-radius: 3px;
    margin-bottom: 20px;
    margin-top: 100px;
  }
  .upload-page  .upload-page-found {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 10px;
    font-family: AlbertLight;
  }
  .upload-page .upload-page-analyze-found-info {
    text-align: center;
    margin-top: 10px;
  }
  .upload-page .upload-page-files-biomarkers {
    max-height: 100px;
    overflow: auto;
  }

.upload-page .upload-intake-block{
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-page .upload-intake-parent{
    width: 700px;
}

.upload-page .upload-intake-parent-button{
    margin: 30px 0;
}

.upload-page .top-title{
    text-align: center;
    font-size: 25px;
    font-family: 'AlbertBold';
    margin-top: 20px;
    margin-bottom: 24px;
}

.upload-page .upload-intake-parent-button{
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-page .upload-intake-parent-button .button{
    max-width: 500px;
}
.upload-page .app {
  background-color: #fff !important;
}
.upload-page-footer {
    display: flex;
    margin-top: 15px;
    flex-direction: column;
    gap: 15px;
  }
  .upload-page-form-footer{
    display: block;
  }

  .upload-page-form-footer .upload-page-form-preloader{
  margin-bottom: 10px;
}

.upload-page .upload-page-skeleton .upload-page-title,
.upload-page .upload-intake-wrapper .top-title {
    margin-top: 100px;
}

.upload-page .files-uploader-state-UPLOADING .header-login-block,
.upload-page .files-uploader-state-CUSTOM1 .header-login-block,
.upload-page .files-uploader-state-ANALYZING .header-login-block,
.upload-page .files-uploader-state-ERROR .header-login-block,
.upload-page .files-uploader-state-MULTI .header-login-block{
    display: none;
}

.overlay-signup {
    padding-top: 40px;
}

.overlay-signup .overlay-top {
    display: none;
}

.upload-page .upload-page-start-inner-bottom{
    margin-top: 35px;
}
.upload-page .upload-page-start-inner-bottom-title{
    text-align: center;
    margin-bottom: 15px;
}
.upload-page .upload-page-start-inner-bottom-buttons{}

.upload-page .upload-page-start-inner-bottom-buttons-or{
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.upload-page .upload-overlay{
    /* padding: 10px; */
}
.upload-page .upload-overlay-header{
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
    display: flex;
    width: 100%;
}
.upload-page .upload-overlay-header img{
    height: 40px;
    margin: 10px;
}
.upload-page .upload-overlay-middle{
    padding: 10px;
    box-sizing: border-box;
}
.upload-page .generic-overlay .generic-overlay-content {
    width: 100%;
}

.upload-page .generic-overlay{
    align-items: baseline;
    justify-content: normal;
    background: #fff;
    width: 100%;
    height: 100%;
}

.upload-page .generic-overlay.generic-overlay-visible{
    /* display: block; */
}

.upload-page .upload-overlay .upload-registration-wrapper{
    width: initial;
}

.upload-page .upload-overlay .upload-registration-wrapper .upload-page-start-section-bottom{
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-page .upload-overlay .upload-registration-title{
    margin-bottom: 20px;
}

.upload-page .next-continue-btn{
    pointer-events: none;
    opacity: 0.5;
    margin-top: 25px;
}   

.upload-page .next-continue-btn.next-continue-btn-enabled{
    pointer-events: initial;
    opacity: 1;
}

.upload-page .continue-popup{
    position: fixed;
    background-color: #000000b4;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    top:0;
    left: 0;
}
.upload-page .continue-popup-container{
    background: #fff;
    width: 80%;
    margin: 0px auto;
    padding: 10px;
    border-radius: 15px;
}
.upload-page .continue-popup-header{
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.upload-page .continue-popup-header-close{
    width: 20px;
}
.upload-page .continue-popup-header-close img{
    width: 25px;
}
.upload-page .continue-popup-middle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #e7f5fe;
    padding: 20px;
}
.upload-page .continue-popup-img{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.upload-page .continue-popup-text{
    margin-bottom: 15px;
}
.upload-page .continue-popup-text2{
    margin-bottom: 15px;
}
.upload-page .continue-popup-text3{}
.upload-page .continue-popup .bold{
    font-family: AlbertBold;
}
.upload-page .continue-popup .small{
    font-size: 14px;
    margin-top: 3px;
}
.upload-page .continue-popup-text3 img{
    width: 25px;
    margin-inline-end: 5px;
}
.react-tel-input .flag-dropdown{
  border: 1px solid #d4d4d4 !important;
}

.upload-page .upload-answer-button{
  display: flex;
  height: 55px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 375px;
  border-radius: 4px;
  border-color: transparent;
  background: var(--Web---Orange, #FF8C69);
  color: var(--match-white, #FFF);
  font-family: "Albert";
  font-size: 20px;
  font-style: normal;
  line-height: 24px; /* 120% */
  cursor: pointer;
}

.upload-page .upload-medical-button{
  display: flex;
  height: 55px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 375px;
  border-radius: 4px;
  border-color: transparent;
  background: var(--Web---Orange, #C77DFF);
  color: var(--match-white, #FFF);
  font-family: "Albert";
  font-size: 20px;
  font-style: normal;
  line-height: 24px; /* 120% */
  cursor: pointer;
}

.upload-page .upload-how-it-works-section{
  display: flex;
  flex-direction: column; /* Align items vertically */
  align-items: center; /* Center horizontally */
  margin-top: 20px;
}

.upload-page  .upload-stats-section{
  height: 121px;
  display: flex;
  flex-direction: column; /* Align items vertically */
  align-items: center; /* Center horizontally */
  background: var(--Web---lime---20, rgba(203, 232, 150, 0.20));
}
.upload-page .upload-stats-section .upload-how-it-works-section-body {
  padding-top: 12px;
}
.upload-page  .upload-stats-section-numbers{
  text-align: center;
  display: flex;
  width: 332px;
  flex-direction: column;
  align-items: flex-start; 
  flex-shrink: 0;
  font-family: "AlbertBold";
  font-size: 21px;
  line-height: 28px;
}
.upload-page  .upload-stats-section-numbers span{
  font-family: "AlbertLight";
}

.upload-page .upload-how-it-works-section-title{
  color: var(--match-black, #213332);
  text-align: center;
  font-family: "AlbertBold";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.upload-page .upload-how-it-works-section-body{
  justify-content: center;
  display: flex;
  padding: 14px;
  /* align-items: center; */
  gap: 18px;
  width: 1200px;
  /* background-color: #f5faeb; */
}

@media only screen and (min-width: 800px) { /* desktop mode */
    .upload-page .upload-page-start-blcok{
        display: block;
    }
    .upload-page .upload-page-start-inner-bottom-buttons{
        /* display: flex; */
    }
   

    .upload-page .upload-overlay .upload-registration-wrapper{
        width: 400px;
    }
    .upload-page .upload-overlay .upload-overlay-middle{
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 800px) { /* mobile mode */
    .upload-page .upload-intake-parent-button .button{
        max-width: 100%;
    }
    .upload-page .upload-page-start-wrapper   {
        grid-template-columns:1fr

    }
    .upload-page .upload-page-start-wrapper .start,.empty  {
        display: none;
    }
    .upload-page .upload-page-start-section .title {
        font-weight: 600;
        font-size: 16px;
    }
    .upload-page .upload-page-start-section {
        padding: 14px 0 36px 0;
    }
    .upload-page .upload-page-start-blcok {
        flex-direction: column;
        margin-top: 0;
    }
    .upload-page .upload-page-start-blcok .or {
        margin: 8px 0;
    }
    .upload-page .upload-page-start-blcok .or-none {
        display: block;
        margin:  8px 0;
    }
    .upload-page .upload-page-start-section-bottom {
        margin-top: 28px;
    }
    .upload-page .upload-page-title {
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 14px;
    }
    .upload-page .upload-page-file-uploader {
        display: flex;
        justify-content: center;
    }
    .upload-page .upload-page-start {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 14px;
    }
    .upload-page .upload-page-start-text {
        text-align: center;
        font-weight: 400;
        font-size: 14px;
    }
    .upload-page .testimonials .title {
        font-size: 20px;
    }
    .app.new-ui {
        padding-bottom: 0 !important;
    }
    .upload-page .upload-page-start-section-mobile {
        padding: 24px 79px;
        background-color: #F2F2F7;
        display: flex;
        justify-content: center;
    }
    .upload-page .file-uploader-block {
        width: 100%;
        min-width: unset;
    }
    .upload-page .upload-registration-wrapper {
        min-width: unset;
        width: 100%;
    }
    .upload-page .animate-section {
        width: 100%;
    }
    .upload-page .upload-intake-block{
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
    }
    .upload-page .upload-intake-parent{
        width: 100%;
    }
    .new-ui .header-upload {
        padding: 14px 10px !important;
    }
}

@media only screen and (max-width: 460px) {
    .upload-page .ebook-download img {
        margin-right: 0;
        margin-bottom: 14px;
    }
  }


  .upload-page .file-uploader-main{
    display: flex;
    flex-direction: column; /* Align items vertically */
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    width: 450px;
  }

  .upload-page .file-uploader-main-header{
    display: flex;
    height: 30px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    text-align: center;
  }

  .upload-page .file-uploader-buttons-div{
    height: 20px;
  }

  .upload-page .file-uploader-buttons-div-btn{
    height: 5px;
  }


  .upload-page .file-uploader-buttons{
    color: rgb(255, 140, 105) !important;
    background-color: #fff !important;
  }

  .upload-page .file-uploader-main-entry {
    display: flex;
    flex-direction: column; /* Align items vertically */
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
  }


  .upload-page .medical-profile-info-box {
    display: flex;
    width: 779px;
    height: 774px;
    padding: 0px 14px;
    flex-direction: column;
    align-items: center;
    gap: 14px;
  }

  .upload-page .medical-profile-info-main {
    background-color: #E7F5FE;
    padding: 15px;
  }

  .upload-page  .later-upload-file{
    background-color: #E7F5FE;
    padding: 15px;
  }

  .upload-page .medical-profile-info-btn-div{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .upload-page .medical-profile-info-gap{
    height: 35px;
  }

  .upload-page .medical-profile-info-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: 'AlbertLight';
  }

  .upload-page .medical-profile-info-text-with-margin {
    font-size: 13px;
    font-family: 'AlbertLight';
    margin-left: 10px;
    text-align: center;
  }

  .upload-page .medical-profile-info-lower-text {
    display: flex;
    flex-direction: column; /* Align items vertically */
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
  }

  .upload-page .quote {
    font-size: 10px;
    font-family: 'AlbertLight';
  }

  .upload-page .medical-profile-info-space-from-logo{
    width: 10px;
  }

  .upload-page .medical-profile-info-list {
    list-style-type: decimal; /* Use numbers for list items */
    font-size: 11px;
    line-height: 1.65;
    margin-left: -20px;
    
  }

  .upload-page .medical-profile-info-main-header {
   text-align: center;
    
  }

  .upload-page .medical-profile-info-button {
    background-color: #fff !important;
    width: 380px !important;
    padding: 30px;
    color: black !important;
    font-size: 14px;
    line-height: 1.35;
   }

   .upload-page .later-upload-button {
    background-color: #fff !important;
    width: 380px !important;
    padding: 25px;
    color: black !important;
    font-size: 14px;
    line-height: 1.35;
   }
 

  .upload-page .medical-profile-info-foot-text {
    display: flex;     
    font-size: 13px !important;
   }

   .upload-page .upload-page-gapper {
    display: flex;
    width: 1440px;
    padding: 14px 120px 0px 120px;
    flex-direction: column;
    align-items: center;
    gap: 36px;
   }

   .upload-page .upload-button-section-main-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
   }

   .upload-page .upload-button-section-main-title {
    align-self: stretch;
    font-family: "Albert";
    margin-top: 10px;
    color: var(--match-black, #213332);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 10px;
    margin-top: 20px;
   }

   .upload-page .upload-button-section-sec-title {
    align-self: stretch;
    color: var(--match-black, #213332);
    text-align: center;
    
    /* Medium - Regular */
    font-family: "AlbertLight";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
   }

   .upload-page .upload-button-section-buttons-row {
    display: flex;
    padding-bottom: 14px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
   }

   .upload-page .upload-button-section-buttons-div {
      align-items: "center"
   }

   .upload-button-section-buttons-div {
    display: flex; /* Enable flexbox layout */
    justify-content: center; /* Center the buttons horizontally */
    align-items: center; /* Center the buttons vertically (optional) */
  }
  
   /* General Styles for Small Devices */


 
  
  .file-uploader-buttons-div-btn {
    height: 0;
    margin: 0;
  }



  @media (max-width: 600px) {
    .upload-footer-buttons-middle{
      display: block !important;
    }
    .upload-footer-button{
      margin-bottom: 10px;
      height: 55px !important;
    }
    .app-ai_profile .overlay-main{
      width: 100% !important;
    }
    .upload-page .overlay-main{
      width: 100% !important;
    }
    .upload-page .file-uploader-main {
      width: 70%; /* Make full width */
      padding: 15px; /* Adjust padding for better spacing */
    }
    
    .upload-page .file-uploader-main-upload-section {
      display: flex;
      height: 54px;
      padding: 10px 14px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex: 1 0 0;
    }
    
    .upload-page .file-uploader-main-header {
      font-size: 28px; /* Slightly smaller font size */
      padding: 10px 0; /* Add padding for spacing */
    }
    
    .upload-page .file-uploader-buttons-div {
      height: auto; /* Adjust height for content */
      margin-top: 10px; /* Add margin for spacing */
    }
    
    .upload-page .file-uploader-buttons-div-btn {
      height: auto; /* Adjust height for content */
      margin-top: 10px; /* Add margin for spacing */
    }
    
    .upload-page .file-uploader-buttons {
      font-size: 14px; /* Adjust font size */
    }
    
    .upload-page .medical-profile-info-box {
      width: 70%; /* Make full width */
      height: auto; /* Adjust height for content */
      padding: 20px; /* Add padding for better spacing */
      gap: 10px; /* Adjust gap for better spacing */
    }
    
    .upload-page .medical-profile-info-main,
    .upload-page .later-upload-file {
      padding: 15px; /* Add padding for better spacing */
    }
    
    .upload-page .medical-profile-info-text,
    .upload-page .medical-profile-info-text-with-margin {
      font-size: 14px; /* Slightly smaller font size */
    }
    
    .upload-page .medical-profile-info-button,
    .upload-page .later-upload-button {
      width: 70%; /* Make full width */
      padding: 15px; /* Adjust padding for smaller screens */
      font-size: 16px; /* Adjust font size */
    }
    
    .upload-page .medical-profile-info-foot-text {
      font-size: 12px; /* Adjust font size */
    }
    
    .upload-page .medical-profile-info-gap {
      height: 20px; /* Adjust height for spacing */
    }
    
    .upload-page .medical-profile-info-space-from-logo {
      width: 5px; /* Adjust width for spacing */
    }
    
    .upload-page .quote {
      font-size: 12px; /* Adjust font size */
    }
    
    .upload-page .medical-profile-info-list {
      font-size: 12px; /* Adjust font size */
      margin-left: 0; /* Adjust margin for better spacing */
    }
    
    .upload-page .medical-profile-info-main-header {
      text-align: center;
      padding: 10px 0; /* Add padding for spacing */
    }
  }
  

  @media (min-width: 600px) and (max-width: 900px) {
    .upload-page .file-uploader-main {
      width: 80%; /* Adjust width */
      padding: 15px; /* Adjust padding for better spacing */
    }
    
    .upload-page .file-uploader-main-upload-section {
      display: flex;
      height: 54px;
      padding: 10px 14px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex: 1 0 0;
    }
    
    .upload-page .file-uploader-main-header {
      font-size: 28px; /* Slightly smaller font size */
    }
    
    .upload-page .file-uploader-buttons-div {
      height: 25px; /* Increase height for better touch interaction */
    }
    
    .upload-page .file-uploader-buttons-div-btn {
      height: 5px; /* Increase height for better touch interaction */
    }
    
    .upload-page .medical-profile-info-box {
      width: 90%; /* Adjust width */
      height: auto; /* Adjust height for content */
      padding: 10px; /* Add padding for better spacing */
    }
    
    .upload-page .medical-profile-info-main,
    .upload-page .later-upload-file {
      padding: 12px; /* Adjust padding for better spacing */
    }
    
    .upload-page .medical-profile-info-text,
    .upload-page .medical-profile-info-text-with-margin {
      font-size: 15px; /* Slightly smaller font size */
    }
    
    .upload-page .medical-profile-info-button,
    .upload-page .later-upload-button {
      width: 90%; /* Adjust width */
      padding: 25px; /* Adjust padding for smaller screens */
    }
  }

  @media (max-width: 400px) {
    .upload-page .file-uploader-main {
      width: 100%; /* Make full width */
      padding: 10px; /* Reduce padding for smaller screens */
    }
    
    .upload-page .file-uploader-main-upload-section {
      display: flex;
height: 54px;
padding: 10px 14px;
justify-content: center;
align-items: center;
gap: 4px;
flex: 1 0 0;
    }
    
    .upload-page .file-uploader-main-header {
      font-size: 28px; /* Further reduce font size */
      padding: 5px 0; /* Adjust padding for spacing */
    }
    
    .upload-page .file-uploader-buttons-div {
      height: auto; /* Adjust height for content */
      margin-top: 5px; /* Add margin for spacing */
    }
    
    .upload-page .file-uploader-buttons-div-btn {
      height: auto; /* Adjust height for content */
      margin-top: 5px; /* Add margin for spacing */
    }
    
    .upload-page .file-uploader-buttons {
      font-size: 12px; /* Reduce font size */
    }
    
    .upload-page .medical-profile-info-box {
      width: 100%; /* Make full width */
      height: auto; /* Adjust height for content */
      padding: 15px; /* Reduce padding for smaller screens */
      gap: 8px; /* Adjust gap for better spacing */
    }
    
    .upload-page .medical-profile-info-main,
    .upload-page .later-upload-file {
      padding: 10px; /* Reduce padding for smaller screens */
    }
    
    .upload-page .medical-profile-info-text,
    .upload-page .medical-profile-info-text-with-margin {
      font-size: 18px; /* Reduce font size */
    }
    
    .upload-page .medical-profile-info-button,
    .upload-page .later-upload-button {
      width: 100%; /* Make full width */
      padding: 12px; /* Adjust padding for smaller screens */
      font-size: 14px; /* Reduce font size */
    }
    
    .upload-page .medical-profile-info-foot-text {
      font-size: 10px; /* Reduce font size */
    }
    
    .upload-page .medical-profile-info-gap {
      height: 15px; /* Reduce height for spacing */
    }
    
    .upload-page .medical-profile-info-space-from-logo {
      width: 3px; /* Adjust width for spacing */
    }
    
    .upload-page .quote {
      font-size: 10px; /* Adjust font size */
    }
    
    .upload-page .medical-profile-info-list {
      font-size: 10px; /* Reduce font size */
      margin-left: 0; /* Adjust margin for better spacing */
    }
    
    .upload-page .medical-profile-info-main-header {
      text-align: center;
      padding: 5px 0; /* Adjust padding for spacing */
    }
  }

  
  @media (max-width: 300px) {
    .upload-page .file-uploader-main {
      width: 100%; /* Make full width */
      padding: 5px; /* Reduce padding for smaller screens */
    }
    
    .upload-page .file-uploader-main-upload-section {
      display: flex;
      height: 54px;
      padding: 10px 14px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex: 1 0 0;
    }
    
    .upload-page .file-uploader-main-header {
      font-size: 28px; /* Further reduce font size */
      padding: 3px 0; /* Adjust padding for spacing */
    }
    
    .upload-page .file-uploader-buttons-div {
      height: auto; /* Adjust height for content */
      margin-top: 3px; /* Add margin for spacing */
    }
    
    .upload-page .file-uploader-buttons-div-btn {
      height: auto; /* Adjust height for content */
      margin-top: 3px; /* Add margin for spacing */
    }
    
    .upload-page .file-uploader-buttons {
      font-size: 10px; /* Reduce font size */
    }
    
    .upload-page .medical-profile-info-box {
      width: 100%; /* Make full width */
      height: auto; /* Adjust height for content */
      padding: 10px; /* Reduce padding for smaller screens */
      gap: 5px; /* Adjust gap for better spacing */
    }
    
    .upload-page .medical-profile-info-main,
    .upload-page .later-upload-file {
      padding: 8px; /* Reduce padding for smaller screens */
    }
    
    .upload-page .medical-profile-info-text,
    .upload-page .medical-profile-info-text-with-margin {
      font-size: 10px; /* Reduce font size */
    }
    
    .upload-page .medical-profile-info-button,
    .upload-page .later-upload-button {
      width: 100%; /* Make full width */
      padding: 10px; /* Adjust padding for smaller screens */
      font-size: 12px; /* Reduce font size */
    }
    
    .upload-page .medical-profile-info-foot-text {
      font-size: 8px; /* Reduce font size */
    }
    
    .upload-page .medical-profile-info-gap {
      height: 10px; /* Reduce height for spacing */
    }
    
    .upload-page .medical-profile-info-space-from-logo {
      width: 2px; /* Adjust width for spacing */
    }
    
    .upload-page .quote {
      font-size: 8px; /* Adjust font size */
    }
    
    .upload-page .medical-profile-info-list {
      font-size: 8px; /* Reduce font size */
      margin-left: 0; /* Adjust margin for better spacing */
    }
    
    .upload-page .medical-profile-info-main-header {
      text-align: center;
      padding: 3px 0; /* Adjust padding for spacing */
    }

  }

  /* Media query for small devices (e.g., mobile phones) */

  /* General Styles for Small Devices */
@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .upload-page .upload-page-gapper {
    width: 100%; /* Take up full width */
    padding: 14px 20px 0px 20px; /* Reduce padding */
  }

  .upload-page .upload-button-section-main-box {
    align-items: center; /* Center content horizontally */
  }

  .upload-page .upload-button-section-main-box {
    display: block; /* Change to block layout */
    align-items: stretch; /* Stretch items to full width */
  }

  .upload-page .upload-button-section-sec-title {
    text-align: center; /* Center text */
    font-size: 18px; /* Slightly smaller font size */
  }

  .upload-page .upload-button-section-buttons-row {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons */
  }

  .upload-page .upload-button-section-buttons-div{
    display: block;
    align-items: center; /* Center buttons */

  }

  .upload-page .upload-how-it-works-section-body{
    flex-direction: column; /* Stack divs vertically */
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;
    max-width: 300px;
  }

  .upload-page .upload-stats-section{
   height: auto;
  }

  .upload-page .upload-stats-section-numbers{
    align-items: unset;
  }

  .upload-page .arrow_icon_rotation {
    transform: rotate(90deg);
  }
}

/* Specific Styles for iPhones (if needed) */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
  /* Add iPhone-specific styles here if necessary */
}

.upload-page .small_height {
  height: 14px;
}

.upload-page .upload-page-main-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin-top: 20px;
}

.upload-page .flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-page .small-width-gap {
  width: 10px
}

.upload-page .large-width-gap {
  width: 40px
}


.upload-page .or-answer-btn {
  color: var(--Web---Orange, #FF8C69);
  font-family: "Albert";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-decoration-line: underline;
  background-color: transparent;
  border: unset;
  cursor: pointer;

}

.upload-page .upload-how-it-works-section-body-text{
  font-family: "AlbertLight";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.upload-footer-buttons{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 40px;
}
.upload-footer-buttons-middle{
 display: flex; 
}
.upload-footer-button{
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Albert";
  font-size: 20px;
  font-style: normal;
  line-height: 24px;
  cursor: pointer;
  padding-inline-end: 20px;
  padding-inline-start: 20px;
  margin-inline-end: 5px;
  margin-inline-start: 5px;
  height: 55px;
  border-radius: 5px;
}
.upload-footer-button img{
  margin-inline-end: 5px;
}
.upload-footer-button.answer-questions{
  background-color: #FF8C69;
}
.upload-footer-button.upload-button{
  background-color: #C77DFF;
}

#main-div {
  display: flex;
  flex-direction: column; /* Align items vertically */
  align-items: center;    /* Center horizontally */
  justify-content: center; /* Center vertically (optional, if you want vertical centering as well) */
}

.upload-page .btn-height-gap {
  height: 0px; /* Take up full width */
}

@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .upload-page .btn-height-gap {
    height: 10px;
  }
}

@media (min-width: 768px) { /* Adjust breakpoint as needed */
  .upload-page .upload-page-main-div{
    padding: 15px;
    margin-top: 10px;
    padding-bottom: 0px;
  }
}


@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .upload-page .upload-page-title-main {
    padding: 15px;
    font-size: 36px;
    padding-bottom: 0px;
  }
}


@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .upload-page .upload-page-start-low-title {
    padding: 15px;
    font-size: 18px;
    padding-top: 0px;
  }
}

@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .upload-page .upload-button-section-sec-title {
    padding: 15px;
  }
}


.upload-page .file-uploader-secoundry-header {
  font-family: AlbertLight;
}

@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .upload-page .file-uploader-secoundry-header {
    color: #000;

text-align: center;
/* Medium - Regular */
font-family: "AlbertLight";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
  }
}


.upload-page .ai-chat-upload-box {
  margin-top: 25px;
  border-radius: 4px;
  border: 1px solid var(--web-dark-40, rgba(33, 51, 50, 0.40));
}

.upload-page .ai-chat-upload {
  overflow-y: scroll;
  width: 725px;
}

.upload-page .ai-chat-upload .chat-input{
  position: unset;
  bottom: unset;
}


 .intercom-button{
  display: none !important;

}

.chat-popup  .add-absulot {
  position: absolute;
}

.upload-page .center-loader{
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
  margin-top: 25px;
}

.upload-page .ai-chat-upload-box .chat-send-button{
  position: absolute;
  right: 35px;
  top: 43%;
  transform: translateY(-50%);
    padding: 8px;
}