.search-doctor .result {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.search-doctor .result:hover {
  background-color: #3A82F7;
}

.search-doctor .result:hover>p {
  color: #FFFFFF;
}

.search-doctor .result .clinic {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3A3A3C;
}

.search-doctor .name {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #3A3A3C;
}

.search-doctor .cant-find {
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0C9BED;
  margin-top: 15px;
  display: inline-block;
}