.treatment-question {
  width: 100%;
  margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ff8c693b;
}

.treatment-question-content {
  margin-top: 20px;
} 

.treatment-question-drugs-received-not-received{
    border: 1px solid #ff8c69;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.treatment-question-drugs-received-not-received-radio{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #f1f1f1;
    border: 1px solid #333;
    margin-inline-end: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.received-not-received-radio-circle {
    display: none;
}
.treatment-question-drugs-received-not-received-radio.selected {
    border-color: #ff8c69;
}
.treatment-question-drugs-received-not-received-radio.selected 
.received-not-received-radio-circle {
    display: block;
    width: 10px;
    height: 10px;
    background: #ff8c69;
    border-radius: 50%;
}
.treatment-question-drugs-received-not-received-text{

}

.treatment-question-add-treatment{
    font-family: AlbertLight;
    background-color: transparent !important;
    color: #ff8c69 !important;
}

.treatment-question-add-treatment:hover{
    /* background-color: transparent !important; */
    border: 1px solid #ff8c69 !important;
    /* color: coral !important; */
}

.treatment-question-next{
    margin-inline-start: 10px;
}
