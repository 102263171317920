.checkbox{
  padding: 13px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.checkbox.checked{
  border: 1px solid rgb(58, 130, 247);
}

.checkbox .checkbox-title{
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.checkbox .checkbox-check{}

.checkbox svg{
  vertical-align: middle;
}

.checkbox.checked svg *{
  fill: rgb(58, 130, 247);
}

.checkbox.theme-orange{
  border: 1px solid #FF8C69;
  border-radius: 15px;
  flex-flow: row-reverse;
  justify-content: flex-end;
}

.checkbox.theme-orange .checkbox-check{
  margin-inline-end: 10px;
  display: inline-flex;
}

.checkbox.checked.theme-orange{
  border: 1px solid #333;
}