.root {
    background-color: white;
}

.header {
    background: #2EC4B633;
    padding: 14px 24px;
    color: #000;
}

.body {
    position: relative;
    box-sizing: border-box;
    min-height: 200px;
}

.headerTitle {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.headerSubTitle {
    margin: 0px;
    font-family: "AlbertLight";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.completedState {
    display: flex;
    justify-content: center;
    padding: 14px 24px;
}

.footer {
    display: flex;
    justify-content: flex-end;
    padding: 14px 60px;
}

@media only screen and (max-width: 800px) {
    .headerTitle {
        font-size: 20px;
    }
    .headerSubTitle {
        font-size: 16px;
    }
}
