.questionnaire{
  height: 100%;
  overflow: hidden;
}

.questionnaire iframe{
  width: 100%;
  border: none;
  height: 100%;
}

.popup.questionnaire .popup-content{
  height: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 800px){
  .popup.questionnaire .popup-content{
    height: 100% ;
    overflow: hidden;
  }
  .popup.questionnaire{
    bottom: 0;
  }
}