.to-and-from {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.someone-else {
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: #0C9BED;
}