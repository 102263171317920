.result-list .result-list-description.tab-info {
  border: 1px solid #F4D06F;
  padding: 8px;
  display: flex;
  background-color: #FDFAF0;
}
.tab-info .list-description-btn-close {
  align-self: self-start;
  margin-left: auto;
}
.tab-info .list-description-bulb {
  align-self: self-center;
}
.tab-info .list-description-text {
  cursor: default;
  margin-top: 16px;
  margin-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2.4em;
  word-break: break-word;
}
.tab-info .list-description-text.expanded {
  display: block;
  height: auto;
}