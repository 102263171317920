.preloader{
  left:0;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 1000;
  transition: all 0.4s ease-out;
  bottom: 100%;
  display: flex;
  justify-content: center;
  background: #ffffffbf;
}
.preloader.inline-preloader{
  position: static;
  height: 100px;
  background: none;
}
.preloader.visible{
  bottom: 0%;
}
.preloader .preloader-middle{
  align-self: center;
  position: relative;
  width: 110px;
  /* top:-30px; */
}

.preloader-logo{
  margin-bottom: 20px;
  text-align: center;
}
.preloader-logo img{
  width: 150px;
}
.preloader-text{
  text-align: center;
  margin-top: 10px;
}
.preloader-spinner{
  position: relative;
  text-align: center;
  display: flex;
  zoom: 0.7;
}
.preloader .loader{
  width: 300px;
  height: 100px;
  margin: 0px auto;
  position: relative;
}
.preloader .loader span{
  background-color: rgb(173 204 255);
  height: 20px;
  width: 20px;
  border-radius: 5rem;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 52.5%;
  left: 150px;
  transition: all 0.15s ease-in-out;
}  
.preloader .loader span:nth-child(1){
  left: 80px;
  animation: waveUp 2s, smallExtend 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.preloader .loader span:nth-child(2){
  left: 115px;
  animation: waveUp 2s, largeExtend 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 0.15s;
}
.preloader .loader span:nth-child(3){
  animation: waveUp 2s, smallExtend 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 0.3s;
}
.preloader .loader span:nth-child(4){
  left: 185px;
  animation: waveUp 2s, largeExtend 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 0.45s;
}
.preloader .loader span:nth-child(5){
  left: 220px;
  animation: waveUp 2s, smallExtend 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 0.6s;
}
@keyframes waveUp{
  0%,15%{ top: 50%; }
  45%,65%{ top: 42.5%; }
  85%,100%{ top: 50%; }
}
@keyframes smallExtend{
  0%,8%{
      background-color: rgb(173 204 255);
      height: 20px;
  }
  14%,34% {
      background-color: rgb(58, 130, 247);
      height: 47.5px;
  }
  46%,100% {
      background-color: rgb(173 204 255);
      height: 20px;
  }
}
@keyframes largeExtend{
  0%,8%{
      background-color: rgb(173 204 255);
      height: 20px;
  }
  14%,34%{
      background-color: rgb(58, 130, 247);
      height: 82.5px;
  }
  46%,100%{
      background-color:rgb(74, 144, 255);
      height: 20px;
  }
}