.accordion{}

.accordion .accordion-item-collapsed .accordion-item-body{
  margin: 0;
  max-height: 0px;
}

.accordion .accordion-item-body{
  max-height: 2000px;
  overflow: hidden;
  transition: all 0.8s ease-out;
}