.next-appointment{
  
}

.next-appointment .next-appointment-description{
  margin-bottom: 20px;
}

@media only screen and (max-width: 800px){
  .popup.next_appointment{
    bottom: 0%;
  }
}