.feed-page-section {
    color: rgba(33, 51, 50, 0.60);
    font-family: "Albert";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
    padding: 2px 0px;
    margin-bottom: 25px;
    border-bottom: 1px solid rgba(33, 51, 50, 0.40);
}

@media only screen and (max-width: 1200px) {
    .feed-page-section span {
        margin-left: 14px;
    }
}
