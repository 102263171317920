.add-patient{
  padding: 20px !important;
}

.add-patient .error{
  border: 1px solid red;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 18px;
  border-radius: 10px;
}
.add-patient-title-holder{
  margin-bottom: 30px;
  text-align: center;
  font-family: Albert;
}
.add-patient-title{
  font-size: 26px;
}
.add-patient-subtitle{
  font-size: 18px;
  margin-top: 12px;
  font-family: 'AlbertLight';
}

.add-patient form{
  margin-bottom: 30px;
}